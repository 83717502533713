import CryptoJS from "crypto-js";

export const EncryptData = (data) => CryptoJS.MD5(data).toString();

const salt = process.env.SALT || "9af1eaac-7c62-4fd0-9632-5a7581a0c3fe";

export const encryptSessionData = (data) =>
  CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();

export const decryptSessionData = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, salt);
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    return null;
  }
};

export const stringGen = () => {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var x = 8;
  for (var i = 0; i < x; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};
