import React, { useEffect, useState } from "react";
import { Button, Stack, Table } from "react-bootstrap";
import * as AiIcons from "react-icons/ai";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTableSearchBox from "../../components/AdvanceTableSearchBox";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import { MissInfoNoPermission } from "../../components/MissInfo";
import ModalEdit from "../../components/ModalEdit";
import EditRecordDatabase from "./EditRecordDatabase";
import { useAuth } from "../../Context/AuthContext";
import axios from "axios";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import Confirm from "../../components/Confirm";
import Notification from "../../components/notification";
import { httpClient } from "../../utils/HttpClient";

const DataBaseRecords = ({
  uidDatabase,
  idpartnerdatabase,
  isHidden,
  isBigData,
  totalRecords,
}) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [idRecord, setIdRecord] = useState(false);
  const [idRecordToDelete, setIdRecordToDelete] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(null);
  const [message, setMessage] = useState(null);
  const [searchMsisdn, setSearchMsisdn] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [notificationType, setNotificationType] = useState(null);
  const [dataBaseInfo, setDataBaseInfo] = useState(null);
  const handleShow = () => setShowEdit(true);
  const handleClose = () => {
    setShowEdit(false);
    getListDatabases();
  };

  const handleCloseNotificacion = () => {
    setDisplayMessage(false);
    getListDatabases();
  };

  const ModalHandleCloseConfirm = () => {
    setShowConfirmDelete(false);
    getListDatabases();
  };

  const handleConfirm = () => {
    if (idRecordToDelete != null && idRecordToDelete != "") {
      deleteRecord();
      setShowConfirmDelete(false);
    }
  };
  useEffect(() => {
    if (!isBigData) {
      getListDatabases();
    }
  }, []);
  useEffect(() => {
    getListDatabases();
  }, [searchMsisdn]);

  // useEffect(() => {
  //   console.log(pagination);
  // }, [pagination]);
  const getListDatabases = () => {
    const URI = ApiEndpoint + "campaigns/getDatabaseRecords";

    httpClient(
      "POST",
      URI,
      {
        uiddatabase: uidDatabase,
        uidUser: Auth.getSessionData().uid_user,
        page: page,
        limit: limit,
        filter: searchMsisdn,
      },
      userData.token
    )
      .then(function (response) {
        setDataBaseInfo(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteRecord = () => {
    if (idRecordToDelete < 1) return;
    const URI =
      ApiEndpoint +
      "campaigns/deleteRecordPartnersDatabase/" +
      uidDatabase +
      "/";

    httpClient("DELETE", URI + idRecordToDelete, {}, userData.token)
      .then(function (response) {
        setMessage("Registro eliminado exitosamente");
        setDisplayMessage(true);
        getListDatabases();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const columns = [
    {
      accessor: "phone",
      Header: "Celular",
    },
    {
      accessor: "details",
      Header: "Detalles",
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let details = rowData.row.original.details;
        return <>{JSON.stringify(details)}</>;
      },
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let iddetail = rowData.row.original.iddetail;
        return (
          <>
            <AiIcons.AiOutlineDelete
              className="IconTable"
              title="Borrar"
              onClick={() => {
                setIdRecordToDelete(iddetail);
                setShowConfirmDelete(true);
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      {isHidden || isBigData ? (
        <MissInfoNoPermission></MissInfoNoPermission>
      ) : (
        <>
          <Stack direction="horizontal" className="wrappTitle" gap={2}>
            <div>
              <h4>Lista de registros</h4>
            </div>
            <>
              <div className="ms-auto">
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => {
                    setIdRecord(0);
                    setShowEdit(true);
                  }}
                >
                  <AiIcons.AiOutlinePlus /> Agregar
                </Button>
              </div>
            </>
          </Stack>
          {dataBaseInfo && (
            <AdvanceTableWrapper
              columns={columns}
              data={dataBaseInfo}
              sortable
              pagination
              perPage={10}
            >
              <div className="SearchBar">
                <Stack direction="horizontal" gap={3}>
                  <AdvanceTableSearchBox
                    table
                    setManualFilter={setSearchMsisdn}
                  />
                </Stack>
              </div>
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  size: "sm",
                  striped: true,
                  className: "fs--1 mb-0 overflow-hidden",
                }}
              />
              <AdvanceTablePagination table />
              <AdvanceTableFooter
                rowCount={totalRecords ?? 0}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
                pageSize={20}
              />
            </AdvanceTableWrapper>
          )}
        </>
      )}
      <ModalEdit
        show={showEdit}
        handleShow={handleShow}
        handleClose={handleClose}
        title={"Agregar registro "}
      >
        <EditRecordDatabase
          idRecord={idRecord}
          show={showEdit}
          handleClose={handleClose}
          idPartnerDatabase={idpartnerdatabase}
          setDisplayMessage={setDisplayMessage}
          setMessage={setMessage}
          setNotificationType={setNotificationType}
          uidDatabase={uidDatabase}
        />
      </ModalEdit>
      <Confirm
        show={showConfirmDelete}
        handleClose={ModalHandleCloseConfirm}
        handleConfirm={handleConfirm}
        message={"Desea eliminar el registro seleccionado?"}
      />
      {displayMessage && (
        <Notification
          show={displayMessage}
          setShow={handleCloseNotificacion}
          message={message}
          alertType={notificationType}
        />
      )}
    </>
  );
};

export default DataBaseRecords;
