import React, { useEffect, useState } from "react";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import { Badge, Button, Stack } from "react-bootstrap";
import AdvanceTableSearchBox from "../../components/AdvanceTableSearchBox";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import ModalEdit from "../../components/ModalEdit";
import { FormatCurrency2Dec, FormatCurrency3Dec } from "../../utils/FormatData";
import axios from "axios";
import * as AiIcons from "react-icons/ai";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { useAuth } from "../../Context/AuthContext";
import { httpClient } from "../../utils/HttpClient";

const ListaPreciosDefault = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const URI = ApiEndpoint + "configuration/pricesgeneral/";
  const [prices, setPrices] = useState([]);
  const [show, setShow] = useState(false);
  const [idPrice, setIdPrice] = useState(0);
  const handleClose = () => {
    getPricesDefault();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const getPricesDefault = () => {
    httpClient("GET", URI, {}, userData.token).then((response) => {
      setPrices(response.data);
    });
  };

  useEffect(() => {
    getPricesDefault();
  }, []);

  const columns = [
    {
      accessor: "limitMin",
      Header: "De",
    },
    {
      accessor: "limitMax",
      Header: "Hasta",
    },
    {
      accessor: "unitPrice",
      Header: "Costo por Sms",
      Cell: (rowData) => {
        let create = rowData.row.original.unitPrice;
        return <>{FormatCurrency2Dec(create)}</>;
      },
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        //let uiddatabase = rowData.row.original.uidcampaigns;
        return <></>;
      },
    },
  ];
  return (
    <>
      <div>
        <Stack direction="horizontal" gap={2} className="AlignItemsTop">
          <div>
            <h2>Lista de Precios</h2>
            <h3>Administre la lista de precios.</h3>
          </div>
          <div className="ms-auto">
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                setIdPrice(0);
                setShow(true);
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>{" "}
          </div>
        </Stack>
        {prices && (
          <AdvanceTableWrapper
            columns={columns}
            data={prices}
            sortable
            pagination
            perPage={10}
          >
            <div className="SearchBar">
              <Stack direction="horizontal" gap={3}>
                <AdvanceTableSearchBox table />
              </Stack>
            </div>
            <h4>Lista de Precios Predeterminados</h4>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            <AdvanceTablePagination table />
            <AdvanceTableFooter
              rowCount={prices.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
              pageSize={10}
            />
          </AdvanceTableWrapper>
        )}
      </div>
      <ModalEdit
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        title={
          idPrice > 0 ? "Editar Rango de Precio" : "Agregar Rango de Precio"
        }
      ></ModalEdit>
    </>
  );
};

export default ListaPreciosDefault;
