import React, { useEffect, useState } from "react";
import * as FiIcons from "react-icons/fi";

// Bootstrap
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Papa from "papaparse";
import ListDatabase from "./_ListDatabase";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../Context/AuthContext";
import { FileUploader } from "react-drag-drop-files";
import { ProgressBar, Stack } from "react-bootstrap";
import BtnExportToExcell from "../../utils/BtnExportToExcell";
import Notification from "../../components/notification";
import { httpClient } from "../../utils/HttpClient";
import ButtonRefresh from "../../components/generic/_ButtonRefresh";
const EditDatabasePartner = ({ handleClose }) => {
  const Auth = useAuth();
  const tmpUid = uuidv4();
  var userData = Auth.getSessionData();
  let intervalID = 0;
  let startCounting = 0;
  const [dataItemsFromFile, setDataItemsFromFile] = useState(null);
  //  var userData = Auth.getSessionData();
  const [currentUid, setCurrentUid] = useState(tmpUid);
  const [fieldsFromFile, setFieldsFromFile] = useState(null);
  const [validated, setValidated] = useState(false);
  const [itemsProcessed, setItemsProcessed] = useState(null);
  const [invalidItemsProcessed, setInvalidItemsProcessed] = useState(null);
  const [invalidItems, setInvalidItems] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [data, setData] = useState(null);
  const [isHidden, setIsHidden] = useState(false);
  const [dataToExport, setDataToExport] = useState(null);
  const [tooBigError, setTooBigError] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [started, setStarted] = useState(false);
  const [carrierList, setCarrierList] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(false);

  const [displayMessageDb, setDisplayMessageDb] = useState(false);
  const [messageDb, setMessageDb] = useState(null);
  const [alertTypeDb, setAlertTypeDb] = useState(null);
  const [values, setValues] = useState({
    idcarrier: "0",
    name: "",
    uid_partner: "",
  });

  const clearData = () => {
    setCurrentUid(uuidv4());
    setDataToExport(null);
    setData(null);
    setStarted(false);
    setIsWorking(false);
    setInvalidItems(null);
    setInvalidItemsProcessed(null);
  };

  const refreshData = () => {
    getTmpData();
  };

  const getCarriersList = () => {
    const URICarriers = ApiEndpoint + "carriers";

    httpClient("GET", URICarriers, {}, userData.token)
      .then(function (response) {
        setCarrierList(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getTmpData = () => {
    const URI = ApiEndpoint + "campaigns/reporttmpdatabase/" + currentUid;
    httpClient("GET", URI, {}, userData.token).then((response) => {
      setStarted(false);
      setData(response.data[0]);
      console.log(response.data[0]);
      if (response.data[0].b_finished) {
        if (startCounting < 3 && response.data[0].i_totalrows > 50000) {
          console.log("entre a startcounting");
          startCounting++;
        } else {
          console.log("pase startcounting");
          clearInterval(intervalID);
          setIsWorking(false);
          getTmpDataError();
        }
      }
    });
  };

  const getTmpDataError = () => {
    const URI =
      ApiEndpoint + "campaigns/getPartnerDatabasesTmpError/" + currentUid;

    httpClient("GET", URI, {}, userData.token).then((response) => {
      setDataToExport(response.data);
    });
  };

  const handleFileUpload = (file) => {
    if (file) {
      setAttachment(file);

      const formData = new FormData();
      formData.append("name", "csvfile");
      formData.append("filename", file.originalname);
      formData.append("csvfile", file);

      formData.append("uid", currentUid);
      //formData.append("file", files[0]);/
      const URISaveFile = ApiEndpoint + "campaigns/savecsvfile";
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: URISaveFile,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userData.token}`,
        },
        data: formData,
      };

      axios
        .request(config)
        // axios
        //   .post(URISaveFile, formData, {
        //     headers: { "Content-Type": "multipart/form-data" },
        //   })
        .then((res) => {
          // getTmpData();
          // setInterval(() => {
          //   getTmpData();
          // }, 5000);

          setIsWorking(true);
          setStarted(true);
          startFileImport();
          //   var i = setInterval(getTmpData, 5000);
          setTimeout(function () {
            if (!isWorking) return;
            clearInterval(intervalID);
            setIsWorking(false);
            getTmpDataError();
          }, 15000);
        })
        .catch((err) => alert("File Upload Error"));
    }
  };

  function startFileImport() {
    intervalID = setInterval(getTmpData, 5000);
  }
  const handleFileUpload1 = (file) => {
    setFieldsFromFile(null);
    setDataItemsFromFile(null);
    Papa.parse(file, {
      header: true,
      complete: function (results) {
        setFieldsFromFile(results.meta.fields);
        if (results.data.length > 50) {
          setTooBigError(true);
          // const URICustomer = ApiEndpoint + "campaigns/savecsvfile";
          // axios
          //   .post(URICustomer, {
          //     jsonobject: results.data,
          //     fields: results.meta.fields,
          //     uid: uuidv4(),
          //   })
          //   .then(function (response) {
          //     console.log(response);
          //     if (response.data.error) {
          //     } else {
          //       handleClose();
          //       return;
          //     }
          //   })
          //   .catch(function (error) {
          //     console.log(error);
          //   });
          processFile(results.data.slice(0, 50), results.meta.fields);
        } else {
          processFile(results.data, results.meta.fields);
        }
        //setDataItemsFromFile(results.data);
        /* setDataItemsFromFile(results.data); */
      },
    });
  };

  const processFile = (dataitems, fieldsFromFile) => {
    var data = [],
      failedData = [],
      headers = [];
    var invalidCount = 0;
    dataitems.map((item) => {
      var msisdn = item[Object.keys(item)[0]];
      if (
        msisdn &&
        /^\d+$/.test(msisdn) &&
        (msisdn.length === 10 ||
          (msisdn.length === 12 && msisdn.startsWith("52")))
      ) {
        data.push({
          msisdn: item[Object.keys(item)[0]],
          details: item,
        });
      } else {
        failedData.push({
          msisdn: item[Object.keys(item)[0]],
          details: item,
        });
        invalidCount++;
      }
    });
    console.log("setItemsProcessed");
    console.log(data);
    setInvalidItems(invalidCount);
    setItemsProcessed(data);
    setInvalidItemsProcessed(failedData);
  };

  const handleSubmit1 = (event) => {
    setIsSaving(true);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    console.log(values);
    const URICustomer = ApiEndpoint + "campaigns/addPartnerDatabase";

    httpClient(
      "POST",
      URICustomer,
      {
        partner: Auth.getSessionData().uid_partner ?? null,
        name: values.name,
        uiddatabase: currentUid,
        isHidden: isHidden,
        uid_user: Auth.getSessionData().uid_user,
        idcarrier: values.idcarrier,
      },
      userData.token
    )
      .then(function (response) {
        console.log(response.data);
        setIsSaving(false);
        if (response.data.error) {
          setAlertTypeDb("warning");
          setMessageDb(response.data.message);
          setDisplayMessageDb(true);
        } else {
          handleClose();
          return;
        }
      })
      .catch(function (error) {
        setAlertTypeDb("warning");
        setMessageDb("Ha ocurrido un error");
        setDisplayMessageDb(true);
        console.log(error);
      });
  };

  useEffect(() => {
    getCarriersList();
  }, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit1}>
      {Auth.isAdminRole() && (
        <>
          <FloatingLabel label="" className="mb-3">
            <Form.Check
              type="switch"
              id="isHidden"
              value={isHidden}
              label="¿Ocultar Informacion?"
              onChange={(event) => {
                setIsHidden(!isHidden);
              }}
              className="prevent-validation"
            />
          </FloatingLabel>
        </>
      )}
      <FloatingLabel controlId="floatingInput" label="Nombre:" className="mb-3">
        <Form.Control
          type="text"
          name="name"
          required
          value={values.name}
          onChange={(event) => {
            onChange(event);
          }}
          placeholder="Nombre:"
        />
      </FloatingLabel>
      <FloatingLabel label="Limitar a Operadora *" className="mb-3">
        <Form.Select
          id="idcarrier"
          name="idcarrier"
          onChange={(selectedOption) => {
            onChange(selectedOption);
            setSelectedCarrier(`${selectedOption.target.value}`);
          }}
          value={values.idcarrier}
          required
          aria-label="select_carrier"
        >
          <option value="0">Todos</option>
          {!!carrierList?.length &&
            carrierList.map((carrier) => (
              <option value={carrier.idcarrier}>{carrier.carrier}</option>
            ))}
        </Form.Select>
      </FloatingLabel>
      <Form.Group className="mb-3">
        <Form.Label>Cargar una base de datos:</Form.Label>

        <FileUploader
          handleChange={handleFileUpload}
          name="csvfile"
          id={"csvfile"}
          label="Sube un archivo o arrastrar aquí"
          //   types="[CSV,XLSX,XLS]"
        />
      </Form.Group>
      <Alert key={"secondary"} variant={"secondary"} className="Notice">
        <b>Nota:</b> La primer columna debe ser el número de teléfono, no
        importa el nombre de la columna. Tu archivo debe ser en formato{" "}
        <b>.csv</b>, puede contener campos adicionales que serán almacenados
        como datos extra.
      </Alert>
      {!data && started && (
        <>
          <h5>Cargando Archivo:</h5>
          <ProgressBar variant="success" animated now={75} />
        </>
      )}
      {data && started && (
        <>
          <h5>Archivo Cargado</h5>
          <ProgressBar variant="success" animated now={100} />
        </>
      )}
      {data && (
        <>
          <div className="Status">
            <Stack direction="horizontal" gap={2}>
              <h6>{isWorking ? "Procesando..." : "Detalle"}</h6>
              <div className="ms-auto">
                <FiIcons.FiRefreshCw onClick={refreshData} />
              </div>
            </Stack>
            {isWorking ? (
              <>
                {" "}
                <ProgressBar
                  variant="success"
                  animated
                  now={(data.i_unique ?? 0 / data.i_totals ?? 1) * 100}
                />
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <p>{data.i_unique ?? 0}</p>
                  </div>
                  <div className="ms-auto">
                    <p>{data.i_totals ?? 0}</p>
                  </div>
                </Stack>
              </>
            ) : (
              <>
                {" "}
                <Alert
                  key={"secondary"}
                  variant={"secondary"}
                  className="Notice"
                >
                  <b>Archivo procesado</b>
                </Alert>
              </>
            )}
            <h5>Numeros Procesados:</h5>
            <Stack direction="horizontal" gap={2}>
              <div>
                <p>
                  Numeros Unicos: <b>{data.i_unique ?? 0}</b>
                </p>
              </div>
              <div className="ms-auto">
                <p>
                  Validos: <b>{data.i_valid ?? 0}</b>
                </p>
              </div>
            </Stack>{" "}
            <Stack direction="horizontal" gap={2}>
              <div>
                <p>
                  Fallidos: <b>{data.i_totals - data.i_valid ?? 0}</b>
                </p>
              </div>
              <div className="ms-auto">
                <p>
                  Total: <b>{data.i_totals ?? 0}</b>
                </p>
              </div>
            </Stack>
          </div>
        </>
      )}
      {dataToExport && (
        <BtnExportToExcell
          dataReport={dataToExport}
          fileName="Error_Report"
          label={"Exportar Fallidos"}
        ></BtnExportToExcell>
      )}
      <hr />
      <div className="text-right">
        {data && !isWorking && (
          <>
            <Button
              variant="primary"
              type="button"
              className="align-self-end"
              onClick={() => clearData()}
            >
              Limpiar
            </Button>
            {data.i_valid > 0 && (
              /* <Button
                variant="primary"
                type="submit"
                className="align-self-end"
              >
                Guardar
              </Button>*/
              <ButtonRefresh
                refreshFunction={() => {}}
                loading={isSaving}
                legendButton="Guardar"
                typeButton="submit"
              ></ButtonRefresh>
            )}
          </>
        )}
      </div>
      {itemsProcessed && (
        <>
          <ListDatabase
            dataitems={itemsProcessed}
            fieldsFromFile={fieldsFromFile}
            invalidItemsCount={invalidItems}
            invalidItems={invalidItemsProcessed}
          />
        </>
      )}
      {displayMessageDb && (
        <Notification
          show={displayMessageDb}
          setShow={setDisplayMessageDb}
          message={messageDb}
          alertType={alertTypeDb}
        />
      )}
    </Form>
  );
};

export default EditDatabasePartner;
