import React, { useEffect, useState } from "react";
import { Alert, Card, Col, Row, Stack } from "react-bootstrap";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { httpClient } from "../../utils/HttpClient";
import { useAuth } from "../../Context/AuthContext";
import { EncryptData } from "../../utils/Encrypt";

const DetailPartnerApi = ({ uidPartner }) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [dataApi, setDataApi] = useState(false);
  const [jsonSample, setJsonSample] = useState("");

  const getApiDetail = () => {
    const URICarriers =
      ApiEndpoint + "administration/partnerdetail/" + uidPartner;

    httpClient("GET", URICarriers, {}, userData.token).then((response) => {
      setDataApi(response.data);
      const userData = response.data;
      const currntTime = new Date().getTime();
      const encrtyp = EncryptData(
        userData.vc_partnerid + currntTime + userData.vc_secretkey
      );

      let jsonData = {
        security: {
          partner_id: userData.vc_partnerid,
          time: currntTime,
          hash: encrtyp,
        },
        notify: {
          service_id: userData.vc_serviceid,
          number: "9996501490",
          carrier: 0,
          message: "Prueba mensaje",
        },
      };
      setJsonSample(JSON.stringify(jsonData));
    });
  };

  useEffect(() => {
    getApiDetail();
  }, []);

  return (
    <>
      <Row>
        <Stack direction="horizontal" gap={2}>
          <div>
            <p>
              Nombre: <b>{dataApi.vc_name ?? ""}</b>
            </p>
          </div>
        </Stack>
      </Row>
      <Row>
        <Stack direction="horizontal" gap={2}>
          <div>
            <p>
              Partner Id: <b>{dataApi.vc_partnerid ?? ""}</b>
            </p>
          </div>
        </Stack>
      </Row>
      <Row>
        <Stack direction="horizontal" gap={2}>
          <div>
            <p>
              Password: <b>{dataApi.vc_secretkey ?? ""}</b>
            </p>
          </div>
        </Stack>
      </Row>{" "}
      <Row>
        <Stack direction="horizontal" gap={2}>
          <div>
            <p>
              Servicio: <b>{dataApi.vc_servicename ?? ""}</b>
            </p>
          </div>
        </Stack>
      </Row>{" "}
      <Row>
        <Stack direction="horizontal" gap={2}>
          <div>
            <p>
              Servicio Id: <b>{dataApi.vc_serviceid ?? ""}</b>
            </p>
          </div>
        </Stack>
      </Row>{" "}
      <Row>
        <Stack direction="horizontal" gap={2}>
          <div>
            <p>
              Descripcion: <b>{dataApi.vc_description ?? ""}</b>
            </p>
          </div>
        </Stack>
      </Row>
      <Row>
        <>
          <Card>
            <Card.Header>JSON Ejemplo de Envio</Card.Header>
            <Card.Body>
              <Alert key={"secondary"} variant={"secondary"} className="Notice">
                {jsonSample || ""}
              </Alert>
            </Card.Body>
          </Card>
        </>
      </Row>
      <Row>
        <Alert key={"success"} variant={"success"} className="Notice">
          <b>
            Proporcione esta informacion al cliente para que pueda realizar el
            envio de Mensajes a traves de su aplicación
          </b>
        </Alert>
      </Row>
    </>
  );
};

export default DetailPartnerApi;
