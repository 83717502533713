import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FormatCurrency2Dec } from "../../utils/FormatData";
import Stack from "react-bootstrap/Stack";

const GenericWidget = ({ icon, sizexs, sizesm, className, title, legend, quantity }) => {
  return (
    <>
      <Col xs={sizexs} sm={sizesm} md={4} lg={4} xl={4}>
        <div className={className}>
          <h6>{legend}</h6>
          <Stack direction="horizontal" gap={2}>
            <div>
              {icon}
            </div>
            <div className="ms-auto">
            <h4>{quantity}</h4>
            </div>
          </Stack>
          <div className="details">{title}</div>
        </div>
      </Col>
    </>
  );
};

export default GenericWidget;