import logo from "./logo.svg";
import "./App.css";
import { AuthProvider } from "./Context/AuthContext";
import Router from "./pages/router";

function App() {
  return (
    <AuthProvider>
      <Router />
    </AuthProvider>
  );
}

export default App;
