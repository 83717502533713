import { Warning } from "@mui/icons-material";
import React, { useRef, useState, useEffect } from "react";
import { Badge, FloatingLabel, Form } from "react-bootstrap";

const SmsTextBox = ({
  name,
  label,
  defaultValue,
  isDisabled,
  changeHandler,
  required = true,
}) => {
  const [LeghtMessage, setLeght] = useState(
    "Cada 160 caracteres o menos, se cobra 1 mensaje *"
  );
  const checkbox = useRef();
  const [count, setCount] = React.useState(defaultValue.length);
  const [message, setMessages] = React.useState("");
  const [warning, setWarning] = useState(false);

  const [scrollTop, setScrollTop] = useState(0);

  const handleScroll = (event) => {
    setScrollTop(event.currentTarget.scrollTop);
  };

  useEffect(() => {
    if (scrollTop == 0 || defaultValue.length == 0) {
      setLeght("Cada 160 caracteres o menos, se cobra 1 mensaje *");
      setWarning(false);
    } else {
      setLeght(" ");
      setWarning(true);
    }
  }, [scrollTop]);

  const [characterLimit, setCharacterLimit] = useState(398);

  useEffect(() => {
    var maxCharacters = 399;
    var formatCount =
      /[`¡ñÑàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ'~]/;
    if (formatCount.test(defaultValue)) {
      console.log("entre a format count");
      maxCharacters = 140;
      setCharacterLimit(280);
    } else {
      console.log("no entre a format count");
      maxCharacters = 160;
      setCharacterLimit(399);
    }
    setMessages(Math.ceil(defaultValue.length / maxCharacters));
    setCount(defaultValue.length + " / " + maxCharacters);
  }, [defaultValue]);

  return (
    <>
      <h5
        style={{
          marginTop: "0px",
          marginBottom: "10px",
          borderRadius: "5px",
          padding: "5px",
          background: "#6d757d",
          color: "#fff",
          width: "fit-content",
          fontWeight: "400",
          fontSize: "12px",
        }}
      >
        Los caracteres especiales aceptados son: # $ % & () * + , &#47; _ - . :
        ; &lt; &#61; &gt; ? ¡ @, el uso de algunos caracteres especiales reduce
        el numero máximo de caracteres por sms (ñÑàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ...).
      </h5>
      {/*<Form.Check
        type="switch"
        name="lengthMessage"
        ref={checkbox}
        label="Activar mensaje extendido"
        onClick={handleClick}
        className="prevent-validation"
      />*/}

      <FloatingLabel label={LeghtMessage} className="mb-3">
        <Form.Control
          as="textarea"
          name={name}
          id={name}
          value={defaultValue || ""}
          required
          disabled={isDisabled}
          maxLength={characterLimit}
          isInvalid={count > characterLimit}
          onScroll={(event) => {
            handleScroll(event);
          }}
          onChange={(event) => {
            //  setCount(event.target.value.length);
            changeHandler(event);
          }}
          placeholder="Escribe el mensaje:"
          style={{ height: "100px", resize: "none" }}
        />
        <Badge
          className="mt-2"
          style={{ minWidth: "143px" }}
          bg={`${count > characterLimit ? "danger" : "secondary"}`}
        >
          Caracteres: {count}
        </Badge>
        <Badge
          className="mt-2"
          bg={`${count > characterLimit ? "danger" : "secondary"}`}
        >
          Mensajes: {message}
        </Badge>
        {warning && (
          <span className="SpamWarning">
            Cada 160 caracteres o menos, se cobra 1 mensaje *
          </span>
        )}
      </FloatingLabel>
    </>
  );
};

export default SmsTextBox;
