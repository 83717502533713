import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";

const AdvanceTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps,
}) => {
  return (
    <Table responsive {...getTableProps(tableProps)}>
      <thead className={headerClassName}>
        <tr>
          {headers.map((column, index) => (
            <th
              key={index}
              {...column.getHeaderProps(
                column.getSortByToggleProps(column.headerProps)
              )}
            >
              {column.render("Header")}
              {column.canSort ? (
                column.isSorted ? (
                  column.isSortedDesc ? (
                    <span className="sort desc" />
                  ) : (
                    <span className="sort asc" />
                  )
                ) : (
                  <span className="sort" />
                )
              ) : (
                ""
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr key={i} className={rowClassName} {...row.getRowProps()}>
              {row.cells.map((cell, index) => {
                return (
                  <td key={index} {...cell.getCellProps(cell.column.cellProps)}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
      <tfoot className={headerClassName}>
        <tr>
          {headers.map((column, index) => {
            return <td key={"footcell_" + index}>{column.render("Footer")}</td>;
          })}
        </tr>
      </tfoot>
    </Table>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
};

export default AdvanceTable;
