import { useState } from 'react';

/* Bootstrap */
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

const loader = ({ show, handleClose }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="loaderModal"
      >
        <Modal.Body>
            <Spinner animation="border" variant="primary"/>
            <p style={{margin: "0"}}>Cargando...</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default loader;