import React from "react";
import { FormatDate } from "../../utils/FormatData";
import { Badge, Col, Row, Stack } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import AdvanceTableSearchBox from "../../components/AdvanceTableSearchBox";

// Icons
import * as AiIcons from "react-icons/ai";
import BtnExportToExcell from "../../utils/BtnExportToExcell";

const columns = [
  {
    accessor: "message",
    Header: "Detalles",
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <>
          <Row>
            <Col>{rowInfo.message}</Col>
          </Row>
          <Row>
            <Col className="DateDetails">{FormatDate(rowInfo.create)}</Col>
          </Row>
        </>
      );
    },
  },

  {
    accessor: "carrier",
    Header: "Carrier",
  },
  {
    accessor: "shortCode",
    Header: "Marcacion",
  },
  {
    accessor: "mask",
    Header: "Mascara",
  },
  {
    accessor: "origen",
    Header: "Origen",
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <>
          <Row>
            <Col className="DateDetails">
              <b>{rowInfo.origen}</b>{" "}
              {rowInfo.campaign ? " | " + rowInfo.campaign : ""}
            </Col>{" "}
          </Row>
        </>
      );
    },
  },

  {
    accessor: "status",
    Header: "Status",
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <>
          <div className="right">
            <Badge
              bg={
                rowInfo.status == "ENTREGADO"
                  ? "success"
                  : rowInfo.status == "PROGRAMADO"
                  ? "warning"
                  : "danger"
              }
            >
              {rowInfo.status}
            </Badge>
          </div>
        </>
      );
    },
  },
];
const ReportUserDetail = ({ listMessages, msisdnFilter, refreshFilter }) => {
  return (
    <>
      {listMessages && (
        <AdvanceTableWrapper
          columns={columns}
          data={listMessages}
          sortable
          pagination
          perPage={10}
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="DbDetails1">
                <Stack direction="horizontal" gap={2}>
                  <AiIcons.AiOutlinePhone />
                  <div className="ms-auto">
                    <p>{msisdnFilter}</p>
                  </div>
                </Stack>
                <div className="details">Número</div>
              </div>
            </Col>
          </Row>
          <Stack direction="horizontal">
            <div className="SearchBar SearchBarStack mt-0">
              <AdvanceTableSearchBox table />
            </div>
            {/* <div className="SearchBar ms-auto mt-0">
              <Button className="ms-auto" variant="secondary">
                Actualizar
              </Button>
            </div> */}
          </Stack>
          <div>
            <div style={{ textAlign: "left" }}>
              <h4>Lista de Mensajes</h4>
            </div>
            <div style={{ textAlign: "right" }}>
              <BtnExportToExcell
                dataReport={listMessages}
                fileName="SMS_Report"
              ></BtnExportToExcell>
            </div>
          </div>
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: "sm",
              striped: true,
              className: "fs--1 mb-0 overflow-hidden",
            }}
          />
          <AdvanceTablePagination table />
          <AdvanceTableFooter
            rowCount={listMessages.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
            pageSize={10}
          />
        </AdvanceTableWrapper>
      )}
    </>
  );
};

export default ReportUserDetail;
