import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

// Bootstrap
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";

// Componentes
import { ApiEndpoint, ApiSMSEndpoint } from "../utils/ApiEndpoint";
import { EncryptData } from "../utils/Encrypt";
import Notification from "../components/notification";
import { useAuth } from "../Context/AuthContext";
import { Badge, Col, Row } from "react-bootstrap";
import MsisdnTextBox from "../components/generic/_MsisdnTextBox";
import SmsTextBox from "../components/generic/_SmsTextBox";
import { httpClient } from "../utils/HttpClient";
import axios from "axios";

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const SendSMS = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [listShortLinks, setListShortLinks] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [carrierList, setCarrierList] = useState(false);
  const [shortCodesList, setShortCodesList] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(false);
  const [messageText, setMessageText] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [canSelectShortCode, setCanSelectShortCode] = useState(false);
  const [values, setValues] = useState({
    idcarrier: "0",
    idshortcode: "0",
    message: "",
    date: "",
    msisdn: "",
  });

  const [validated, setValidated] = useState(false);

  const getCarriersList = () => {
    const URICarriers = ApiEndpoint + "carriers";

    httpClient("GET", URICarriers, {}, userData.token).then((response) => {
      setCarrierList(response.data);
    });
  };

  const getShortCodes = (idCarrier) => {
    const URIShortCodes = ApiEndpoint + "shortcodesbycarrier/";
    httpClient("GET", URIShortCodes + idCarrier, {}, userData.token).then(
      (response) => {
        setShortCodesList(response.data);
      }
    );
  };

  const getShortLinks = () => {
    const URI = ApiEndpoint + "campaigns/shortLinks/" + userData.uid_partner;
    httpClient("GET", URI, {}, userData.token).then((response) => {
      setListShortLinks(response.data.filter((x) => x.active));
    });
  };

  useEffect(() => {
    getShortCodes(selectedCarrier);
  }, [selectedCarrier]);

  useEffect(() => {
    setCount(values.message.length);
  }, [values.message]);

  useEffect(() => {
    getCarriersList();
    getShortLinks();
  }, []);

  const [LeghtMessage, setLeght] = useState("Mensaje * (160 caracteres)");
  const checkbox = useRef();
  const [count, setCount] = React.useState(0);
  const [characterLimit, setCharacterLimit] = useState(160);

  const handleClick = () => {
    if (checkbox.current.checked) {
      setLeght("Mensaje * (320 caracteres)");
      setCharacterLimit(320);
    } else {
      setLeght("Mensaje * (160 caracteres)");
      setCharacterLimit(160);
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const [isDisabled, setIsDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Enviar");

  const sendSms = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);

    setIsDisabled(true);
    setButtonText("Enviando...");
    var userData = Auth.getSessionData();

    const currntTime = new Date().getTime();
    const encrtyp = EncryptData(
      userData.vc_partnerid + currntTime + userData.vc_secretkey
    );

    httpClient(
      "POST",
      ApiEndpoint + "users/userlimitsms",
      {
        uiduser: userData.uid_user,
      },
      userData.token
    ).then(function (response) {
      if (
        response.data.i_limit > 0 &&
        response.data.i_currentsms + 1 > response.data.i_limit
      ) {
        setMessageText(
          "Su usuario ha excedido el limite de mensajes por enviar"
        );
        setAlertType("warning");
        setShow(true);
        setIsDisabled(false);
        setValidated(false);
        setButtonText("Enviar");
        return;
      }

      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: ApiSMSEndpoint,
        data: {
          security: {
            partner_id: userData.vc_partnerid,
            time: currntTime,
            hash: encrtyp,
          },
          notify: {
            service_id: userData.vc_serviceid,
            number: values.msisdn,
            carrier: values.idcarrier,
            message: values.message,
            xtraparams: { panelUserId: userData.uid_user, source: "Panel" },
          },
        },
      })
        .then(function (response) {
          console.log(response);
          if (response.data.result.code != 1) {
            setMessageText(response.data.result.message);
            setAlertType("warning");
            setShow(true);
            setIsDisabled(false);
            setValidated(false);
            setButtonText("Enviar");

            return;
          }
          setMessageText("El mensaje se ha enviado...");
          setAlertType("");
          setShow(true);
          setIsDisabled(false);
          setValidated(false);
          setButtonText("Enviar");

          setValues({
            idcarrier: "0",
            idshortcode: "0",
            message: "",
            date: "",
            msisdn: "",
          });
          return;
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  return (
    <>
      <div className="">
        <Stack direction="horizontal" gap={2}>
          <div>
            <h2>Enviar SMS</h2>
            <h3>1. Ingrese los datos para enviar un mensaje directamente.</h3>
          </div>
          <div className="ms-auto"></div>
        </Stack>

        <div className="SendSMS">
          <Form noValidate validated={validated} onSubmit={sendSms}>
            <MsisdnTextBox
              name="msisdn"
              defaultValue={values.msisdn}
              changeHandler={onChange}
            ></MsisdnTextBox>
            <FloatingLabel label="Operadora *" className="mb-3">
              <Form.Select
                id="idcarrier"
                name="idcarrier"
                onChange={(selectedOption) => {
                  onChange(selectedOption);
                  setSelectedCarrier(`${selectedOption.target.value}`);
                }}
                value={values.idcarrier}
                required
                aria-label="select_carrier"
              >
                <option value="">Seleccione una opción de la lista</option>
                <option value="0">Todos</option>
                {!!carrierList?.length &&
                  carrierList.map((carrier) => (
                    <option value={carrier.idcarrier}>{carrier.carrier}</option>
                  ))}
              </Form.Select>
            </FloatingLabel>
            {canSelectShortCode && (
              <>
                <FloatingLabel label="Numero corto *" className="mb-3">
                  <Form.Select
                    id="idshortcode"
                    name="idshortcode"
                    onChange={(selectedOption) => {
                      onChange(selectedOption);
                    }}
                    value={values.idshortcode}
                    required
                    aria-label="select_shortCode"
                  >
                    <option value="">Seleccione una opción de la lista</option>
                    {!!shortCodesList?.length &&
                      shortCodesList.map((shortcode) => (
                        <option value={shortcode.idshortcode}>
                          {shortcode.shortcode}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </>
            )}
            <Divider />
            <h3>2. Redacte el mensaje</h3>
            <Row>
              <Col sm={8}>
                <SmsTextBox
                  name="message"
                  defaultValue={values.message}
                  changeHandler={onChange}
                ></SmsTextBox>
              </Col>
              <Col sm={4}>
                <div
                  className="ShortLink"
                  style={{
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                  }}
                >
                  <h3>Urls Cortas</h3>
                  <h5>
                    Al darle click se agregará la url corta relacionada con el
                    alias que aparece a continuación:
                  </h5>
                  <div className="AllLinks">
                    <>
                      {listShortLinks &&
                        listShortLinks.map((shortlink) => {
                          return (
                            <>
                              {" "}
                              <Badge
                                onClick={() => {
                                  setMessageText(shortlink.shortlinks);
                                  setValues({
                                    ...values,
                                    message:
                                      values.message +
                                      " " +
                                      shortlink.shortlinks +
                                      " ",
                                  });
                                }}
                                bg="primary"
                              >
                                {shortlink.displayname}
                              </Badge>{" "}
                            </>
                          );
                        })}
                    </>
                  </div>
                </div>
              </Col>
            </Row>

            <Divider />

            {/* <FloatingLabel
              controlId="floatingSelect"
              label="Programar envío:"
              className="mb-3"
            >
              <Form.Control type="date" name="" id="" placeholder="Programar" />
            </FloatingLabel> */}

            <div className="text-right">
              <Button
                disabled={isDisabled}
                variant="primary"
                type="submit"
                className="align-self-end"
              >
                {" "}
                {buttonText}
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <Notification
        show={show}
        alertType={alertType}
        setShow={setShow}
        message={messageText}
      />

      {/* <Edit show={show} handleShow={handleShow} handleClose={handleClose}/> */}
    </>
  );
};

export default SendSMS;
