import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Bootstrap
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";

// Icons
import * as AiIcons from "react-icons/ai";

// Assets
import Avatar from "../../assets/avatar.jpg";

// Componentes
import ModalEdit from "../../components/ModalEdit";
import axios from "axios";
import { ApiEndpoint } from "../../utils/ApiEndpoint.js";
import { useAuth } from "../../Context/AuthContext.js";
import Notification from "../../components/notification.js";
import EditUserData from "./editUser";

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 0px;
  margin-bottom: 20px;
`;

const UserInfo = () => {
  const Auth = useAuth();
  var userDataFromSess = Auth.getSessionData();
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [messageNotification, setMessageNotification] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getUserData = () => {
    setUserData(userDataFromSess);
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <>
      <div className="bodyPage">
        <Stack direction="horizontal" gap={2}>
          <div>
            <h2>Perfil</h2>
          </div>
          <div className="ms-auto"></div>
        </Stack>

        <div
          className="UserPicBig"
          style={{ background: `url(${Avatar})` }}
        ></div>
        <Divider className="DividerUserPic" />

        <Row>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="DbDetails1">
              <Stack direction="horizontal" gap={2}>
                <AiIcons.AiOutlineNumber />
                <div className="ms-auto">
                  <p>{userData.vc_partnerid}</p>
                </div>
              </Stack>
              <div className="details">Número de cuenta</div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <div className="DbDetails2">
              <Stack direction="horizontal" gap={2}>
                <AiIcons.AiOutlineUser />
                <div className="ms-auto">
                  <p>{`${userData.vc_name} ${userData.vc_firstname}`}</p>
                </div>
              </Stack>
              <div className="details">Nombre</div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="DbDetails3">
              <Row>
                <Col xs={3} sm={3} md={2} lg={2} xl={1}>
                  <Stack direction="horizontal" gap={2}>
                    <AiIcons.AiOutlineInfoCircle className="mt-0" />
                  </Stack>
                </Col>
                <Col xs={9} sm={9} md={10} lg={10} xl={4}>
                  <Stack direction="vertical">
                    <div className="ms-auto">
                      <p>{userData.vc_email}</p>
                    </div>
                    <div className="details">Email</div>
                  </Stack>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                  <Stack direction="vertical">
                    <div className="ms-auto">
                      <p>{userData.vc_phone}</p>
                    </div>
                    <div className="details">Teléfono</div>
                  </Stack>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                  <Stack direction="vertical">
                    <div className="ms-auto">
                      <p>{userData.vc_displayname}</p>
                    </div>
                    <div className="details">Usuario</div>
                  </Stack>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div className="text-right">
          <Button
            variant="primary"
            type="submit"
            className="align-self-end"
            onClick={() => {
              setShow(true);
            }}
          >
            Editar
          </Button>
        </div>
      </div>
      <Notification
        alertType={notificationType}
        show={showNotification}
        setShow={setShowNotification}
        message={messageNotification}
      />
      <ModalEdit
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        title="Editando perfil"
      >
        <EditUserData
          setShowNotification={setShowNotification}
          setMessageNotification={setMessageNotification}
          setNotificationType={setNotificationType}
          handleShow={handleShow}
          handleClose={handleClose}
        />
      </ModalEdit>
    </>
  );
};

export default UserInfo;
