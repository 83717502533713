import React, { useState } from "react";
import styled from "styled-components";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { MissInfoReport } from "../../components/MissInfo";
import SimpleDateFilters from "./_SimpleDateFilters";
import { Stack } from "react-bootstrap";
import AdminProfilerList from "./_AdminProfilerList";
import { httpClient } from "../../utils/HttpClient";
import { useAuth } from "../../Context/AuthContext";
const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ReportPortabilidadAdmin = () => {
  const Auth = useAuth();
  const [reportData, setReportData] = useState(null);
  const [periodStr, setPeriodStr] = useState("");
  const [loading, setLoading] = useState(false);
  var userData = Auth.getSessionData();

  const getDataReport = (filters) => {
    setReportData(null);
    setLoading(true);
    const URI = ApiEndpoint + "administration/report/profilersummary";

    httpClient(
      "POST",
      URI,
      {
        from: filters.dateFrom,
        to: filters.dateTo,
      },
      userData.token
    )
      .then(function (response) {
        setReportData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className="">
        <Stack direction="horizontal" gap={2}>
          <div>
            <h2>Consultas API de Perfilador por cliente</h2>
            <h3>
              Consulte las consultas a la API de portabilidad con base en la
              fecha.
            </h3>
          </div>
        </Stack>

        <div className="ReportDate">
          <SimpleDateFilters
            onRefresh={getDataReport}
            setPeriodStr={setPeriodStr}
            loading={loading}
          />
        </div>

        <Divider />
        {reportData ? (
          <>
            <AdminProfilerList
              periodStr={periodStr}
              listMessages={reportData}
            ></AdminProfilerList>
          </>
        ) : (
          <MissInfoReport />
        )}
      </div>
    </>
  );
};

export default ReportPortabilidadAdmin;
