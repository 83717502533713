export const FormatDate = (data) => {
  if (!data) return "-";
  var date = new Date(data);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var dt = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return dt + "/" + month + "/" + year;
};

export const FormatDateWTime = (data) => {
  if (!data) return "-";
  var date = new Date(data);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var dt = date.getDate();
  var hr = date.getHours();
  var mm = date.getMinutes();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (hr < 10) {
    hr = "0" + hr;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dt + "/" + month + "/" + year + " " + hr + ":" + mm;
};

export const FormatDateToDb = (data) => {
  var date = new Date(data);
  var year = date.getFullYear();
  var month = date.getMonth();
  var dt = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return year + "/" + month + "/" + dt;
};

export const FormatCurrency = (data) => {
  return (
    "$" +
    (isNumber(data)
      ? parseFloat(data)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,")
      : data)
  );
};

export const FormatCurrency2Dec = (data) => {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return "$" + formatter.format(data);
};

export const FormatCurrency3Dec = (data) => {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });
  return "$" + formatter.format(data);
};

export const FormatInt = (data) => {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(data);
};

export const IsNumber = (data) => {
  return IsNumber(data);
};

var isNumber = function isNumber(value) {
  return typeof value === "number" && isFinite(value);
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
