/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";

/* Bootstrap */
import Spinner from 'react-bootstrap/Spinner';

const ReportSearchBox = ({
  filter,
  setFilter,
  isDisabled,
  placeholder = "Ingresa un número telefónico...",
}) => {
  const [value, setValue] = useState(filter);

  return (
    <InputGroup className="position-relative">
      <FormControl
        value={value || ""}
        onChange={(event) => {
          event.target.value = event.target.value.replace(/[^\d]+/g, "");
          setValue(event.target.value);
        }}
        size=""
        pattern="^\d{10}$"
        id="search"
        placeholder={placeholder}
        type="number"
        className="shadow-none"
      />

      {isDisabled ? (
        <InputGroup.Text className="btn btn-secondary">
          <Button
            disabled
            className="btn btn-secondary"
            onClick={() => {
              setFilter(value);
            }}
          >
            <Spinner  size="sm" animation="border" variant="light" /> Consultando...
          </Button>
        </InputGroup.Text>
      )
      :
      (
        <InputGroup.Text className="btn btn-primary">
          <Button
            onClick={() => {
              setFilter(value);
            }}
          >
            Consultar
          </Button>
        </InputGroup.Text>
      )}
    </InputGroup>
  );
};

export default ReportSearchBox;
