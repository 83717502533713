import React, { useState } from "react";

// Bootstrap
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import MsisdnTextBox from "../../components/generic/_MsisdnTextBox";
import { httpClient } from "../../utils/HttpClient";
import { useAuth } from "../../Context/AuthContext";

const EditRecordDatabase = ({
  idPartnerDatabase,
  idRecord,
  handleClose,
  setDisplayMessage,
  setMessage,
  setNotificationType,
  uidDatabase,
}) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({
    msisdn: "",
  });

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    const URICustomer = ApiEndpoint + "campaigns/createRecordPartnersDatabase/";
    if (idRecord > 0) {
      httpClient(
        "PUT",
        URICustomer + idRecord,
        {
          idpartnerdatabase: idPartnerDatabase,
          phone: values.msisdn,
          added: new Date(),
          active: true,
          details: {},
        },
        userData.token
      )
        .then(function (response) {
          handleClose();
          return;
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      httpClient(
        "POST",
        URICustomer,
        {
          uiddatabase: uidDatabase,
          phone: values.msisdn,
        },
        userData.token
      )
        .then(function (response) {
          console.log(response.data);
          if (response.data.error) {
            setMessage("El registro ya existe");
            setDisplayMessage(true);
            setNotificationType("warning");
            return;
          }
          setMessage("Registro agregado exitosamente");
          setDisplayMessage(true);
          setNotificationType("");
          handleClose();
          return;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit1}>
      <MsisdnTextBox
        name="msisdn"
        defaultValue={values.msisdn}
        label="Número * (10 dígitos)"
        changeHandler={onChange}
      ></MsisdnTextBox>

      <div className="text-right">
        <Button variant="primary" type="submit" className="align-self-end">
          Guardar
        </Button>
      </div>

      {/* <Alert key={"secondary"} variant={"secondary"} className="Notice">
        <b>Nota:</b> Texto de nota necesaria para agregar un registro a la base
        de datos.
        </Alert> */}
    </Form>
  );
};

export default EditRecordDatabase;
