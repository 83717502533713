import React from "react";

// Assets
import * as FiIcons from "react-icons/fi";
import * as RiIcons from "react-icons/ri";
import * as HiIcons from "react-icons/hi";

export const SideBarData = [
  {
    title: "Inicio",
    path: "/Home",
    isPartnerRoute: true,
    roles: [2, 3],
    isAdminRoute: false,
    icon: <FiIcons.FiHome />,
  },
  {
    title: "Inicio",
    path: "/Home",
    isPartnerRoute: false,
    roles: [1, 4, 5],
    isAdminRoute: true,
    icon: <FiIcons.FiHome />,
  },
  {
    title: "API sms",
    path: "/Sms",
    isPartnerRoute: true,
    roles: [2, 3],
    isAdminRoute: false,
    icon: <RiIcons.RiChat4Line />,
  },
  {
    title: "Configuración",
    isPartnerRoute: false,
    isAdminRoute: true,
    roles: [1],
    icon: <FiIcons.FiSliders />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Clientes",
        path: "/administration/Customers",
        isPartnerRoute: false,
        isAdminRoute: true,
        roles: [1],
        icon: <FiIcons.FiUserCheck />,
      },
      {
        title: "Marcaciones",
        path: "/administration/shortcodes",
        isPartnerRoute: false,
        isAdminRoute: true,
        roles: [1],
        icon: <FiIcons.FiPhoneCall />,
      },
      {
        title: "Mascaras Marcaciones",
        path: "/administration/shortcodesmasks",
        isPartnerRoute: false,
        isAdminRoute: true,
        roles: [1],
        icon: <FiIcons.FiPhoneCall />,
      },
      {
        title: "Keywords",
        path: "/administration/keywords",
        isPartnerRoute: false,
        isAdminRoute: true,
        roles: [1],
        icon: <FiIcons.FiPhoneCall />,
      },
      // {
      //   title: "Paquetes de SMS",
      //   path: "/administration/smsPackages",
      //   isPartnerRoute: false,
      //   isAdminRoute: true,
      //   icon: <FiIcons.FiUserCheck />,
      // },
      {
        title: "Tarifas de SMS",
        path: "/Configuration/DefaultPrices",
        isAdminRoute: true,
        roles: [1],
        icon: <FiIcons.FiUserCheck />,
      },
    ],
  },

  {
    title: "Usuarios",
    isPartnerRoute: true,
    isAdminRoute: false,
    roles: [2],
    icon: <RiIcons.RiUserSettingsLine />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Usuarios",
        path: "/Administration/Users",
        isPartnerRoute: true,
        roles: [2],
        isAdminRoute: false,
        icon: <FiIcons.FiUsers />,
      },
    ],
  },
  {
    title: "Usuarios",
    isPartnerRoute: false,
    isAdminRoute: true,
    roles: [1],
    icon: <RiIcons.RiUserSettingsLine />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Usuarios Operativos",
        path: "/Administration/staff",
        isPartnerRoute: false,
        isAdminRoute: true,
        roles: [1],
        icon: <FiIcons.FiUsers />,
      },
    ],
  },
  {
    title: "Herramientas",
    isPartnerRoute: false,
    isAdminRoute: true,
    roles: [1, 5],
    icon: <RiIcons.RiToolsLine />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Urls Cortas",
        path: "Administration/ShortLinks",
        isPartnerRoute: false,
        isAdminRoute: true,
        roles: [1, 5],
        icon: <FiIcons.FiDatabase />,
      },
    ],
  },
  {
    title: "Herramientas",
    isPartnerRoute: true,
    isAdminRoute: false,
    roles: [2, 3],
    icon: <RiIcons.RiToolsLine />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Urls Cortas",
        path: "/ShortLinks",
        isPartnerRoute: true,
        roles: [2, 3],
        isAdminRoute: false,
        icon: <FiIcons.FiDatabase />,
      },
    ],
  },
  {
    title: "Contactos",
    isPartnerRoute: false,
    isAdminRoute: true,
    roles: [1, 5],
    icon: <RiIcons.RiContactsBook2Line />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Grupo de contactos",
        path: "Contacts/ContactsDatabase",
        isPartnerRoute: true,
        roles: [1, 5],
        isAdminRoute: false,
        icon: <FiIcons.FiDatabase />,
      },
    ],
  },
  {
    title: "Contactos",
    isPartnerRoute: true,
    isAdminRoute: false,
    roles: [2, 3],
    icon: <RiIcons.RiContactsBook2Line />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Grupo de contactos",
        path: "Db/ContactsDatabase",
        isPartnerRoute: true,
        roles: [2, 3],
        isAdminRoute: false,
        icon: <FiIcons.FiDatabase />,
      },
    ],
  },
  {
    title: "Campaña",
    isPartnerRoute: true,
    isAdminRoute: false,
    roles: [2, 3],
    icon: <HiIcons.HiOutlineSpeakerphone />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Campañas",
        path: "/Campaigns/Campaigns",
        isPartnerRoute: true,
        roles: [2, 3],
        isAdminRoute: false,
        icon: <RiIcons.RiLineChartLine />,
      },
    ],
  },
  {
    title: "Campaña",
    isPartnerRoute: false,
    isAdminRoute: true,
    roles: [1, 5],
    icon: <HiIcons.HiOutlineSpeakerphone />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Campañas",
        path: "/Administration/Campaigns",
        isPartnerRoute: false,
        roles: [1, 5],
        isAdminRoute: true,
        icon: <RiIcons.RiLineChartLine />,
      },
    ],
  },
  {
    title: "Dos vías",
    isPartnerRoute: true,
    isAdminRoute: false,
    roles: [2, 3],
    icon: <RiIcons.RiQuestionAnswerLine />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Mensajes Mo",
        path: "/Reports/ReportMoDate",
        roles: [2, 3],
        isPartnerRoute: true,
        isAdminRoute: false,
        icon: <FiIcons.FiUser />,
      },
    ],
  },
  {
    title: "Reportes",
    isPartnerRoute: true,
    isAdminRoute: false,
    roles: [2, 3],
    icon: <FiIcons.FiFileText />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Por usuario",
        path: "/Reports/ReportUser",
        isPartnerRoute: true,
        roles: [2, 3],
        isAdminRoute: false,
        icon: <FiIcons.FiUser />,
      },
      {
        title: "Detallado Por fecha",
        path: "/Reports/ReportDate",
        isPartnerRoute: true,
        isAdminRoute: false,
        roles: [2, 3],
        icon: <FiIcons.FiCalendar />,
      },
      {
        title: "Resumen",
        path: "/Reports/ReportSmsSmry",
        isPartnerRoute: true,
        isAdminRoute: false,
        roles: [2, 3],
        icon: <FiIcons.FiCalendar />,
      },
      {
        title: "Resumen Por Carrier",
        path: "/Reports/ReportSmsSmryCarrier",
        isPartnerRoute: true,
        isAdminRoute: false,
        roles: [2, 3],
        icon: <FiIcons.FiCalendar />,
      },
      {
        title: "Resumen Por Usuario",
        path: "/Reports/ReportSmsSmryUser",
        isPartnerRoute: true,
        isAdminRoute: false,
        roles: [2, 3],
        icon: <FiIcons.FiCalendar />,
      },
      {
        title: "Reportes Generados",
        path: "/Reports/ReportsUser",
        isPartnerRoute: true,
        isAdminRoute: false,
        roles: [2, 3],
        icon: <FiIcons.FiCalendar />,
      },
    ],
  },
  {
    title: "Reportes",
    isPartnerRoute: false,
    isAdminRoute: true,
    roles: [1, 4, 5],
    icon: <FiIcons.FiFileText />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Mo",
        path: "/AdminReports/Mo",
        isPartnerRoute: false,
        roles: [1, 4, 5],
        isAdminRoute: true,
        icon: <FiIcons.FiCalendar />,
      },
      {
        title: "Sms Detalle por Cliente",
        path: "/AdminReports/Smsdetail",
        isPartnerRoute: false,
        roles: [1, 4, 5],
        isAdminRoute: true,
        icon: <FiIcons.FiCalendar />,
      },
      {
        title: "Campañas por Cliente",
        path: "/AdminReports/campaigns",
        isPartnerRoute: false,
        roles: [1, 4, 5],
        isAdminRoute: true,
        icon: <FiIcons.FiCalendar />,
      },
      {
        title: "Sms por Cliente",
        path: "/AdminReports/Sms",
        isPartnerRoute: false,
        roles: [1, 4, 5],
        isAdminRoute: true,
        icon: <FiIcons.FiCalendar />,
      },
      {
        title: "Resumen por Carrier",
        path: "/AdminReports/SummaryCarrier",
        isPartnerRoute: false,
        roles: [1, 4, 5],
        isAdminRoute: true,
        icon: <FiIcons.FiCalendar />,
      },
      {
        title: "Api Perfilador",
        path: "/AdminReports/Portabilidad",
        isPartnerRoute: false,
        roles: [1, 4, 5],
        isAdminRoute: true,
        icon: <FiIcons.FiCalendar />,
      },
    ],
  },
];
