import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import CustomerPartners from "./_CustomerPartners";
import UsersByCustomer from "./_UsersByCustomer";
import CustomerPrices from "./_CustomerPrices";
import ShortCodesListByCustomer from "./_ShortCodesListByCustomer";
import EditShortCodeService from "./_EditShortCodeService";
import ModalEdit from "../../components/ModalEdit";
import { httpClient } from "../../utils/HttpClient";
import { useAuth } from "../../Context/AuthContext";

const CustomerDetail = () => {
  const Auth = useAuth();
  let { id } = useParams();
  var userData = Auth.getSessionData();
  const [customerInfo, setCustomerInfo] = useState(null);
  const getCustomerData = () => {
    const URI = ApiEndpoint + "administration/customer/" + id;
    httpClient("GET", URI, {}, userData.token)
      .then(function (response) {
        console.log(response.data);
        setCustomerInfo(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getCustomerData();
  }, []);
  return (
    <>
      {customerInfo && (
        <>
          <h2>Detalle de Cliente {customerInfo.comercialname}</h2>
          <h4>Información</h4>
          <div className="wrapp">
            <CustomerPartners
              idCustomer={customerInfo.idcustomer}
            ></CustomerPartners>
          </div>
          <div className="wrapp">
            <ShortCodesListByCustomer
              uidCustomer={id}
              idCustomer={customerInfo.idcustomer}
            ></ShortCodesListByCustomer>
          </div>
          <div className="wrapp">
            <CustomerPrices
              idCustomer={customerInfo.idcustomer}
              uidCustomer={id}
            ></CustomerPrices>
          </div>
          <div className="wrapp">
            <UsersByCustomer
              idCustomer={customerInfo.idcustomer}
            ></UsersByCustomer>
          </div>
        </>
      )}
    </>
  );
};

export default CustomerDetail;
