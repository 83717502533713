import axios from "axios";
import React, { useEffect, useState } from "react";

// Bootstrap
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import Badge from "react-bootstrap/Badge";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { FormatDate, FormatDateWTime } from "../../utils/FormatData"; // Icons
import * as FiIcons from "react-icons/fi";
import { useAuth } from "../../Context/AuthContext";

function WidgetNextCampaigns() {
  const Auth = useAuth();
  const [dataReport, setDataReport] = useState(null);

  const getSmsData = () => {
    var userData = Auth.getSessionData();
    const URIReport = ApiEndpoint + "campaigns/getNextPartnerCampaigns";
    const config = {
      headers: { Authorization: `Bearer ${userData.token}` },
    };

    axios
      .post(
        URIReport,
        {
          uidpartner: userData.uid_partner,
          user: userData.uid_user,
        },
        config
      )
      .then(function (response) {
        setDataReport(response.data);
        console.log("response");
        console.log(response.data);
        return;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getSmsData();
  }, []);

  return (
    <>
      {dataReport && (
        <div className="MessagesSended">
          <Stack direction="horizontal" gap={2}>
            <h6>Proximas Campañas Programadas</h6>
            <div className="ms-auto">
              <FiIcons.FiRefreshCw onClick={() => getSmsData()} />
            </div>
          </Stack>
          <div className="Scroll">
            {dataReport &&
              dataReport.map((sms) => {
                return (
                  <Card>
                    <Card.Body>
                      <Card.Text>
                        <Stack direction="horizontal" gap={2}>
                          <div>{sms.vc_campaignname} </div>
                          <div>·</div>
                          <div
                            className="ms-auto"
                            style={{ color: "green", fontWeight: "bold" }}
                          >
                            <span>
                              <Badge bg="success">SMS: {sms.i_records}</Badge>
                            </span>
                          </div>
                        </Stack>
                      </Card.Text>
                      <Stack direction="horizontal" gap={2}>
                        <div>
                          <span>Mensaje: {sms.vc_message}</span>
                        </div>
                        <div>·</div>
                        <div
                          className="ms-auto"
                          style={{ color: "green", fontWeight: "bold" }}
                        >
                          <span>{FormatDateWTime(sms.tst_send)}</span>
                        </div>
                      </Stack>
                    </Card.Body>
                  </Card>
                );
              })}
          </div>
          {/* <div className="details">
            <Link to="/Reports/ReportDate">Ver detalles</Link>
          </div> */}
        </div>
      )}
    </>
  );
}

export default WidgetNextCampaigns;
