import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { Col, Row, Stack } from "react-bootstrap";
import * as AiIcons from "react-icons/ai";
import { FormatDate } from "../../utils/FormatData";
import CampaignMessages from "./_CampaignMessages";
import DataBaseRecords from "../ContactsDatabase/_DataBaseRecords";
import ModalEdit from "../../components/ModalEdit";

import NewCampaignMessage from "./_NewCampaignMessage";

import MessageDetail from "./MessageDetail";
import Confirm from "../../components/Confirm";
import { useAuth } from "../../Context/AuthContext";
import moment from "moment";
import { httpClient } from "../../utils/HttpClient";

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const CampaignDetail = () => {
  let { id } = useParams();
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [showAddMessage, setShowAddMessage] = useState(false);
  const [showDetailMessage, setShowDetailMessage] = useState(false); /*******/
  const [idRecord, setIdRecord] = useState(false);
  const [uidDatabase, setUidDatabase] = useState(false);
  const [campaignInfo, setCampaignInfo] = useState(null);
  const [campaignMessages, setCampaignMessages] = useState(null);
  const [idRecordToDetail, setIdRecordToDetail] = useState(false);
  const [idRecordMessageToDelete, setIdRecordMessageToDelete] = useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);

  const [status, setStatus] = useState(null);
  const [nameCampaign, setNameCampaign] = useState(null);

  const handleClose = () => {
    console.log("entre a handlecolse");
    setShowAddMessage(false);
    setShowDetailMessage(false); /*******/
    getCampaign();
    getCampaignMessages();
  };

  const InactivateMessage = () => {
    const URIDb =
      ApiEndpoint +
      "campaigns/inactivateCampaignMessage/" +
      idRecordMessageToDelete;

    httpClient("DELETE", URIDb, {}, userData.token)
      .then((response) => {
        getCampaign();
        setShowConfirmCancel(false);
        getCampaignMessages();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleShowMessage = () => setShowAddMessage(true);
  const handleShowDetailMessage = () => setShowDetailMessage(true); /*******/

  const getCampaign = () => {
    const URI = ApiEndpoint + "campaigns/getPartnerCampaign/";

    httpClient("GET", URI + id, {}, userData.token).then((response) => {
      setCampaignInfo(response.data[0]);
      setUidDatabase(`${response.data[0].uid_database}`);
    });
  };

  const getCampaignMessages = () => {
    const URI = ApiEndpoint + "campaigns/getPartnerCampaignMessages/";
    httpClient("GET", URI + id, {}, userData.token).then((response) => {
      setCampaignMessages(response.data);
    });
  };

  useEffect(() => {
    getCampaign();
    getCampaignMessages();
  }, []);

  return (
    <>
      {campaignInfo && (
        <>
          <div className="">
            <h2>Detalles de Campaña</h2>

            <h4>Información</h4>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                <div className="DbDetails1">
                  <Stack direction="horizontal" gap={2}>
                    <AiIcons.AiOutlineDatabase />
                    <div className="ms-auto">
                      <p>{campaignInfo.vc_campaignname}</p>
                    </div>
                  </Stack>
                  <div className="details">Nombre</div>
                </div>
              </Col>
              <Col xs={8} sm={8} md={4} lg={4} xl={2}>
                <div className="DbDetails2">
                  <Stack direction="horizontal" gap={2}>
                    <AiIcons.AiOutlineNumber />
                    <div className="ms-auto">
                      <p>
                        {campaignInfo.i_totalmessages} /{" "}
                        {campaignInfo.i_contacts}
                      </p>
                    </div>
                  </Stack>
                  <div className="details">Mensajes / Contactos </div>
                </div>
              </Col>
              <Col xs={8} sm={8} md={4} lg={4} xl={2}>
                <div className="DbDetails3">
                  <Stack direction="horizontal" gap={2}>
                    <AiIcons.AiOutlineCalendar />
                    <div className="ms-auto">
                      <p>
                        {moment(campaignInfo.tst_create).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </Stack>
                  <div className="details">Fecha de creación</div>
                </div>
              </Col>
              <Col xs={8} sm={8} md={4} lg={4} xl={2}>
                <div className="DbDetails3">
                  <Stack direction="horizontal" gap={2}>
                    <AiIcons.AiOutlineCalendar />
                    <div className="ms-auto">
                      <p>{campaignInfo.vc_mask}</p>
                    </div>
                  </Stack>
                  <div className="details">Mascara</div>
                </div>
              </Col>

              <Col xs={8} sm={8} md={4} lg={4} xl={2}>
                <div
                  className={
                    campaignInfo.b_active
                      ? "DbDetailsStatusON"
                      : "DbDetailsStatusOFF"
                  }
                >
                  <Stack direction="horizontal" gap={2}>
                    <AiIcons.AiOutlineFlag />
                    <div className="ms-auto">
                      <p>{campaignInfo.b_active ? "Active" : "Inactiva"}</p>
                    </div>
                  </Stack>
                  <div className="details">Estatus</div>
                </div>
              </Col>
            </Row>
            <div className="wrapp">
              {campaignMessages && (
                <CampaignMessages
                  setShow={setShowAddMessage}
                  setShowDetail={setShowDetailMessage}
                  dataBaseInfo={campaignMessages}
                  setIdRecord={setIdRecord}
                  setIdRecordToDetail={setIdRecordToDetail}
                  setIdRecordMessageToDelete={setIdRecordMessageToDelete}
                  setShowConfirmCancel={setShowConfirmCancel}
                  setStatus={setStatus}
                  setNameCampaign={setNameCampaign}
                  isDatabaseDeleted={campaignInfo.b_deleted}
                ></CampaignMessages>
              )}
            </div>
            <div className="wrapp">
              <DataBaseRecords
                totalRecords={campaignInfo.i_contacts}
                uidDatabase={uidDatabase}
                idpartnerdatabase={campaignInfo.id_partnerdatabase}
                isHidden={campaignInfo.b_deleted || campaignInfo.b_hidden}
              ></DataBaseRecords>

              <ModalEdit
                show={showAddMessage}
                handleShow={handleShowMessage}
                handleClose={handleClose}
                title="Agregar Mensaje"
              >
                <NewCampaignMessage
                  CampaignName={`${campaignInfo.vc_campaignname}`}
                  uidCampaign={`${id}`}
                  handleCloseParent={handleClose}
                  uidDatabase={uidDatabase}
                ></NewCampaignMessage>
              </ModalEdit>
              <ModalEdit
                show={showDetailMessage}
                handleShow={handleShowDetailMessage}
                handleClose={handleClose}
                title="Detalle de Mensaje"
              >
                <MessageDetail
                  uidCampaign={`${id}`}
                  idCampaignMessage={idRecordToDetail}
                  idStatus={status}
                  nameCampaign={nameCampaign}
                ></MessageDetail>
              </ModalEdit>
              <Confirm
                show={showConfirmCancel}
                handleClose={() => setShowConfirmCancel(false)}
                message={"Desea cancelar el mensaje seleccionado?"}
                handleConfirm={InactivateMessage}
              ></Confirm>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CampaignDetail;
