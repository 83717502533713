import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
  scales,
} from "chart.js";
import { Line } from "react-chartjs-2";

import "chartjs-adapter-date-fns";
import { es } from "date-fns/locale";

import faker from "faker";
import { useAuth } from "../../Context/AuthContext";
import axios from "axios";
import { ApiEndpoint } from "../../utils/ApiEndpoint";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
  scales
);

export function ChartBitly({ LinkData }) {
  const Auth = useAuth();
  const [show, setShow] = useState(false);
  const [token, setToken] = useState(false);
  const [CliksInfo, setCliksInfo] = useState(null);

  const [Labels, setLabels] = useState([]);
  const [Data, setData] = useState([]);

  const GetToken = () => {
    var userData = Auth.getSessionData();
    axios
      .get(ApiEndpoint + "partnersettings/" + userData.uid_partner)
      .then(function (response) {
        setToken(response.data.tokenbitly);
        GetInfoUrl(response.data.tokenbitly);
      });
  };

  const GetInfoUrl = (state) => {
    const config = {
      headers: {
        Authorization: "Bearer " + state,
        "Content-Type": "application/json",
      },
    };
    axios
      .get(
        "https://api-ssl.bitly.com/v4/bitlinks/" +
          ShortLink +
          "/clicks?unit=day&units=-1&size=10",
        config
      )
      .then(function (response) {
        var LinkData = response.data.link_clicks.slice(0, 10);        
        setCliksInfo(LinkData);
        setData(LinkData.map((UrlInfo) => UrlInfo.clicks));
        setLabels(LinkData.map((UrlInfo) => UrlInfo.date.substring(0, 10)));
      })
      .catch(function (error) {
        setShow(true);
        console.log(error);
      });
  };

  useEffect(() => {
    console.log(LinkData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()));
    setData(LinkData.map((UrlInfo) => UrlInfo.clicks));
    setLabels(LinkData.map((UrlInfo) => UrlInfo.date));
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Clicks por día",
      },
    },
    scales: {
      x: {
        reverse: false,
        type: "time",
        distribution: 'linear',
        time: {
          unit: "day",
          tooltipFormat: "dd LLL",
          displayFormats: {
            day: "dd LLL",
          },
        },
        adapters: {
          date: {
            locale: es,
          },
        },
      },
      y: {},
    },
  };

  const labels = Labels;

  const data = {
    labels,
    datasets: [
      {
        label: "Clicks: ",
        data: Data,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <>
      <div style={{ height: "300px", width: "100%" }}>
        <Line options={options} data={data} />

        {/*CliksInfo != null ? (
            CliksInfo.map(UrlInfo => (
                <p> Clicks: {UrlInfo.clicks} | Fecha: {UrlInfo.date} </p>
            ))
            ) : ("no hay información")*/}
      </div>
    </>
  );
}

export default ChartBitly;
