import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const ButtonRefresh = ({
  refreshFunction,
  legendButton = "Consultar",
  loading = false,
  typeButton = "button",
}) => {
  const [buttonText, setButtonText] = useState(legendButton);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (loading) {
      setIsDisabled(true);
      setButtonText("Generando...");
    } else {
      setIsDisabled(false);
      setButtonText(legendButton);
    }
  }, [loading]);
  return (
    <>
      <Button
        disabled={isDisabled}
        variant="primary"
        type={typeButton}
        onClick={() => {
          refreshFunction();
        }}
      >
        {buttonText}
      </Button>
    </>
  );
};

export default ButtonRefresh;
