import React from "react";
import { Badge, Col, Row, Stack } from "react-bootstrap";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import BtnExportToExcell from "../../utils/BtnExportToExcell";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";

import * as AiIcons from "react-icons/ai";
import { FormatDateWTime } from "../../utils/FormatData";
const columns = [
  {
    accessor: "vc_customer",
    Header: "Cliente",
  },
  {
    accessor: "vc_partner",
    Header: "Partner",
  },
  {
    accessor: "vc_message",
    Header: "Mensaje",
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <>
          <Row>
            <Col className="DateDetails">
              <b>{rowInfo.vc_message}</b>
            </Col>
          </Row>
        </>
      );
    },
  },
  {
    accessor: "tst_create",
    Header: "Recepcion",
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <>
          <Row>
            <Col className="DateDetails">
              {FormatDateWTime(rowInfo.tst_create)}
            </Col>
          </Row>
        </>
      );
    },
  },
  {
    accessor: "vc_msisdn",
    Header: "Telefono",
  },
  {
    accessor: "vc_carrier",
    Header: "Carrier",
  },
  {
    accessor: "vc_shortcode",
    Header: "ShortCode",
  },
  {
    accessor: "vc_keywords",
    Header: "Keyword",
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <>
          <Row>
            <Col className="DateDetails">
              <b>{rowInfo.vc_keywords}</b>
            </Col>
          </Row>
        </>
      );
    },
  },
];
const AdminReportMoList = ({ listMessages, periodStr }) => {
  return (
    <>
      {listMessages && (
        <AdvanceTableWrapper
          columns={columns}
          data={listMessages}
          sortable
          pagination
          perPage={10}
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="DbDetails2">
                <Stack direction="horizontal" gap={2}>
                  <AiIcons.AiOutlineCalendar />
                  <div className="ms-auto">
                    <p>{periodStr}</p>
                  </div>
                </Stack>
                <div className="details">Rango de fechas</div>
              </div>
            </Col>
          </Row>
          <Stack direction="horizontal" className="mb-3" gap={2}>
            <div style={{ textAlign: "left" }}>
              <h4>Lista de Mensajes</h4>
            </div>
            <div className="ms-auto">
              <BtnExportToExcell
                dataReport={listMessages}
                fileName="SMS_Report"
              ></BtnExportToExcell>
            </div>
          </Stack>

          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: "sm",
              striped: true,
              className: "fs--1 mb-0 overflow-hidden",
            }}
          />
          <AdvanceTablePagination table />
          <AdvanceTableFooter
            rowCount={listMessages.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
            pageSize={10}
          />
        </AdvanceTableWrapper>
      )}
    </>
  );
};

export default AdminReportMoList;
