import axios from "axios";
import React, { useEffect, useState } from "react";
import { Badge, Button, Stack } from "react-bootstrap";
import * as AiIcons from "react-icons/ai";
import ModalEdit from "../../components/ModalEdit";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTableSearchBox from "../../components/AdvanceTableSearchBox";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import { FormatCurrency2Dec } from "../../utils/FormatData";
import EditSmsPackage from "./_EditSmsPackage";
import { useAuth } from "../../Context/AuthContext";
import { httpClient } from "../../utils/HttpClient";

const SmsPackages = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const URI = ApiEndpoint + "configuration/smsPackages/";
  const [packages, setPackages] = useState([]);
  const [show, setShow] = useState(false);
  const [idPackage, setIdPackage] = useState(0);
  const handleClose = () => {
    getSmsPackages();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const getSmsPackages = () => {
    httpClient("GET", URI, {}, userData.token).then((response) => {
      setPackages(response.data);
    });
  };

  useEffect(() => {
    getSmsPackages();
  }, []);

  const columns = [
    {
      accessor: "packagename",
      Header: "Nombre",
    },
    {
      accessor: "totalsms",
      Header: "Sms Totales",
    },
    {
      accessor: "unitprice",
      Header: "Costo por Sms",
      Cell: (rowData) => {
        let create = rowData.row.original.unitprice;
        return <>{FormatCurrency2Dec(create)}</>;
      },
    },
    {
      accessor: "isactive",
      Header: "Estatus",
      Cell: (rowData) => {
        let active = rowData.row.original.isactive;
        return (
          <>
            {active ? (
              <Badge bg="primary">Activo</Badge>
            ) : (
              <Badge bg="secondary">Inactivo</Badge>
            )}
          </>
        );
      },
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let uiddatabase = rowData.row.original.uidcampaigns;
        return <></>;
      },
    },
  ];

  return (
    <>
      <div>
        <Stack direction="horizontal" gap={2} className="AlignItemsTop">
          <div>
            <h2>Paquetes de Sms </h2>
            <h3>Administre la lista de paquetes de sms.</h3>
          </div>
          <div className="ms-auto">
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                setIdPackage(0);
                setShow(true);
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>{" "}
          </div>
        </Stack>
        {packages && (
          <AdvanceTableWrapper
            columns={columns}
            data={packages}
            sortable
            pagination
            perPage={10}
          >
            <div className="SearchBar">
              <Stack direction="horizontal" gap={3}>
                <AdvanceTableSearchBox table />
              </Stack>
            </div>
            <h4>Lista de Campañas</h4>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            <AdvanceTablePagination table />
            <AdvanceTableFooter
              rowCount={packages.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
              pageSize={10}
            />
          </AdvanceTableWrapper>
        )}
      </div>
      <ModalEdit
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        title={
          idPackage > 0 ? "Editar Paquete de SMS" : "Agregar Paquete de Sms"
        }
      >
        <EditSmsPackage
          idSmsPackage={idPackage}
          handleClose={handleClose}
        ></EditSmsPackage>
      </ModalEdit>
    </>
  );
};

export default SmsPackages;
