import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { ApiEndpoint, ShortUrlEndpoint } from "../../utils/ApiEndpoint";
import Notification from "../../components/notification";
import { httpClient } from "../../utils/HttpClient";
import { useAuth } from "../../Context/AuthContext";

const NewShortLinkAdmin = ({ handleShow, handleClose }) => {
  const Auth = useAuth();
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [token, setToken] = useState("");
  const [hostsList, setHostsList] = useState("");
  const [partnerList, setPartnerList] = useState(false);
  const [customersList, setCustomersList] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(false);
  const [selectedHost, setSelectedHost] = useState("odt.mx");
  const [notificationType, setNotificationType] = useState("");
  var userData = Auth.getSessionData();
  const [messageNotification, setMessageNotification] =
    useState("Url Corta Creada");
  const [values, setValues] = useState({
    displayname: "",
    longurl: "",
    uid_partner: "",
  });

  function GetToken() {
    httpClient(
      "GET",
      ApiEndpoint + "partnersettings/00000000-0000-0000-0000-000000000000",
      {},
      userData.token
    ).then(function (response) {
      setToken(response.data.tokenbitly);
    });
  }

  const getPartnersList = (idCustomer) => {
    const URIPartners = ApiEndpoint + "partners/";
    httpClient("GET", URIPartners + idCustomer, {}, userData.token).then(
      (response) => {
        setPartnerList(response.data);
      }
    );
  };

  const getCustomersList = () => {
    const URICustomers = ApiEndpoint + "customers";
    httpClient("GET", URICustomers, {}, userData.token).then((response) => {
      setCustomersList(response.data);
    });
  };

  const getShortLinksHosts = () => {
    const URIHosts = ApiEndpoint + "administration/hosts";
    httpClient("GET", URIHosts, {}, userData.token).then((response) => {
      setHostsList(response.data);
    });
  };

  useEffect(() => {
    if (selectedCustomer > 0) {
      getPartnersList(selectedCustomer);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    GetToken();
    getCustomersList();
    getShortLinksHosts();
  }, []);

  function GenerateLink() {
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        "https://api-ssl.bitly.com/v4/shorten",
        {
          long_url: values.longurl,
          // group_guid: userData.uid_partner,
        },
        config
      )
      .then(function (response) {
        var responseLink = response.data;
        console.log(responseLink);
        let URIShort = ApiEndpoint + "campaigns/createShortLink";

        axios
          .post(URIShort, {
            idpartner: `${values.uid_partner}`,
            displayname: values.displayname,
            shortlinks: responseLink.link,
            originallink: values.longurl,
            bitlink: responseLink.id,
            responsedetail: JSON.stringify(responseLink),
          })
          .then(function (response) {
            if (response.data.error) {
              setMessageNotification("Ha ocurrido un error");
              setNotificationType("warning");
              setShow(true);
            } else {
              setMessageNotification("Campaña creada");
              setNotificationType("");
              setShow(true);
              handleClose();
            }
            return;
          })
          .catch(function (error) {
            setMessageNotification("Ha ocurrido un error");
            setNotificationType("warning");
            setShow(true);
            console.log(error);
          });
      })
      .catch(function (error) {
        setMessageNotification("Ha ocurrido un error");
        setNotificationType("warning");
        setShow(true);
        console.log(error);
      });
  }

  function GenerateLinkTtr() {
    axios
      .post(
        ShortUrlEndpoint + "config/createurl",
        {
          endpoint: values.longurl,
          domain: selectedHost, //"odt.mx",
          // group_guid: userData.uid_partner,
        }
        // config
      )
      .then(function (response) {
        var responseLink = response.data;
        console.log(responseLink);
        let URIShort = ApiEndpoint + "campaigns/createShortLink";

        httpClient(
          "POST",
          URIShort,
          {
            idpartner: `${values.uid_partner}`,
            displayname: values.displayname,
            shortlinks: responseLink.message,
            originallink: values.longurl,
            bitlink: responseLink.code,
            responsedetail: JSON.stringify(responseLink),
          },
          userData.token
        )
          .then(function (response) {
            if (response.data.error) {
              setMessageNotification("Ha ocurrido un error");
              setNotificationType("warning");
              setShow(true);
            } else {
              setMessageNotification("Campaña creada");
              setNotificationType("");
              setShow(true);
              handleClose();
            }
            return;
          })
          .catch(function (error) {
            setMessageNotification("Ha ocurrido un error");
            setNotificationType("warning");
            setShow(true);
            console.log(error);
          });
      })
      .catch(function (error) {
        setMessageNotification("Ha ocurrido un error");
        setNotificationType("warning");
        setShow(true);
        console.log(error);
      });
  }

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);

    GenerateLinkTtr();
  };
  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <div className="NewCampaignUrl">
        <Form noValidate validated={validated} onSubmit={handleSubmit1}>
          <FloatingLabel label="Cliente *" className="mb-3">
            <Form.Select
              id="idcliente"
              name="idcliente"
              onChange={(selectedOption) => {
                setSelectedCustomer(`${selectedOption.target.value}`);
              }}
              value={selectedCustomer}
              required
              aria-label="select_customer"
            >
              <option value="">Seleccione una opción de la lista</option>
              {!!customersList?.length &&
                customersList.map((customer) => (
                  <option value={customer.idcustomer}>
                    {customer.comercialname}
                  </option>
                ))}
            </Form.Select>
          </FloatingLabel>

          <FloatingLabel label="Partner *" className="mb-3">
            <Form.Select
              id="uid_partner"
              name="uid_partner"
              onChange={(selectedOption) => {
                onChange(selectedOption);
              }}
              value={values.uid_partner}
              required
              aria-label="select_carrier"
            >
              <option value="">Seleccione una opción de la lista</option>
              {!!partnerList?.length &&
                partnerList.map((partner) => (
                  <option value={partner.uidpartner}>{partner.name}</option>
                ))}
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel label="Host *" className="mb-3">
            <Form.Select
              id="idHost"
              name="idHost"
              onChange={(selectedOption) => {
                setSelectedHost(`${selectedOption.target.value}`);
              }}
              value={selectedHost}
              required
              aria-label="select_host"
            >
              {!!hostsList?.length &&
                hostsList.map((host) => (
                  <option value={host.host}>{host.host}</option>
                ))}
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel label="Alias *" className="mb-3">
            <Form.Control
              name="displayname"
              id="displayname"
              placeholder="Escribe un nombre"
              required
              onChange={(event) => {
                onChange(event);
              }}
            />
          </FloatingLabel>
          <h5>Agrega un nombre para poder identificar esta url corta</h5>

          <FloatingLabel label="Url *" className="mb-3">
            <Form.Control
              name="longurl"
              id="longurl"
              placeholder="Escribe la Url *"
              required
              onChange={(event) => {
                onChange(event);
              }}
            />
          </FloatingLabel>
          <h5>Agrega la url que deseas acortar</h5>

          <div className="text-right">
            <Button
              variant="primary"
              type="submit"
              // onClick={() => {
              //   GenerateLink();
              // }}
              className="align-self-end"
            >
              Enviar
            </Button>
          </div>
        </Form>
      </div>

      <Notification
        alertType={notificationType}
        show={show}
        setShow={setShow}
        message={messageNotification}
      />
    </>
  );
};

export default NewShortLinkAdmin;
