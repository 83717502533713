import React, { Children } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import SideBar from "./sideBar";
import TopBar from "./topBar";
import { Outlet } from "react-router-dom";

//Components
import DropdownProfileMenu from "./ProfileOptionsDropdown";
import Navbar from "./Navbar";
import { currentVersion } from "../utils/AuxFunctions";

const MainLayout = ({ children }) => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="g-0 d-lg-none" xs={12} sm={12}>
            <TopBar />
          </Col>
          <Col className="d-none d-lg-block" md={3} lg={3} xl={2}>
            <SideBar />
          </Col>
          <Col xs={12} sm={12} md={12} lg={9} xl={10}>
            <div className="bodyPage">
              <div className="NavbarTop">
                <Navbar />
              </div>
              {/*<div className="d-flex justify-content-end">
                <DropdownProfileMenu />
              </div>*/}
              {/* <Stack direction="horizontal" gap={2}> */}
              <Outlet />
              {/* </Stack> */}
            </div>
            <footer>
              ODT 2023 - Todos los derechos reservados - V-{currentVersion}
            </footer>
          </Col>
        </Row>
        <Row>
          <Col className="g-0" xs={12} sm={12}></Col>
        </Row>
      </Container>
    </>
  );
};

export default MainLayout;
