import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

const MsisdnTextBox = ({
  name,
  label = "Número de Telefono *",
  defaultValue,
  changeHandler,
  required = true,
}) => {
  return (
    <>
      <FloatingLabel label={label} className="mb-3">
        <Form.Control
          type="text"
          name={name}
          id={name}
          maxLength="10"
          onkeypress="return event.charCode>=48 && event.charCode<=57"
          value={defaultValue}
          required
          pattern="^\d{10}$"
          onChange={(event) => {
            event.target.value = event.target.value.replace(/[^\d]+/g, "");
            changeHandler(event);
          }}
          placeholder="Ingrese el Telefono"
        />
      </FloatingLabel>
    </>
  );
};

export default MsisdnTextBox;
