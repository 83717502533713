import React, { useEffect, useState } from "react";

//Bootstrap
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//Components
import DropdownProfileMenu from "./ProfileOptionsDropdown";
import { useAuth } from "../Context/AuthContext.js";

function NavbarTop() {
  const Auth = useAuth();
  const [userData, setUserData] = useState(false);
  
  const getUserData = () => {
    var userDataFromSess = Auth.getSessionData();
    setUserData(userDataFromSess);
  };

  useEffect(() => {
    getUserData();
  }, []);
  
  return (
    <Navbar className="bg-body-tertiary justify-content-between">
      <Col>
        <p style={{fontWeight: "500"}}>
            {userData.vc_partner === "null" ? ("") : (userData.vc_partner + " | ")}
            
            {userData.vc_usertype}
        </p>
      </Col>
      <Col xs="auto">
        <p style={{fontWeight: "500"}}>{userData.vc_displayname}</p>
      </Col>
      <Col xs="auto">
         <DropdownProfileMenu />
      </Col>
    </Navbar>
  );
}

export default NavbarTop;