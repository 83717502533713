import React, { useEffect, useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import { ApiEndpoint, ShortUrlEndpoint } from "../../utils/ApiEndpoint";

import { format } from "date-fns";
import { es } from "date-fns/locale";

import { Stack } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import * as TbIcons from "react-icons/tb";
import * as FiIcons from "react-icons/fi";
import * as HiIcons from "react-icons/hi2";
import * as AiIcons from "react-icons/ai";

import ChartBitly from "../../components/widgets/widgetChartBitly";

import BtnExportToExcell from "../../utils/BtnExportToExcell";
import { httpClient } from "../../utils/HttpClient";

const ShortLinkDetail = ({
  ShortLink,
  ShortLinkName,
  OriginalLink,
  handleShow,
  handleClose,
  ShortLinkFull,
}) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [show, setShow] = useState(false);
  const [token, setToken] = useState(false);

  const [cliksInfo, setCliksInfo] = useState(null);
  const [uniqueCliksInfo, setUniqueCliksInfo] = useState(null);
  const [totalClicks, setTotalClicks] = useState(null);
  const [bestDay, setBestDay] = useState(null);

  const [notificationType, setNotificationType] = useState("");
  const [messageNotification, setMessageNotification] = useState(
    "Configuracion Actualizada"
  );
  const [values, setValues] = useState({
    token: "",
  });

  function GetToken() {
    httpClient(
      "GET",
      ApiEndpoint + "partnersettings/" + userData.uid_partner,
      {},
      userData.token
    ).then(function (response) {
      console.log(response.data);
      setToken(response.data.tokenbitly);
      GetInfoUrl(response.data.tokenbitly);
    });
  }

  function GetInfoUrlBitly(state) {
    const config = {
      headers: {
        Authorization: "Bearer " + state,
        "Content-Type": "application/json",
      },
    };
    axios
      .get(
        "https://api-ssl.bitly.com/v4/bitlinks/" +
          ShortLink +
          "/clicks?unit=day&units=-1&size=10",
        config
      )
      .then(function (response) {
        var LinkData = response.data.link_clicks;
        setCliksInfo(LinkData);

        var BestDate = LinkData.sort((a, b) => b.clicks - a.clicks)[0].date;
        setBestDay(BestDate.substring(0, 10));
      })
      .catch(function (error) {
        setMessageNotification("Ha ocurrido un error");
        setNotificationType("warning");
        setShow(true);
        console.log(error);
      });

    axios
      .get(
        "https://api-ssl.bitly.com/v4/bitlinks/" +
          ShortLink +
          "/clicks/summary?unit=day&units=-1&size=10",
        config
      )
      .then(function (response) {
        var LinkDataTotal = response.data.total_clicks;
        setTotalClicks(LinkDataTotal);
      })
      .catch(function (error) {
        setMessageNotification("Ha ocurrido un error");
        setNotificationType("warning");
        setShow(true);
        console.log(error);
      });
  }

  function GetInfoUrl() {
    httpClient(
      "GET",
      ShortUrlEndpoint + "reports/bydate/" + ShortLink,
      {},
      userData.token
    )
      .then((response) => {
        var LinkData = response.data;
        setCliksInfo(LinkData);
        var totalCls = LinkData.data.reduce(
          (partialSum, item) => partialSum + Number(item.clicks),
          0
        );
        var totalUniCls = LinkData.data.reduce(
          (partialSum, item) => partialSum + Number(item.unique),
          0
        );
        setTotalClicks(totalCls);
        setUniqueCliksInfo(totalUniCls);
        var BestDate = LinkData.data.sort((a, b) => b.clicks - a.clicks)[0]
          .date;
        console.log(BestDate);
        var NewDate = new Date(BestDate.replace(/-/g, "/"));
        setBestDay(format(new Date(NewDate), "d MMMM"));
        //setBestDay(format(new Date(BestDate), "d MMMM"));
        //format(new Date(bestDay), "d MMMM");
      })
      .catch(function (error) {
        setMessageNotification("Ha ocurrido un error");
        setNotificationType("warning");
        setShow(true);
        console.log(error);
      });
  }
  useEffect(() => {
    GetToken();
  }, []);

  return (
    <>
      <Stack direction="horizontal" className="titleShortUrl" gap={2}>
        <b> {ShortLinkName} </b>
        <div className="ms-auto">
          <FiIcons.FiRefreshCw onClick={() => GetInfoUrl()} />
        </div>
      </Stack>

      <div className="borderData">
        <p>
          <b>Url corta:</b> {ShortLinkFull}{" "}
        </p>
        <p>
          <b>Url original:</b> {OriginalLink.substring(0, 22)}...{" "}
        </p>
      </div>
      <Row>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="DbDetails4">
            <Stack direction="horizontal" gap={2}>
              <HiIcons.HiOutlineCursorArrowRipple />
              <div className="ms-auto">
                <p>{totalClicks}</p>
              </div>
            </Stack>
            <div className="details">Total de clicks</div>
          </div>
        </Col>{" "}
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="DbDetails5">
            <Stack direction="horizontal" gap={2}>
              <HiIcons.HiOutlineCursorArrowRays />
              <div className="ms-auto">
                <p> {uniqueCliksInfo}</p>
              </div>
            </Stack>
            <div className="details">Clicks Únicos</div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="DbDetails5 DbDetailDark">
            <Stack direction="horizontal" gap={2}>
              <AiIcons.AiOutlineLineChart />
              <div className="ms-auto">
                <p>{bestDay}</p>
              </div>
            </Stack>
            <div className="details">Día con más clicks</div>
          </div>
        </Col>
      </Row>

      <div className="Status">
        <Stack direction="horizontal" gap={2}>
          <h6>Clicks por día</h6>
        </Stack>
        {cliksInfo && <ChartBitly LinkData={cliksInfo.data}></ChartBitly>}
      </div>
      {cliksInfo && (
        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <BtnExportToExcell
            dataReport={cliksInfo.data}
            fileName="Url_Report"
          ></BtnExportToExcell>
        </div>
      )}
    </>
  );
};

export default ShortLinkDetail;
