import React, { useEffect, useState } from "react";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { httpClient } from "../../utils/HttpClient";
import { useAuth } from "../../Context/AuthContext";
import DateFilters from "../../components/generic/_DateFilters";
import ButtonRefresh from "../../components/generic/_ButtonRefresh";

const GenericAdminFilters = ({
  onRefresh,
  setPeriodStr,
  withStatusFilter = true,
  withPartnerFilter = true,
  withCarrierFilter = true,
  withShortCodeFilter = true,
  loading = false,
}) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();

  const [carrierList, setCarrierList] = useState(false);
  const [customersList, setCustomersList] = useState(false);
  const [partnerList, setPartnerList] = useState(false);
  const [statusList, setStatusList] = useState(false);
  const [filterFrom, setFilterFrom] = useState(false);
  const [filterTo, setFilterTo] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(false);
  const [shortCodesList, setShortCodesList] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(0);
  const [values, setValues] = useState({
    idcarrier: "0",
    idshortcode: "0",
    idstatus: "0",
    dateFrom: "",
    dateTo: "",
    idpartner: "0",
  });

  const getFilters = () => {
    onRefresh({
      dateFrom: filterFrom,
      dateTo: filterTo,
      idcarrier: values.idcarrier,
      idshortcode: values.idshortcode,
      idcustomer: selectedCustomer,
      idpartner: values.idpartner,
    });
  };

  const getCarriersList = () => {
    const URICarriers = ApiEndpoint + "carriers";
    httpClient("GET", URICarriers, {}, userData.token).then((response) => {
      setCarrierList(response.data);
    });
  };

  const getStatuses = () => {
    const URICarriers = ApiEndpoint + "configuration/smsStatus";
    httpClient("GET", URICarriers, {}, userData.token).then((response) => {
      setStatusList(response.data.filter((st) => st.idStatus != 4));
    });
  };

  const getShortCodes = (idCarrier) => {
    const URIShortCodes = ApiEndpoint + "shortcodesbycarrier/";

    httpClient("GET", URIShortCodes + idCarrier, {}, userData.token).then(
      (response) => {
        setShortCodesList(response.data);
      }
    );
  };

  const getPartnersList = (idCustomer) => {
    const URIPartners = ApiEndpoint + "partners/";
    httpClient("GET", URIPartners, {}, userData.token).then((response) => {
      setPartnerList(response.data);
    });
  };

  const getCustomersList = () => {
    const URICustomers = ApiEndpoint + "customers";
    httpClient("GET", URICustomers, {}, userData.token).then((response) => {
      setCustomersList(response.data);
    });
  };

  useEffect(() => {
    if (selectedCustomer) getPartnersList(selectedCustomer);
  }, [selectedCustomer]);

  useEffect(() => {
    if (!selectedCarrier) return;
    getShortCodes(selectedCarrier);
  }, [selectedCarrier]);

  useEffect(() => {
    withCarrierFilter && getCarriersList();
    getStatuses();
    getCustomersList();
  }, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <FloatingLabel label="Cliente:" className="mb-1">
              <Form.Select
                id="idCustomer"
                name="idCustomer"
                onChange={(selectedOption) => {
                  onChange(selectedOption);
                  setSelectedCustomer(`${selectedOption.target.value}`);
                }}
                value={values.idcustomer}
                required
                aria-label="select_client"
              >
                <option value="">Todos los clientes</option>
                {!!customersList?.length &&
                  customersList.map((customer) => (
                    <option value={customer.idcustomer}>
                      {customer.comercialname}
                    </option>
                  ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          {withPartnerFilter && (
            <Form.Group as={Col}>
              <FloatingLabel label="Partner:" className="mb-1">
                <Form.Select
                  id="idPartner"
                  name="idPartner"
                  onChange={(selectedOption) => {
                    onChange(selectedOption);
                  }}
                  value={values.idPartner}
                  required
                  aria-label="select_partner"
                >
                  <option selected disabled hidden>
                    Partner
                  </option>
                  <option value="0">Todos los partners</option>
                  {!!partnerList?.length &&
                    partnerList.map((partner) => (
                      <option value={partner.idpartner}>{partner.name}</option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          )}
          {withCarrierFilter && (
            <Form.Group as={Col}>
              <FloatingLabel label="Carrier:" className="mb-1">
                <Form.Select
                  id="idcarrier"
                  name="idcarrier"
                  onChange={(selectedOption) => {
                    onChange(selectedOption);
                    setSelectedCarrier(`${selectedOption.target.value}`);
                  }}
                  value={values.idcarrier}
                  required
                  aria-label="select_carrier"
                >
                  <option value="">Todos los Carriers</option>
                  {!!carrierList?.length &&
                    carrierList.map((carrier) => (
                      <option value={carrier.idcarrier}>
                        {carrier.carrier}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          )}

          {withShortCodeFilter && (
            <Form.Group as={Col}>
              <FloatingLabel label="Número corto:" className="mb-1">
                <Form.Select
                  id="idshortcode"
                  name="idshortcode"
                  onChange={(selectedOption) => {
                    onChange(selectedOption);
                  }}
                  value={values.idshortcode}
                  required
                  aria-label="select_shortCode"
                >
                  <option selected disabled hidden>
                    Número corto
                  </option>
                  <option value="">Todas</option>
                  {!!shortCodesList?.length &&
                    shortCodesList.map((shortcode) => (
                      <option value={shortcode.idshortcode}>
                        {shortcode.shortcode}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          )}
        </Row>
        <Row className="mb-3">
          {withStatusFilter && (
            <>
              <Form.Group as={Col}>
                <FloatingLabel label="Estatus:" className="mb-1">
                  <Form.Select
                    id="idstatus"
                    name="idstatus"
                    onChange={(selectedOption) => {
                      onChange(selectedOption);
                    }}
                    value={values.idstatus}
                    required
                    aria-label="select_status"
                  >
                    <option selected disabled hidden>
                      Estatus:
                    </option>
                    <option value="">Todos</option>
                    {!!statusList?.length &&
                      statusList.map((status) => (
                        <option value={status.idStatus}>{status.status}</option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
            </>
          )}

          <DateFilters
            setPeriodStr={setPeriodStr}
            setFilterFrom={setFilterFrom}
            setFilterTo={setFilterTo}
          ></DateFilters>
        </Row>

        <div className="text-right">
          <ButtonRefresh
            refreshFunction={getFilters}
            loading={loading}
          ></ButtonRefresh>
        </div>
      </Form>
    </>
  );
};

export default GenericAdminFilters;
