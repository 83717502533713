import axios from "axios";
import React, { useEffect, useState } from "react";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { v4 as uuidv4 } from "uuid";

// Bootstrap
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { useAuth } from "../../Context/AuthContext";
import { httpClient } from "../../utils/HttpClient";

const EditUserData = ({
  setShowNotification,
  setMessageNotification,
  setNotificationType,
  handleClose,
  handleShow,
}) => {
  const Auth = useAuth();
  const [validated, setValidated] = useState(false);
  const [userInfo, setUserInfo] = useState(false);
  var userData = Auth.getSessionData();
  const [values, setValues] = useState({
    name: "",
    display: "",
    firstName: "",
    lastName: "",
    email: "",
    Phone: "",
  });

  const getUserData = () => {
    var userDataFromSess = Auth.getSessionData();
    const URIData = ApiEndpoint + "users/userData/" + userDataFromSess.uid_user;

    httpClient("GET", URIData, {}, userData.token).then((response) => {
      console.log(response.data);
      setValues(response.data);
      setUserInfo(response.data);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    var userDataFromSess = Auth.getSessionData();
    setValidated(true);
    const URIUpdate = ApiEndpoint + "users/updateUser";

    httpClient(
      "PUT",
      URIUpdate,
      {
        display: values.display,
        name: values.name,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.Phone,
        uidUser: userDataFromSess.uid_user,
      },
      userData.token
    )
      .then(function (response) {
        if (!response.error) {
          setMessageNotification(
            "Datos Actualizados los cambios se reflejaran en su proximo inicio de sesion"
          );
          setNotificationType("");
          setShowNotification(true);
          handleClose();
        } else {
          setMessageNotification("Ha ocurrido un error");
          setNotificationType("warning");
          setShowNotification(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  return (
    userInfo && (
      <>
        <Form noValidate validated={validated} onSubmit={handleSubmit1}>
          <FloatingLabel label="Nombre:" className="mb-3">
            <Form.Control
              type="text"
              name="name"
              id="name"
              value={values.name}
              onChange={(event) => {
                onChange(event);
              }}
              required
              placeholder="Ingrese su nombre"
            />
          </FloatingLabel>

          <FloatingLabel label="Apellido Paterno:" className="mb-3">
            <Form.Control
              type="text"
              name="firstName"
              id="firstName"
              required
              value={values.firstName}
              onChange={(event) => onChange(event)}
              placeholder="Ingrese su apellido"
            />
          </FloatingLabel>
          <FloatingLabel label="Apellido Materno:" className="mb-3">
            <Form.Control
              type="text"
              name="lastName"
              id="lastName"
              required
              value={values.lastName}
              onChange={(event) => onChange(event)}
              placeholder="Ingrese su apellido"
            />
          </FloatingLabel>
          <FloatingLabel label="Email:" className="mb-3">
            <Form.Control
              type="text"
              name="email"
              id="email"
              value={values.email}
              onChange={(event) => onChange(event)}
              required
              placeholder="Ingrese su email"
            />
          </FloatingLabel>
          <FloatingLabel label="Teléfono:" className="mb-3">
            <Form.Control
              type="text"
              name="phone"
              id="phone"
              value={values.Phone}
              onChange={(event) => onChange(event)}
              required
              placeholder="Teléfono:"
            />
          </FloatingLabel>

          <div className="text-right">
            <Button variant="primary" type="submit" className="align-self-end">
              Actualizar
            </Button>
          </div>
        </Form>
      </>
    )
  );
};

export default EditUserData;
