import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { v4 as uuidv4 } from "uuid";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { useAuth } from "../../Context/AuthContext";
import { httpClient } from "../../utils/HttpClient";

const CreateUserPartner = ({ handleClose }) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [validated, setValidated] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [inputValueConfirm, setinputValueConfirm] = useState("");

  const [passValidate, setPassValidate] = useState(false);
  const [passValidateB, setPassValidateB] = useState(false);

  const [values, setValues] = useState({
    username: "",
    password: "",
    confirmpassword: "",
    displayname: "",
    name: "",
    firstname: "",
    lastname: "",
    email: "",
    usertype: "",
    phone: "",
  });

  const validatePass = (event) => {
    setPassValidate(inputValue !== event.target.value);
  };

  const validatePassB = (event) => {
    setPassValidateB(inputValueConfirm !== event.target.value);
  };

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    const URICreateUser = ApiEndpoint + "partner/createuser";

    var userData = Auth.getSessionData();

    httpClient(
      "POST",
      URICreateUser,
      {
        idpartner: userData.uid_partner,
        username: values.username,
        password: values.password,
        displayname: values.username,
        name: values.name,
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        usertype: 3, //AGENTE
        phone: values.phone,
      },
      userData.token
    )
      .then(function (response) {
        console.log(response);
        handleClose();
        return;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit1}>
        <FloatingLabel label="Nombre" className="mb-3">
          <Form.Control
            type="text"
            name="name"
            id="name"
            value={values.name}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el nombre del nuevo usuario"
          />
        </FloatingLabel>
        <FloatingLabel label="Apellido Paterno" className="mb-3">
          <Form.Control
            type="text"
            name="firstname"
            id="firstname"
            value={values.firstname}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el Apellido Paterno"
          />
        </FloatingLabel>
        <FloatingLabel label="Apellido Materno" className="mb-3">
          <Form.Control
            type="text"
            name="lastname"
            id="lastname"
            value={values.lastname}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el Apellido Materno"
          />
        </FloatingLabel>
        <FloatingLabel label="Email" className="mb-3">
          <Form.Control
            type="email"
            name="email"
            id="email"
            value={values.email}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese un Email Valido"
          />
        </FloatingLabel>
        <FloatingLabel label="Teléfono" className="mb-3">
          <Form.Control
            type="text"
            name="phone"
            id="phone"
            maxLength="10"
            onkeypress="return event.charCode>=48 && event.charCode<=57"
            value={values.phone}
            required
            pattern="^\d{10}$"
            onChange={(event) => {
              event.target.value = event.target.value.replace(/[^\d]+/g, "");
              onChange(event);
            }}
            placeholder="Ingrese un Telefono Valido"
          />
        </FloatingLabel>
        <FloatingLabel label="Usuario" className="mb-3">
          <Form.Control
            type="text"
            name="username"
            id="username"
            value={values.username}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese un Usuario"
          />
        </FloatingLabel>
        <FloatingLabel label="Contraseña" className="mb-3">
          <Form.Control
            type="password"
            name="password"
            id="password"
            value={values.password}
            required
            onChange={(event) => {
              onChange(event);
              validatePassB(event);
              setInputValue(event.target.value);
            }}
            placeholder="Ingrese una Contraseña"
          />
        </FloatingLabel>
        <FloatingLabel label="Confirmar contraseña" className="mb-3">
          <Form.Control
            type="password"
            name="confirmpassword"
            id="confirmpassword"
            value={values.confirmpassword}
            required
            onChange={(event) => {
              onChange(event);
              validatePass(event);
              setinputValueConfirm(event.target.value);
            }}
            placeholder="Ingrese nuevamente la nueva Contraseña"
          />
        </FloatingLabel>
        {passValidate && passValidateB && (
          <Alert
            key="danger"
            variant="danger"
            size="sm"
            style={{ fontSize: "13px", padding: "5px 10px" }}
          >
            Las contraseñas no coinciden
          </Alert>
        )}
        <div className="text-right">
          <Button variant="primary" type="submit" className="align-self-end">
            Guardar
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CreateUserPartner;
