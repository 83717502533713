import React, { useEffect, useState } from "react";
import { Badge, Button, Stack } from "react-bootstrap";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { stringGen } from "../../utils/Encrypt";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import AdvanceTableSearchBox from "../../components/AdvanceTableSearchBox";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import Confirm from "../../components/Confirm";
import * as AiIcons from "react-icons/ai";
import EditOperativeUser from "./_EditOperativeUser";
import ModalEdit from "../../components/ModalEdit";
import Notification from "../../components/notification";
import { useAuth } from "../../Context/AuthContext";
import { httpClient } from "../../utils/HttpClient";

const OperativesUsers = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState(null);
  const handleShow = () => setShow(true);
  const [modalMessage, setModalMessage] = useState(
    "Desea Cambiar el status del registro?"
  );
  const [messageConfirm, setMessageConfirm] = useState(false);
  const ModalHandleClose = () => setModalShowConfirm(false);
  const ModalHandleClosePassword = () => setModalShowConfirmPass(false);
  const ModalHandleCloseNewPassword = () => setModalShowConfirmNewPass(false);
  const [idRecordToInactivate, setIdRecordToInactivate] = useState(false);
  const [idRecordToChangePass, setIdRecordToChangePass] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  const [modalShowConfirmPass, setModalShowConfirmPass] = useState(false);
  const [modalShowConfirmNewPass, setModalShowConfirmNewPass] = useState(false);
  const handleConfirmNewPass = () => {
    setModalShowConfirmNewPass(false);
  };
  const handleConfirm = () => {
    changeStatusUser();
    setModalShowConfirm(false);
  };
  const handleConfirmPass = () => {
    createNewUserPasword();
    setModalShowConfirmPass(false);
  };
  const handleClose = () => {
    setShow(false);
    getStaff();
  };
  const columns = [
    {
      accessor: "name",
      Header: "Nombre",
    },
    {
      accessor: "username",
      Header: "Usuario",
    },
    {
      accessor: "typeuser",
      Header: "Tipo",
    },
    {
      accessor: "email",
      Header: "Email",
    },
    {
      accessor: "phone",
      Header: "Telefono",
    },
    {
      accessor: "active",
      Header: "Estatus",
      Cell: (rowData) => {
        let active = rowData.row.original.active;
        return (
          <>
            {active ? (
              <Badge bg="primary">Activo</Badge>
            ) : (
              <Badge bg="secondary">Inactivo</Badge>
            )}
          </>
        );
      },
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let userid = rowData.row.original.uiduser;
        return (
          <>
            <AiIcons.AiFillEyeInvisible
              className="IconTable"
              title="Desactivar"
              onClick={() => {
                setIdRecordToInactivate(userid);
                setModalMessage("Desea Cambiar el status del usuario");
                setModalShowConfirm(true);
              }}
            />
            <AiIcons.AiFillLock
              className="IconTable"
              title="Generar Contrasea"
              onClick={() => {
                setIdRecordToChangePass(userid);
                setModalMessage(
                  "Desea generar una contraseña temporal a este usuario?"
                );
                setModalShowConfirmPass(true);
              }}
            />
          </>
        );
      },
    },
  ];

  const changeStatusUser = () => {
    const URI =
      ApiEndpoint + "configuration/deactivatestaff/" + idRecordToInactivate;
    httpClient("PUT", URI, {}, userData.token).then((response) => {
      getStaff();
    });
  };

  const createNewUserPasword = () => {
    const URI = ApiEndpoint + "partner/changepasswordfromadm";
    const newPass = stringGen();

    httpClient(
      "POST",
      URI,
      {
        password: newPass,
        uiduser: idRecordToChangePass,
      },
      userData.token
    ).then((response) => {
      setMessageConfirm("La contraseña nueva es: " + newPass);
      setModalShowConfirmNewPass(true);
      //getPartnerUsers();
      //TODO: mostrar resultado
    });
  };

  const getStaff = () => {
    const URI = ApiEndpoint + "configuration/operativeusers";

    httpClient("GET", URI, {}, userData.token).then((response) => {
      setUsers(response.data);
    });
  };

  useEffect(() => {
    getStaff();
  }, []);

  return (
    <>
      <div className="">
        <Stack direction="horizontal" gap={2}>
          <div>
            <h2>Usuarios Operativos</h2>
          </div>
          <div className="ms-auto">
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                //setIdCustomer(0);
                setShow(true);
              }}
            >
              {" "}
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>
          </div>
        </Stack>

        {users && (
          <AdvanceTableWrapper
            columns={columns}
            data={users}
            sortable
            pagination
            perPage={10}
          >
            <div className="SearchBar">
              <Stack direction="horizontal" gap={3}>
                <AdvanceTableSearchBox table />
              </Stack>
            </div>
            <h4>Lista de Usuarios</h4>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            <AdvanceTablePagination table />
            <AdvanceTableFooter
              rowCount={users.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
              pageSize={10}
            />
          </AdvanceTableWrapper>
        )}
      </div>
      <ModalEdit
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        title="Agregar Usuario"
      >
        <EditOperativeUser handleClose={handleClose}></EditOperativeUser>
      </ModalEdit>
      <Confirm
        show={modalShowConfirm}
        handleClose={ModalHandleClose}
        handleConfirm={handleConfirm}
        message={"Desea Cambiar el status del usuario?"}
      />
      <Confirm
        show={modalShowConfirmPass}
        handleClose={ModalHandleClosePassword}
        handleConfirm={handleConfirmPass}
        message={modalMessage}
      />
      <Confirm
        show={modalShowConfirmNewPass}
        handleClose={ModalHandleCloseNewPassword}
        handleConfirm={ModalHandleCloseNewPassword}
        message={messageConfirm}
      />
    </>
  );
};

export default OperativesUsers;
