import React, { useEffect, useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { useAuth } from "../../Context/AuthContext";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { httpClient } from "../../utils/HttpClient";

const CreateEndPoint = ({ idpartnerendpoint, isMo, handleClose }) => {
  const Auth = useAuth();
  const [validated, setValidated] = useState(false);

  const [values, setValues] = useState({
    endpoint: "",
    data: "",
  });

  const getPartnerEndpoint = () => {
    var userData = Auth.getSessionData();
    const URI =
      ApiEndpoint +
      "partner/partnerendpoints/" +
      userData.uid_partner +
      "/" +
      idpartnerendpoint;
    httpClient("GET", URI, {}, userData.token).then((response) => {
      setValues({ endpoint: response.data.endpoint, data: response.data.data });
      // setPartsnerEndpoints(response.data);
    });
  };
  const handleSubmit1 = (event) => {
    event.preventDefault();

    var userData = Auth.getSessionData();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);

    var userData = Auth.getSessionData();
    if (idpartnerendpoint > 0) {
      httpClient(
        "POST",
        ApiEndpoint + "partner/updateendpoint",
        {
          idpartnerendpoint: idpartnerendpoint,
          endpoint: values.endpoint,
          method: "POST",
          data: values.data,
          headers: "",
          isresult: !isMo,
          ismo: isMo,
        },
        userData.token
      )
        .then(function (response) {
          console.log(response);
          handleClose();
          return;
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      httpClient(
        "POST",
        ApiEndpoint + "partner/createendpoint/" + userData.uid_partner,
        {
          idpartner: userData.uid_partner,
          endpoint: values.endpoint,
          method: "POST",
          data: values.data,
          headers: "",
          isresult: !isMo,
          ismo: isMo,
        },
        userData.token
      )
        .then(function (response) {
          console.log(response);
          handleClose();
          return;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    getPartnerEndpoint();
  }, []);
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit1}>
        <h4>{isMo ? "Recepcion de MO" : "Respuestas de SMS"}</h4>

        <hr />

        <FloatingLabel label="URL" className="mb-3">
          <Form.Control
            type="text"
            name="endpoint"
            id="endpoint"
            value={values.endpoint}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese la URL"
          />
        </FloatingLabel>
        <FloatingLabel label="Body" className="mb-3">
          <Form.Control
            as="textarea"
            name="data"
            style={{ height: "400px" }}
            id="data"
            value={values.data}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el cuerpo a enviar en la notificacion"
          />
        </FloatingLabel>
        <hr />

        <div className="text-right">
          <Button variant="primary" type="submit" className="align-self-end">
            Guardar
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CreateEndPoint;
