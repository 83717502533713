import React, { useEffect, useState } from "react";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { Alert, Button, FloatingLabel, Form } from "react-bootstrap";
import { httpClient } from "../../utils/HttpClient";
import { useAuth } from "../../Context/AuthContext";

const EditCustomerUser = ({ idCustomer, handleClose }) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [validated, setValidated] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [inputValueConfirm, setinputValueConfirm] = useState("");

  const [partnerUid, setPartnerUid] = useState("");
  const [partnerList, setPartnerList] = useState(false);
  const [passValidate, setPassValidate] = useState(false);
  const [passValidateB, setPassValidateB] = useState(false);
  const [passValidateFormat, setPassValidateFormat] = useState(false);
  const [usersTypes, setUsersTypes] = useState(false);

  const [values, setValues] = useState({
    username: "",
    password: "",
    confirmpassword: "",
    displayname: "",
    name: "",
    firstname: "",
    lastname: "",
    email: "",
    usertype: "",
    phone: "",
    idtypeuser: 3,
    limitsms: 0,
  });

  const validatePass = (event) => {
    setPassValidate(inputValue !== event.target.value);
  };

  const validatePassB = (event) => {
    setPassValidateB(inputValueConfirm !== event.target.value);
  };

  const getPartnersList = () => {
    const URIPartners = ApiEndpoint + "partners/";
    httpClient("GET", URIPartners + idCustomer, {}, userData.token).then(
      (response) => {
        setPartnerList(response.data);
      }
    );
  };

  const getUsersTypes = () => {
    const URIShortCodes = ApiEndpoint + "configuration/customersuserstypes";
    httpClient("GET", URIShortCodes, {}, userData.token).then((response) => {
      setUsersTypes(response.data);
    });
  };

  useEffect(() => {
    getUsersTypes();
  }, []);

  useEffect(() => {
    getPartnersList();
  }, []);
  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    const URICreateUser = ApiEndpoint + "partner/createuser";

    var formatregex =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;
    if (formatregex.test(values.password)) {
      setPassValidateFormat(false);
    } else {
      setPassValidateFormat(true);
      return;
    }

    httpClient(
      "POST",
      URICreateUser,
      {
        idpartner: partnerUid,
        username: values.username,
        password: values.password,
        displayname: values.username,
        name: values.name,
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        usertype: values.idtypeuser, //AGENTE
        phone: values.phone,
        smslimit: values.limitsms,
      },
      userData.token
    )
      .then(function (response) {
        console.log(response);
        handleClose();
        return;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <FloatingLabel label="Partner *" className="mb-3">
        <Form.Select
          id="idPartner"
          name="idPartner"
          onChange={(selectedOption) => {
            setPartnerUid(selectedOption.target.value);
          }}
          value={values.idPartner}
          required
          aria-label="select_carrier"
        >
          <option value="">Seleccione una opcion de la lista</option>
          {!!partnerList?.length &&
            partnerList.map((partner) => (
              <option value={partner.uidpartner}>{partner.name}</option>
            ))}
        </Form.Select>
      </FloatingLabel>
      <Form noValidate validated={validated} onSubmit={handleSubmit1}>
        <FloatingLabel label="Nombre" className="mb-3">
          <Form.Control
            type="text"
            name="name"
            id="name"
            value={values.name}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el nombre del nuevo usuario"
          />
        </FloatingLabel>
        <FloatingLabel label="Apellido Paterno" className="mb-3">
          <Form.Control
            type="text"
            name="firstname"
            id="firstname"
            value={values.firstname}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el Apellido Paterno"
          />
        </FloatingLabel>
        <FloatingLabel label="Apellido Materno" className="mb-3">
          <Form.Control
            type="text"
            name="lastname"
            id="lastname"
            value={values.lastname}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el Apellido Materno"
          />
        </FloatingLabel>
        <FloatingLabel label="Email" className="mb-3">
          <Form.Control
            type="email"
            name="email"
            id="email"
            value={values.email}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese un Email Valido"
          />
        </FloatingLabel>
        <FloatingLabel label="Teléfono" className="mb-3">
          <Form.Control
            type="text"
            name="phone"
            id="phone"
            maxLength="10"
            onkeypress="return event.charCode>=48 && event.charCode<=57"
            value={values.phone}
            required
            pattern="^\d{10}$"
            onChange={(event) => {
              event.target.value = event.target.value.replace(/[^\d]+/g, "");
              onChange(event);
            }}
            placeholder="Ingrese un Telefono Valido"
          />
        </FloatingLabel>
        <FloatingLabel label="limite Mensual" className="mb-3">
          <Form.Control
            type="text"
            name="limitsms"
            id="limitsms"
            value={values.limitsms}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el limite mensual"
          />
        </FloatingLabel>
        <hr />
        <FloatingLabel label="Usuario" className="mb-3">
          <Form.Control
            type="text"
            name="username"
            id="username"
            value={values.username}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese un Usuario"
          />
        </FloatingLabel>
        <FloatingLabel label="Contraseña" className="mb-3">
          <Form.Control
            type="password"
            name="password"
            id="password"
            value={values.password}
            required
            onChange={(event) => {
              onChange(event);
              validatePassB(event);
              setInputValue(event.target.value);
            }}
            placeholder="Ingrese una Contraseña"
          />
        </FloatingLabel>
        <FloatingLabel label="Confirmar contraseña" className="mb-3">
          <Form.Control
            type="password"
            name="confirmpassword"
            id="confirmpassword"
            value={values.confirmpassword}
            required
            onChange={(event) => {
              onChange(event);
              validatePass(event);
              setinputValueConfirm(event.target.value);
            }}
            placeholder="Ingrese nuevamente la nueva Contraseña"
          />
        </FloatingLabel>
        {passValidate && passValidateB && (
          <Alert
            key="danger"
            variant="danger"
            size="sm"
            style={{ fontSize: "13px", padding: "5px 10px" }}
          >
            Las contraseñas no coinciden
          </Alert>
        )}
        {passValidateFormat && (
          <Alert
            key="danger"
            variant="danger"
            size="sm"
            style={{ fontSize: "13px", padding: "5px 10px" }}
          >
            La contraseña no cumple el formato requerido. - Debe tener al menos
            8 caracteres - Debe contener una minuscula - Debe contener una
            mayuscula - Debe contener un numero - Debe contener un caracter
            especial
          </Alert>
        )}
        <hr />
        <FloatingLabel label="Tipo de Usuario *" className="mb-3">
          <Form.Select
            id="idtypeuser"
            name="idtypeuser"
            onChange={(selectedOption) => {
              onChange(selectedOption);
            }}
            value={values.idtypeuser}
            required
            aria-label="select_usertypes"
          >
            <option value="">Seleccione una opcion de la lista</option>
            {!!usersTypes?.length &&
              usersTypes.map((usertype) => (
                <option value={usertype.idusertype}>{usertype.usertype}</option>
              ))}
          </Form.Select>
        </FloatingLabel>
        <div className="text-right">
          <Button variant="primary" type="submit" className="align-self-end">
            Guardar
          </Button>
        </div>
      </Form>
    </>
  );
};
export default EditCustomerUser;
