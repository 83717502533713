import { Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import * as RiIcons from "react-icons/ri";

const BtnExportToExcell = ({ dataReport, fileName, label = "Exportar" }) => {
  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, fileName + ".xlsx");
  };

  return (
    <>
      <Button
        onClick={() => downloadExcel(dataReport)}
        className="ExcelExport"
        variant="outline-primary"
        size="sm"
      >
        <RiIcons.RiFileExcel2Line /> {label}
      </Button>
    </>
  );
};
export default BtnExportToExcell;
