import React, { useEffect, useState } from "react";
import Notification from "../../components/notification";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { useAuth } from "../../Context/AuthContext";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { httpClient } from "../../utils/HttpClient";

const EditBitlyToken = ({ handleShow, handleClose }) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [show, setShow] = useState(false);
  const [token, setToken] = useState(false);
  const [validated, setValidated] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [messageNotification, setMessageNotification] = useState(
    "Configuracion Actualizada"
  );
  const [values, setValues] = useState({
    token: "",
  });

  function GetToken() {
    var userData = Auth.getSessionData();

    httpClient(
      "GET",
      ApiEndpoint + "partnersettings/" + userData.uid_partner,
      {},
      userData.token
    ).then((response) => {
      console.log(response.data);
      setToken(response.data.tokenbitly);
    });
  }

  useEffect(() => {
    GetToken();
  }, []);
  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);

    let URIShort = ApiEndpoint + "savePartnersettings";
    var userData = Auth.getSessionData();

    httpClient(
      "POST",
      URIShort,
      {
        id: userData.uid_partner,
        token: values.token,
      },
      userData.token
    )
      .then(function (response) {
        if (response.data.error) {
          setMessageNotification("Ha ocurrido un error");
          setNotificationType("warning");
          setShow(true);
        } else {
          setMessageNotification("Campaña creada");
          setNotificationType("");
          setShow(true);
          handleClose();
        }
        return;
      })
      .catch(function (error) {
        setMessageNotification("Ha ocurrido un error");
        setNotificationType("warning");
        setShow(true);
        console.log(error);
      });
  };
  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <div className="NewCampaignUrl">
        <Form noValidate validated={validated} onSubmit={handleSubmit1}>
          <FloatingLabel label="Token *" className="mb-3">
            <Form.Control
              name="token"
              id="token"
              placeholder="Escribe un nombre"
              required
              onChange={(event) => {
                onChange(event);
              }}
            />
          </FloatingLabel>
          <h5>
            Ingresa tu propio token generado en Bitly para asociar una cuenta
          </h5>

          <Alert key={"success"} variant={"success"} className="Notice">
            <b>Token actual:</b> {token}
          </Alert>

          <h5></h5>

          <div className="text-right">
            <Button
              variant="primary"
              type="submit"
              // onClick={() => {
              //   GenerateLink();
              // }}
              className="align-self-end"
            >
              Enviar
            </Button>
          </div>
        </Form>
      </div>

      <Notification
        alertType={notificationType}
        show={show}
        setShow={setShow}
        message={messageNotification}
      />
    </>
  );
};

export default EditBitlyToken;
