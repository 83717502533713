import moment from "moment";
import React, { useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import DateFilters from "../../components/generic/_DateFilters";
import ButtonRefresh from "../../components/generic/_ButtonRefresh";

const SimpleDateFilters = ({ onRefresh, setPeriodStr, loading = false }) => {
  const [filterFrom, setFilterFrom] = useState(false);
  const [filterTo, setFilterTo] = useState(false);

  const getFilters = () => {
    onRefresh({
      dateFrom: filterFrom,
      dateTo: filterTo,
    });
  };

  return (
    <>
      <Form>
        <DateFilters
          setPeriodStr={setPeriodStr}
          setFilterFrom={setFilterFrom}
          setFilterTo={setFilterTo}
        ></DateFilters>

        <div className="text-right">
          <ButtonRefresh
            refreshFunction={getFilters}
            loading={loading}
          ></ButtonRefresh>
        </div>
      </Form>
    </>
  );
};

export default SimpleDateFilters;
