import React, { useState } from "react";
import styled from "styled-components";

// Bootstrap
import Stack from "react-bootstrap/Stack";

// Icons
import { MissInfoReport } from "../../components/MissInfo";
import ReportFiltersSmry from "./ReportFiltersSmry";
import ReportSmsSmryList_V2 from "./_ReportSmsSmryListV2";

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ReportSmsSummary = () => {
  const [reportData, setReportData] = useState(null);
  const [periodStr, setPeriodStr] = useState("");
  return (
    <>
      <div className="">
        <Stack direction="horizontal" gap={2}>
          <div>
            <h2>Resumen por fecha</h2>
            <h3>Resumen de Mensajes exitosos con base en la fecha.</h3>
          </div>
        </Stack>

        <div className="ReportDate">
          <ReportFiltersSmry
            endPointReport="getpartnerSummary"
            setReportData={setReportData}
            setPeriodStr={setPeriodStr}
          />
        </div>

        <Divider />
        {reportData ? (
          <ReportSmsSmryList_V2
            periodStr={periodStr}
            listMessages={reportData}
          ></ReportSmsSmryList_V2>
        ) : (
          <MissInfoReport />
        )}
      </div>
    </>
  );
};

export default ReportSmsSummary;
