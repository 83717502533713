import React from "react";
import styled from "styled-components";

// Assets
import Logo from "../assets/logo.png";

// Assets
import { IconContext } from "react-icons/lib";

// Components
import { SideBarData } from "./sideBarData";
import SubMenu from "./subMenu";
import { useAuth } from "../Context/AuthContext";

const SidebarNav = styled.nav`
    background-color: #ffffff;
    box-shadow: 0px 0px 60px 0px #0000000f;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    top: 0;
    left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
    transition: 350ms;
    z-index; 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const SideBar = () => {
  const Auth = useAuth();

  return (
    <IconContext.Provider value={{ color: "#009cff" }}>
      <div className="LeftSide">
        <SidebarNav>
          <img src={Logo} alt="logotipo" className="LogotipoSide" />
          <SidebarWrap>
            {SideBarData.filter(
              (item) =>
                item.isAdminRoute == Auth.isAdminRole() &&
                item.roles.includes(Auth.getUserRole())
            ).map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
      </div>
    </IconContext.Provider>
  );
};

export default SideBar;
