import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useAuth } from "../Context/AuthContext";

//Assets
import Avatar from "../assets/avatar.jpg";
import { Link } from "react-router-dom";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

function DropdownProfileMenu() {
  const Auth = useAuth();
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} drop={"up-centered"}>
        <div
          className="UserPic"
          style={{
            background: `url(${Avatar})`,
          }}
        ></div>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/User">
          Perfil
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/Security">
          Seguridad
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/Partner/Endpoints">
          Notificaciones
        </Dropdown.Item>
        {/* <Dropdown.Item as={Link} to="/Security">
          Ayuda
        </Dropdown.Item> */}
        <Dropdown.Divider />
        <Dropdown.Item
          href="#"
          onClick={() => {
            Auth.logout();
          }}
        >
          Cerrar sesión
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownProfileMenu;
