import { createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./LocalSess";
import {
  EncryptData,
  decryptSessionData,
  encryptSessionData,
} from "../utils/Encrypt";
const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useLocalStorage("smsCallUser", null);
  const [auth, setAuth] = useState(false);
  const navigate = useNavigate();
  // call this function when you want to authenticate the user
  const login = async (data, token) => {
    setAuth(true);
    data[0].token = token;
    setUserData(encryptSessionData(data));
    navigate("/home");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setAuth(false);
    setUserData(null);

    navigate("/", { replace: true });
  };

  const logoutNoRedirect = () => {
    setAuth(false);
    setUserData(null);

    console.log("entre a logoutNoRedirect ");
  };

  const getSessionData = () => {
    const dataUserEncr = decryptSessionData(userData);
    return dataUserEncr[0];
  };

  const isAdminRole = () => {
    const dataUserEncr = decryptSessionData(userData);
    return (
      (dataUserEncr[0].id_usertype == 1 ||
        dataUserEncr[0].id_usertype == 4 ||
        dataUserEncr[0].id_usertype == 5) ??
      false
    );
  };

  const getUserRole = () => {
    const dataUserEncr = decryptSessionData(userData);
    return dataUserEncr[0].id_usertype;
  };

  const value = useMemo(
    () => ({
      userData,
      auth,
      login,
      logout,
      logoutNoRedirect,
      getSessionData,
      isAdminRole,
      getUserRole,
    }),
    [userData]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
