import React, { useEffect, useState } from "react";
import * as AiIcons from "react-icons/ai";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTableSearchBox from "../../components/AdvanceTableSearchBox";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import axios from "axios";
import { Button, Stack } from "react-bootstrap";
import ModalEdit from "../../components/ModalEdit";
import CreateCustomerPrice from "./_CreateCustomerPrice";
import { FormatCurrency3Dec, FormatInt } from "../../utils/FormatData";
import Confirm from "../../components/Confirm";
import Notification from "../../components/notification";
import { httpClient } from "../../utils/HttpClient";
import { useAuth } from "../../Context/AuthContext";

const CustomerPrices = ({ idCustomer, uidCustomer }) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();

  const [pricesData, setPricesData] = useState(null);
  const [priceEdit, setPriceEdit] = useState(null);
  const [displayMessage, setDisplayMessage] = useState(null);
  const [message, setMessage] = useState(null);
  const [notificationType, setNotificationType] = useState(null);
  const [idToDeleteInfoPriceCustomer, setIdToDeleteInfoPriceCustomer] =
    useState(null);
  const [showInfoPriceCustomer, setShowInfoPriceCustomer] = useState(false);
  const [show, setShow] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const handleCloseInfoPartner = () => {
    setShow(false);
    getCustomerPricesData();
  };
  const handleShowInfoPartner = () => setShow(true);

  const handleCloseNotificacion = () => {
    setDisplayMessage(false);
  };

  const ModalHandleCloseConfirm = () => {
    setShowConfirmDelete(false);
    getCustomerPricesData();
    setIdToDeleteInfoPriceCustomer(0);
  };

  const columns = [
    {
      accessor: "limitMin",
      Header: "Range Min",
      disableSortBy: true,
      headerProps: {
        className: "text-left",
      },
      cellProps: {
        className: "text-left",
      },
      Cell: (rowData) => {
        let limit = rowData.row.original.limitMin;
        return <>{FormatInt(limit)}</>;
      },
    },
    {
      accessor: "limitMax",
      Header: "Range Max",
      disableSortBy: true,
      headerProps: {
        className: "text-left",
      },
      cellProps: {
        className: "text-left",
      },
      Cell: (rowData) => {
        let limit = rowData.row.original.limitMax;
        return <>{FormatInt(limit)}</>;
      },
    },
    {
      accessor: "unitPrice",
      Header: "Precio Unitario",
      disableSortBy: true,
      headerProps: {
        className: "text-left",
      },
      cellProps: {
        className: "text-left",
      },
      Cell: (rowData) => {
        let unitPrice = rowData.row.original.unitPrice;
        return <>{FormatCurrency3Dec(unitPrice)}</>;
      },
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let idToEdit = rowData.row.original.idpartnerprice;
        return (
          <>
            {/* <AiIcons.AiFillEdit
              className="IconTable"
              title="Editar"
              onClick={() => {
                setShow(true);
                setShowInfoPriceCustomer(idToEdit);
              }}
            /> */}
            <AiIcons.AiFillDelete
              className="IconTable"
              title="Eliminar"
              onClick={() => {
                setIdToDeleteInfoPriceCustomer(idToEdit);
                setShowConfirmDelete(true);
              }}
            />
          </>
        );
      },
    },
  ];

  const handleConfirm = () => {
    if (
      idToDeleteInfoPriceCustomer != null &&
      idToDeleteInfoPriceCustomer != ""
    ) {
      deleteRecord();
    }
  };

  const deleteRecord = () => {
    if (idToDeleteInfoPriceCustomer < 1) return;
    const URI = ApiEndpoint + "configuration/pricesbycustomer/";

    httpClient(
      "POST",
      URI,
      {
        partnerprice: idToDeleteInfoPriceCustomer,
        idcustomer: uidCustomer,
      },
      userData.token
    )
      .then(function (response) {
        console.log(response.data);
        if (response.data.error) {
          setMessage(
            "Ha ocurrido un error, debe validar que no existan registros mayores al actual"
          );
          setDisplayMessage(true);
          setNotificationType("warning");
          return;
        }
        setMessage("Registro eliminado exitosamente");
        setDisplayMessage(true);

        ModalHandleCloseConfirm();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getCustomerPricesData = () => {
    const URI = ApiEndpoint + "configuration/pricesbycustomer/" + idCustomer;

    httpClient("GET", URI, {}, userData.token)
      .then(function (response) {
        setPricesData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getCustomerPricesData();
  }, []);
  return (
    <>
      <div>
        <Stack direction="horizontal" gap={2} className="wrappTitle">
          <h4>Lista de Precios por Mensaje</h4>
          <div className="ms-auto">
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => {
                setShowInfoPriceCustomer(0);
                setShow(true);
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>{" "}
          </div>
        </Stack>
        {pricesData && (
          <AdvanceTableWrapper
            columns={columns}
            data={pricesData}
            sortable
            pagination
            perPage={10}
          >
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            <AdvanceTablePagination table />
            <AdvanceTableFooter
              rowCount={pricesData.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
              pageSize={10}
            />
          </AdvanceTableWrapper>
        )}
      </div>
      <ModalEdit
        show={show}
        handleShow={handleShowInfoPartner}
        handleClose={handleCloseInfoPartner}
        title={"Modificar Precio"}
        size="lg"
      >
        <CreateCustomerPrice
          uidCustomer={uidCustomer}
          handleClose={handleCloseInfoPartner}
          setMessage={setMessage}
          setDisplayMessage={setDisplayMessage}
          setNotificationType={setNotificationType}
        ></CreateCustomerPrice>
      </ModalEdit>
      <Confirm
        show={showConfirmDelete}
        handleClose={ModalHandleCloseConfirm}
        handleConfirm={handleConfirm}
        message={"Desea eliminar el registro seleccionado?"}
      />
      {displayMessage && (
        <Notification
          show={displayMessage}
          setShow={handleCloseNotificacion}
          message={message}
          alertType={notificationType}
        />
      )}
    </>
  );
};

export default CustomerPrices;
