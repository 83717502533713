import React, { useState } from "react";

// Bootstrap
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

function Notification({ show, setShow, message, alertType = "" }) {
  return (
    <>
      <ToastContainer className="p-3" position={"bottom-end"}>
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={4000}
          bg={alertType}
          autohide={true}
        >
          <Toast.Header closeVariant="white">
            <strong className="me-auto">MB sms</strong>
          </Toast.Header>
          <Toast.Body> {message} </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}

export default Notification;
