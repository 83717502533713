import React, { useEffect, useState } from "react";

// Bootstrap
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { FormatDateWTime } from "../../utils/FormatData";

import { MissInfoMessage } from "../../components/MissInfo";
import MessageDeliveryDetail from "./_MessageDeliveryDetail";
import BtnExportToExcell from "../../utils/BtnExportToExcell";
import { useAuth } from "../../Context/AuthContext";
import { httpClient } from "../../utils/HttpClient";

const MessageDetail = ({
  idStatus,
  nameCampaign,
  uidCampaign,
  idCampaignMessage,
}) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [databaseRecords, setDatabaseRecords] = useState(null);
  const [messageSummary, setMessageSummary] = useState(null);
  const [messageErrors, setMessageErrors] = useState(null);

  const GetSummaryCampaignMessage = () => {
    setMessageSummary(null);
    const URI = ApiEndpoint + "reports/summarycampaignmessage/";

    httpClient(
      "POST",
      URI,
      {
        uidcampaign: uidCampaign,
        idcampaignmessage: idCampaignMessage,
      },
      userData.token
    )
      .then(function (response) {
        setMessageSummary(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetErrorsCampaignMessage = () => {
    setMessageErrors(null);
    const URI = ApiEndpoint + "reports/errorscampaignmessage/";

    httpClient(
      "POST",
      URI,
      {
        uidcampaign: uidCampaign,
        idcampaignmessage: idCampaignMessage,
      },
      userData.token
    )
      .then(function (response) {
        setMessageErrors(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getCampaignMessageDetail = () => {
    const URI = ApiEndpoint + "reports/GetCampaignMessageDetail/";

    httpClient(
      "POST",
      URI,
      {
        uidcampaign: uidCampaign,
        idcampaignmessage: idCampaignMessage,
      },
      userData.token
    )
      .then(function (response) {
        setDatabaseRecords(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = () => {
    // getCampaignMessageDetail();
    GetSummaryCampaignMessage();
    GetErrorsCampaignMessage();
  };
  return (
    <>
      {messageSummary && (
        <MessageDeliveryDetail
          idStatus={idStatus}
          nameCampaign={nameCampaign}
          data={messageSummary}
          refreshData={refreshData}
        ></MessageDeliveryDetail>
      )}
      {messageErrors && messageErrors.length > 0 && (
        <BtnExportToExcell
          dataReport={messageErrors}
          fileName={"Errores campaña"}
          label="Detalle de Errores"
        ></BtnExportToExcell>
      )}

      <ListGroup as="ol" numbered>
        <hr />
        {databaseRecords && databaseRecords.length > 0 ? (
          databaseRecords.map((item) => {
            return (
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div>{item.vc_msisdn}</div>
                  <div className="DateDetails">
                    {FormatDateWTime(item.tst_create)}
                  </div>
                </div>
                <Badge
                  bg={
                    item.vc_status == "ENTREGADO"
                      ? "success"
                      : item.vc_status == "PROGRAMADO"
                      ? "warning"
                      : "danger"
                  }
                >
                  {item.vc_status}
                </Badge>
              </ListGroup.Item>
            );
          })
        ) : (
          <MissInfoMessage />
        )}
      </ListGroup>
    </>
  );
};

export default MessageDetail;
