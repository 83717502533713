import React, { useEffect, useState } from "react";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { Stack, Table, Button, Badge } from "react-bootstrap";

import * as AiIcons from "react-icons/ai";
import ModalEdit from "../../components/ModalEdit";
import EditMaskShortCode from "./_EditMaskShortCode";
import Confirm from "../../components/Confirm";
import { httpClient } from "../../utils/HttpClient";
import { useAuth } from "../../Context/AuthContext";
const URI = ApiEndpoint + "maskshortcodes/";

const MaskShortCodes = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [shortCodesMasks, setShortCodesMasks] = useState([]);
  const [show, setShow] = useState(false);
  const ModalHandleClose = () => setModalShowConfirm(false);
  const [modalMessage, setModalMessage] = useState(
    "Desea Cambiar el status del registro?"
  );
  const [idShortCodeMask, setIdShortCodeMask] = useState(0);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  const [idRecordToInactivate, setIdRecordToInactivate] = useState(false);
  const handleConfirm = () => {
    setModalShowConfirm(false);
    InactivateKeyword();
  };
  const handleClose = () => {
    getShortCodesMasks();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const getShortCodesMasks = () => {
    httpClient("GET", URI, {}, userData.token).then((response) => {
      setShortCodesMasks(response.data);
    });
  };

  useEffect(() => {
    getShortCodesMasks();
  }, []);

  const InactivateKeyword = () => {
    const URIDb =
      ApiEndpoint + "deactivatemaskshortcode/" + idRecordToInactivate;

    httpClient("DELETE", URIDb, {}, userData.token)
      .then(function (response) {
        getShortCodesMasks();
        setModalShowConfirm(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div>
        <Stack direction="horizontal" gap={2} className="AlignItemsTop">
          <div>
            <h2>Mascaras para Marcaciones</h2>
            <h3>Administre la lista de Mascaras.</h3>
          </div>
          <div className="ms-auto">
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                setIdShortCodeMask(0);
                setShow(true);
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>{" "}
          </div>
        </Stack>

        <Table striped>
          <thead>
            <tr>
              <th>Marcación</th>
              <th>Carrier</th>
              <th>Mascara</th>
              <th>Activa</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {shortCodesMasks &&
              shortCodesMasks.map((item) => {
                return (
                  <tr>
                    <td>{item.vc_shortcode}</td>
                    <td>{item.vc_carrier}</td>
                    <td>{item.vc_mask}</td>
                    <td>
                      <>
                        {item.b_active ? (
                          <Badge bg="primary">Activo</Badge>
                        ) : (
                          <Badge bg="secondary">Inactivo</Badge>
                        )}
                      </>
                    </td>
                    <td>
                      <AiIcons.AiOutlineEdit
                        className="IconTable"
                        onClick={() => {
                          setIdShortCodeMask(item.id_shortcode_mask);
                          handleShow();
                        }}
                      />
                      <AiIcons.AiFillEyeInvisible
                        className="IconTable"
                        title="Desactivar"
                        onClick={() => {
                          setIdRecordToInactivate(item.id_shortcode_mask);
                          setModalMessage(
                            "Desea Cambiar el status del registro"
                          );
                          setModalShowConfirm(true);
                        }}
                      />
                      {/* <AiIcons.AiOutlineDelete className="IconTable" /> */}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      <ModalEdit
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        title={idShortCodeMask > 0 ? "Editar Mascara" : "Agregar Mascara"}
      >
        <EditMaskShortCode
          show={show}
          idMaskShortCode={idShortCodeMask}
          handleClose={handleClose}
        />
      </ModalEdit>
      <Confirm
        show={modalShowConfirm}
        handleClose={ModalHandleClose}
        handleConfirm={handleConfirm}
        message={"Desea Cambiar desactivar la Mascara seleccionada?"}
      />
    </>
  );
};

export default MaskShortCodes;
