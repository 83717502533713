import React from "react";
import { ProgressBar, Stack } from "react-bootstrap";
import { FormatDate, FormatDateWTime } from "../../utils/FormatData";
import { Col, Row } from "react-bootstrap";
import * as FiIcons from "react-icons/fi";
import * as AiIcons from "react-icons/ai";
import { getStatus } from "../../utils/AuxFunctions";

const MessageDeliveryDetail = ({
  idStatus,
  nameCampaign,
  data,
  refreshData,
}) => {
  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="DbDetails6">
            <p>{nameCampaign}</p>
            <div className="details">Mensaje</div>
          </div>
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="DbDetails4">
            <Stack direction="horizontal" gap={2}>
              <FiIcons.FiSend />
              <div className="ms-auto">
                <p>{data.i_sent ?? 0}</p>
              </div>
            </Stack>
            <div className="details">Enviados</div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="DbDetails5">
            <Stack direction="horizontal" gap={2}>
              <FiIcons.FiMail />
              <div className="ms-auto">
                <p>{data.i_total ?? 0}</p>
              </div>
            </Stack>
            <div className="details">Total</div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div
            className={
              idStatus == 1
                ? "DbDetailsStatusCancel"
                : idStatus == 2
                ? "DbDetailsStatusOFF"
                : idStatus == 3
                ? "DbDetailsStatusConcluded"
                : "DbDetailsStatusCancel"
            }
            style={{ marginTop: "20px" }}
          >
            <Stack direction="horizontal" gap={2}>
              <AiIcons.AiOutlineFlag />
              <div className="ms-auto">
                <p>{getStatus(idStatus)}</p>
              </div>
            </Stack>
            <div className="details">Estatus</div>
          </div>
        </Col>
      </Row>

      <div className="Status">
        <Stack direction="horizontal" gap={2}>
          <h6>Progreso</h6>
          <div className="ms-auto">
            <FiIcons.FiRefreshCw onClick={refreshData} />
          </div>
        </Stack>
        <h5>Mensajes enviados:</h5>
        <ProgressBar
          variant="success"
          animated
          now={(data.i_sent ?? 0 / data.i_total ?? 1) * 100}
        />
        <Stack direction="horizontal" gap={2}>
          <div>
            <p>{data.i_sent ?? 0}</p>
          </div>
          <div className="ms-auto">
            <p>{data.i_total ?? 0}</p>
          </div>
        </Stack>{" "}
        <Stack direction="horizontal" gap={2}>
          <div>
            <p>
              Entregados: <b>{data.i_sent ?? 0}</b>
            </p>
          </div>
          <div className="ms-auto">
            <p>
              Fallidos: <b>{data.i_failed ?? 0}</b>
            </p>
          </div>
        </Stack>{" "}
        <Stack direction="horizontal" gap={2}>
          <div>
            <p>
              En proceso: <b>{data.i_programmed ?? 0}</b>
            </p>
          </div>
          <div className="ms-auto">
            <p>
              Total: <b>{data.i_total ?? 0}</b>
            </p>
          </div>
        </Stack>
        <Stack direction="horizontal" gap={2}>
          <div>
            <p>
              Confirmados: <b>{data.i_deliver ?? 0}</b>
            </p>
          </div>
          <div className="ms-auto">
            <p>
              Invalidos: <b>{data.i_invalid ?? 0}</b>
            </p>
          </div>
        </Stack>
        <Stack direction="horizontal" gap={2}>
          <div>
            <p>
              Inicio de Envio: <br />
              <b>{data.tst_first ? FormatDateWTime(data.tst_first) : " - "}</b>
            </p>
          </div>
          <div className="ms-auto">
            <p>
              Ultima Entrega: <br />
              <b>{data.tst_last ? FormatDateWTime(data.tst_last) : " - "}</b>
            </p>
          </div>
        </Stack>
      </div>
    </>
  );
};

export default MessageDeliveryDetail;
