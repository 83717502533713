import React, { useEffect, useState } from "react";
import { ApiEndpoint } from "../../utils/ApiEndpoint.js";
import axios from "axios";
import { Stack, Table, Button } from "react-bootstrap";

import * as AiIcons from "react-icons/ai";
import EditCustomer from "./EditCustomer";
import ModalEdit from "../../components/ModalEdit.js";
import { Link } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext.js";
import { httpClient } from "../../utils/HttpClient.js";
import AdvanceTableFooter from "../../components/AdvanceTableFooter.js";
import AdvanceTable from "../../components/AdvanceTable.js";
import AdvanceTableSearchBox from "../../components/AdvanceTableSearchBox.js";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper.js";
import AdvanceTablePagination from "../../components/AdvanceTablePagination.js";
const URI = ApiEndpoint + "customers/";

const Customers = () => {
  const Auth = useAuth();
  const [customers, setCustomers] = useState([]);
  const [show, setShow] = useState(false);
  const [idCustomer, setIdCustomer] = useState(0);
  var userData = Auth.getSessionData();
  const handleClose = () => {
    getCustomers();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const getCustomers = () => {
    httpClient("GET", URI, {}, userData.token).then((response) => {
      setCustomers(response.data);
    });
  };

  useEffect(() => {
    getCustomers();
  }, []);

  const columns = [
    {
      accessor: "idcustomer",
      Header: "#",
    },
    {
      accessor: "name",
      Header: "Nombre",
    },
    {
      accessor: "comercialname",
      Header: "Nombre Comercial",
    },
    {
      accessor: "rfc",
      Header: "RFC",
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let customer = rowData.row.original;
        return (
          <>
            <AiIcons.AiOutlineEdit
              className="IconTable"
              onClick={() => {
                setIdCustomer(customer.idcustomer);
                handleShow();
              }}
            />
            <Link to={"/Customer/Details/" + customer.uidCustomer}>
              <AiIcons.AiOutlineSearch
                className="IconTable"
                title="Detalles"
                onClick={""}
              />
            </Link>
            <AiIcons.AiOutlineDelete className="IconTable" />
          </>
        );
      },
    },
  ];
  return (
    <>
      <div>
        <Stack direction="horizontal" gap={2} className="AlignItemsTop">
          <div>
            <h2>Clientes</h2>
            <h3>Administre la lista de clientes.</h3>
          </div>
          <div className="ms-auto">
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                setIdCustomer(0);
                setShow(true);
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>{" "}
          </div>
        </Stack>
        {customers && (
          <AdvanceTableWrapper
            columns={columns}
            data={customers}
            sortable
            pagination
            perPage={10}
          >
            <div className="SearchBar">
              <Stack direction="horizontal" gap={3}>
                <AdvanceTableSearchBox table />
              </Stack>
            </div>
            <h4>Lista de Campañas</h4>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            <AdvanceTablePagination table />
            <AdvanceTableFooter
              rowCount={customers.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
              pageSize={10}
            />
          </AdvanceTableWrapper>
        )}
      </div>
      <ModalEdit
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        title={idCustomer > 0 ? "Editar Cliente" : "Agregar Cliente"}
      >
        <EditCustomer
          show={show}
          handleShow={handleShow}
          handleClose={handleClose}
          idCustomer={idCustomer}
        />
      </ModalEdit>
    </>
  );
};

export default Customers;
