import React, { useState } from "react";

// Bootstrap
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

function GlobalNotification({ message, alertType = "" }) {
  const [showGlobalNotification, setShowGlobalNotification] = useState(true);
  console.log("ebntre a GlobalNotification");
  return (
    <>
      <ToastContainer className="p-3" position={"bottom-end"}>
        <Toast
          onClose={() => setShowGlobalNotification(false)}
          show={showGlobalNotification}
          delay={4000}
          bg={alertType}
          autohide={true}
        >
          <Toast.Header closeVariant="white">
            <strong className="me-auto">MB sms</strong>
          </Toast.Header>
          <Toast.Body> {message} </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}

export default GlobalNotification;
