import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";
import { useAuth } from "../Context/AuthContext";
import axios from "axios";
import { ApiEndpoint } from "../utils/ApiEndpoint";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { httpClient } from "../utils/HttpClient";
const moment = require("moment");

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function SmsByDateGraph() {
  const Auth = useAuth();
  const [chartData, setChartData] = useState(false);
  const [dataCategories, setDataCategories] = useState([]);
  const [dataReport1, setDataReport1] = useState([]);
  const [filterType, setFilterType] = useState(0);
  const getReportData = () => {
    var userDataFromSess = Auth.getSessionData();
    var now = new Date();
    if (filterType == 0) {
      const URIData = ApiEndpoint + "reports/getMessagesByDay";

      httpClient(
        "POST",
        URIData,
        {
          uidpartner: userDataFromSess.uid_partner,
          month: now.getMonth() + 1,
          year: now.getFullYear(),
          user: userDataFromSess.uid_user,
        },
        userDataFromSess.token
      ).then((response) => {
        if (response.data.error) {
        } else {
          setChartData(response.data);
        }
      });
    } else {
      var dateReport = new Date();
      dateReport.setDate(dateReport.getDate() - filterType);
      const URIData = ApiEndpoint + "reports/getMessagesByDayDate";

      httpClient(
        "POST",
        URIData,
        {
          uidpartner: userDataFromSess.uid_partner,
          date: moment(dateReport).format("YYYY/MM/DD"),
          user: userDataFromSess.uid_user,
        },
        userDataFromSess.token
      ).then((response) => {
        if (response.data.error) {
        } else {
          setChartData(response.data);
        }
      });
    }
  };
  useEffect(() => {
    if (!chartData) return;

    let AllDays = [];
    chartData.map((item) => {
      AllDays.push(item.create);
    });

    var uniqueDays = [...new Set(AllDays)];
    setDataCategories(uniqueDays);

    var dataToGraph1 = [];

    uniqueDays.map((create) => {
      let subtotal = chartData
        .filter((item) => item.create === create)
        .reduce((total, row) => {
          return parseInt(row.total) + parseInt(total);
        }, 0);

      dataToGraph1.push(subtotal);
    });
    setDataReport1(dataToGraph1);
    console.log("dataToGraph1");
    console.log(dataToGraph1);
    console.log(dataCategories);
  }, [chartData]);

  useEffect(() => {
    getReportData();
  }, []);

  useEffect(() => {
    getReportData();
  }, [filterType]);

  const data = {
    labels: dataCategories || [],
    datasets: [
      {
        label: "SMS por Dia",
        data: dataReport1 || [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "SMS por Dia",
      },
    },
  };

  return (
    <>
      <div style={{ height: "300px", width: "100%" }}>
        <div>
          <ButtonToolbar className="justify-content-between Bar chartToolBar">
            <h6>SMS por periodo</h6>
            <ButtonGroup aria-label="First group" size="sm">
              <Button
                variant={filterType == 0 ? "primary" : "outline-secondary"}
                onClick={() => {
                  setFilterType(0);
                }}
              >
                Mes Actual
              </Button>
              <Button
                variant={filterType == 30 ? "primary" : "outline-secondary"}
                onClick={() => {
                  setFilterType(30);
                }}
              >
                30 días
              </Button>
              <Button
                variant={filterType == 90 ? "primary" : "outline-secondary"}
                onClick={() => {
                  setFilterType(90);
                }}
              >
                90 días
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </div>
        {data && <Line options={options} data={data} />}
      </div>
    </>
  );
}

export default SmsByDateGraph;
