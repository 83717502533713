import React, { useEffect, useState } from "react";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import SmsTextBox from "../../components/generic/_SmsTextBox";
import { httpClient } from "../../utils/HttpClient";
import { useAuth } from "../../Context/AuthContext";

const EditKeyword = ({ show, idKeyword, handleClose }) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();

  const [validated, setValidated] = useState(false);
  const [carrierList, setCarrierList] = useState(false);
  const [partnerList, setPartnerList] = useState(false);
  const [customersList, setCustomersList] = useState(false);
  const [moActions, setMoActions] = useState(false);
  const [shortCodesList, setShortCodesList] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(false);
  const [selectedMoAction, setSelectedMoAction] = useState(false);
  const [keywordsError, setKeywordsError] = useState(null);
  const [values, setValues] = useState({
    idShortCode: "0",
    idPartner: "0",
    idService: "0",
    keyword: "",
    alias: "",
    active: "true",
    idAction: "0",
    parameters: "",
    httpmethod: "GET",
    endpoint: "",
  });

  const getKeyword = () => {
    if (idKeyword > 0) {
      const URIKw = ApiEndpoint + "administration/keyword/" + idKeyword;

      httpClient("GET", URIKw, {}, userData.token).then((response) => {
        setValues({
          keyword: response.data.keyword,
          alias: response.data.alias,
          endpoint: response.data.cat_keywordsactions[0].endpoint,
          httpmethod: response.data.cat_keywordsactions[0].httpmethod,
          parameters: response.data.cat_keywordsactions[0].parameters,
        });
        setSelectedMoAction(response.data.idAction);
      });
    }
  };

  useEffect(() => {
    getKeyword();
  }, []);
  const getMoActions = () => {
    const URIMoActions = ApiEndpoint + "configuration/moactions";
    httpClient("GET", URIMoActions, {}, userData.token).then((response) => {
      setMoActions(response.data);
    });
  };

  const getCarriersList = () => {
    const URICarriers = ApiEndpoint + "carriers";
    httpClient("GET", URICarriers, {}, userData.token).then((response) => {
      setCarrierList(response.data);
    });
  };

  const getPartnersList = (idCustomer) => {
    const URIPartners = ApiEndpoint + "partners/";
    httpClient("GET", URIPartners, {}, userData.token).then((response) => {
      setPartnerList(response.data);
    });
  };

  const getCustomersList = () => {
    const URICustomers = ApiEndpoint + "customers";
    httpClient("GET", URICustomers, {}, userData.token).then((response) => {
      setCustomersList(response.data);
    });
  };

  const getShortCodes = (idCarrier) => {
    const URIShortCodes = ApiEndpoint + "shortcodesbycarrier/";
    httpClient("GET", URIShortCodes + idCarrier, {}, userData.token).then(
      (response) => {
        setShortCodesList(response.data);
      }
    );
  };
  useEffect(() => {
    if (selectedCarrier) getShortCodes(selectedCarrier);
  }, [selectedCarrier]);

  useEffect(() => {
    if (selectedCustomer) getPartnersList(selectedCustomer);
  }, [selectedCustomer]);

  useEffect(() => {
    getCarriersList();
    getCustomersList();
    getMoActions();
  }, []);
  const handleSubmit1 = (event) => {
    event.preventDefault();
    setKeywordsError(null);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    const URICustomer = ApiEndpoint + "administration/createkeyword";
    if (idKeyword > 0) {
      httpClient(
        "PUT",
        URICustomer + "/" + idKeyword,
        {
          keyword: values.keyword.replace(/ /g, ""),
          alias: values.alias.replace(/ /g, ""),
          action: values.idAction,
          endpoint: values.endpoint ?? "",
          method: values.httpmethod ?? "",
          parameters: values.parameters ?? "",
        },
        userData.token
      )
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          if (response.data != "") {
            setKeywordsError(JSON.stringify(response.data));
            return;
          } else {
            setKeywordsError(null);
            handleClose();
            return;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      httpClient(
        "POST",
        URICustomer,
        {
          keyword: values.keyword.replace(/ /g, ""),
          idshortcode: values.idShortCode,
          alias: values.alias.replace(/ /g, ""),
          partner: values.idPartner,
          action: values.idAction,
          endpoint: values.endpoint,
          method: values.httpmethod,
          parameters: values.parameters,
        },
        userData.token
      )
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          if (response.data != "") {
            setKeywordsError(JSON.stringify(response.data));
            return;
          } else {
            setKeywordsError(null);
            handleClose();
            return;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]:
        name == "alias" || name == "keyword" ? value.replace(/ /g, "") : value,
    });
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit1}>
        {idKeyword == 0 && (
          <>
            <FloatingLabel label="Cliente *" className="mb-3">
              <Form.Select
                id="idcliente"
                name="idcliente"
                onChange={(selectedOption) => {
                  setSelectedCustomer(`${selectedOption.target.value}`);
                }}
                value={selectedCustomer}
                required
                aria-label="select_customer"
              >
                <option value="">Seleccione una opcion de la lista</option>
                {!!customersList?.length &&
                  customersList.map((customer) => (
                    <option value={customer.idcustomer}>
                      {customer.comercialname}
                    </option>
                  ))}
              </Form.Select>
            </FloatingLabel>

            <FloatingLabel label="Partner *" className="mb-3">
              <Form.Select
                id="idPartner"
                name="idPartner"
                onChange={(selectedOption) => {
                  onChange(selectedOption);
                }}
                value={values.idPartner}
                required
                aria-label="select_carrier"
              >
                <option value="">Seleccione una opcion de la lista</option>
                {!!partnerList?.length &&
                  partnerList.map((partner) => (
                    <option value={partner.idpartner}>{partner.name}</option>
                  ))}
              </Form.Select>
            </FloatingLabel>

            <FloatingLabel label="Operadora *" className="mb-3">
              <Form.Select
                id="idcarrier"
                name="idcarrier"
                onChange={(selectedOption) => {
                  setSelectedCarrier(`${selectedOption.target.value}`);
                }}
                value={selectedCarrier}
                required
                aria-label="select_carrier"
              >
                <option value="">Seleccione una opcion de la lista</option>
                {!!carrierList?.length &&
                  carrierList.map((carrier) => (
                    <option value={carrier.idcarrier}>{carrier.carrier}</option>
                  ))}
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel label="Numero corto *" className="mb-3">
              <Form.Select
                id="idShortCode"
                name="idShortCode"
                onChange={(selectedOption) => {
                  onChange(selectedOption);
                }}
                value={values.idShortCode}
                required
                aria-label="select_shortCode"
              >
                <option value="">Seleccione una opcion de la lista</option>
                {!!shortCodesList?.length &&
                  shortCodesList.map((shortcode) => (
                    <option value={shortcode.idshortcode}>
                      {shortcode.shortcode}
                    </option>
                  ))}
              </Form.Select>
            </FloatingLabel>
          </>
        )}

        <FloatingLabel label="Keyword" className="mb-3">
          <Form.Control
            type="text"
            name="keyword"
            id="keyword"
            value={values.keyword}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el Keyword"
          />
        </FloatingLabel>

        <FloatingLabel label="Alias" className="mb-3">
          <Form.Control
            type="text"
            name="alias"
            id="alias"
            required
            value={values.alias}
            onChange={(event) => onChange(event)}
            placeholder="Ingrese palabras relacionadas"
          />
        </FloatingLabel>
        {keywordsError && keywordsError != "" && (
          <h5 style={{ marginTop: "0px", marginBottom: "5px" }}>
            {`Algunas de las keword nuevas ya se encuentran asignadas en esa
            marcacion: ${keywordsError}`}
          </h5>
        )}
        <FloatingLabel label="Accion *" className="mb-3">
          <Form.Select
            id="idAction"
            name="idAction"
            onChange={(selectedOption) => {
              setSelectedMoAction(`${selectedOption.target.value}`);
              onChange(selectedOption);
            }}
            value={selectedMoAction}
            required
            aria-label="select_moaction"
          >
            <option value="">Seleccione una opcion de la lista</option>
            {!!moActions?.length &&
              moActions.map((action) => (
                <option value={action.idMOActions}>{action.name}</option>
              ))}
          </Form.Select>
        </FloatingLabel>
        <hr />
        {selectedMoAction == 2 && (
          <>
            <SmsTextBox
              name="parameters"
              defaultValue={values.parameters}
              changeHandler={onChange}
            ></SmsTextBox>
          </>
        )}
        {selectedMoAction == 4 && (
          <>
            <FloatingLabel label="EndPoint" className="mb-3">
              <Form.Control
                type="text"
                name="endpoint"
                id="endpoint"
                value={values.endpoint}
                required
                onChange={(event) => {
                  onChange(event);
                }}
                placeholder="Ingrese la URL donde se ejecutara la notificacion"
              />
            </FloatingLabel>
            <FloatingLabel label="Parametros" className="mb-3">
              <Form.Control
                type="text"
                name="parameters"
                id="parameters"
                value={values.parameters}
                required
                onChange={(event) => {
                  onChange(event);
                }}
                placeholder="Ingrese el mensaje"
              />
            </FloatingLabel>
          </>
        )}
        <div className="text-right">
          <Button variant="primary" type="submit" className="align-self-end">
            {idKeyword > 0 ? "Actualizar" : "Guardar"}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default EditKeyword;
