import React from "react";

// Bootstrap
import Stack from "react-bootstrap/Stack";

// Icons
import * as FiIcons from "react-icons/fi";

function WidgetToSend({ used_events }) {
  return (
    <div className="Sended">
      <Stack direction="horizontal" gap={2}>
        <div>
          <FiIcons.FiSend />
        </div>
        <div className="ms-auto">
          <p>{used_events}</p>
        </div>
      </Stack>
      <div className="details">
        <a href="">Mensajes enviados</a>
      </div>
    </div>
  );
}

export default WidgetToSend;
