import React from "react";
import { Route, Routes } from "react-router-dom";

import Login from "./login";
import Home from "./home";
import Sms from "./sms";
import Campaigns from "./Campaigns/Campaigns";
import NewCampaign from "./Campaigns/NewCampaign";
import ReportUser from "./Reports/ReportUser";
import NotFound from "./notFound";
import Customers from "./Administration/Customers";
import MainLayout from "../components/MainLayout";
import ShortCodes from "./Administration/ShortCodes";
import UserInfo from "./User/user";
import Security from "./User/security";
import CamapaignsDetailDatabase from "./ContactsDatabase/DetailsDb";
import {
  ProtectedRoute,
  RolesAuthRoute,
  UnProtectedRoute,
} from "../Context/ProtectedRoute";
import CampaignDetail from "./Campaigns/CampaignDetail";
import ReportDate from "./Reports/ReportSmsByDatePeriod";
import ShortLinks from "./Campaigns/ShortLinks";
import UsersByPartner from "./Customer/users";
import CustomerDetail from "./Administration/CustomerDetail";
import SmsPackages from "./Configuration/SmsPackages";
import OperativeUsers from "./Configuration/OperativeUsers";
import Keywords from "./Administration/Keywords";
import ListaPreciosDefault from "./Configuration/ListaPreciosDefault";
import PartnerDatabases from "./Configuration/PartnerDatabases";
import ShortLinksAdmin from "./Administration/ShortLinksAdmin";
import CampaignsAdmin from "./Administration/CampaignsAdmin";
import NewCampaignAdmin from "./Administration/_NewCampaignAdmin";
import ReportMoPartner from "./Reports/ReportMoPartner";
import ReportMoAdmin from "./ReportsAdmin/ReportMoAdmin";
import ReportSmsSummaryAdmin from "./ReportsAdmin/ReportSmsSummaryAdmin";
import ReportPortabilidadAdmin from "./ReportsAdmin/ReportPortabilidadAdmin";
import ReportSmsByDateAdmin from "./ReportsAdmin/ReportSmsByDateAdmin";
import ReportCustomerCarrierAdmin from "./ReportsAdmin/ReportCustomerCarrierAdmin";
import MaskShortCodes from "./Administration/MaskShortCodes";
import ContactsDatabase from "./ContactsDatabase/CampaignDatabases";
import ReportCampaignSmsAdmin from "./ReportsAdmin/ReportCampaignSmsAdmin";
import ReportFiles from "./Reports/ReportFiles";
import ReportSmsSummaryByCarrier from "./Reports/ReportSmsSummaryByCarrier";
import ReportSmsSummary from "./Reports/ReportSmsSummary";
import Logout from "./User/logout";
import ReportSmsSummaryByUser from "./Reports/ReportSmsSummaryByUser";
import PartnerEndpoints from "./Customer/endpoints";

const Router = () => (
  <Routes>
    <Route
      exact
      path="/"
      element={
        <UnProtectedRoute>
          <Login />
        </UnProtectedRoute>
      }
    />

    <Route
      element={
        <ProtectedRoute>
          {/* <RolesAuthRoute> */}
          <MainLayout />
          {/* </RolesAuthRoute> */}
        </ProtectedRoute>
      }
    >
      <Route
        exact
        path="/logout"
        element={
          <ProtectedRoute>
            <Logout />
          </ProtectedRoute>
        }
      />
      <Route path="Home" element={<Home />} />
      <Route path="Sms" element={<Sms />} />
      <Route path="Partner/Endpoints" element={<PartnerEndpoints />} />
      <Route path="Campaigns/Campaigns" element={<Campaigns />} />
      <Route path="Campaigns/NewCampaign" element={<NewCampaign />} />
      <Route
        path="Administration/NewCampaignAdmin"
        element={<NewCampaignAdmin />}
      />
      <Route path="Administration/Customers" element={<Customers />} />
      <Route path="Administration/keywords" element={<Keywords />} />
      <Route path="Contacts/ContactsDatabase" element={<PartnerDatabases />} />
      <Route path="Administration/ShortLinks" element={<ShortLinksAdmin />} />
      <Route path="Administration/Campaigns" element={<CampaignsAdmin />} />
      <Route
        path="Configuration/DefaultPrices"
        element={<ListaPreciosDefault />}
      />
      <Route
        path="Administration/shortcodesmasks"
        element={<MaskShortCodes />}
      />
      <Route path="Administration/shortcodes" element={<ShortCodes />} />
      <Route path="Administration/Users" element={<UsersByPartner />} />
      <Route path="Administration/smspackages" element={<SmsPackages />} />
      <Route path="Administration/Staff" element={<OperativeUsers />} />

      <Route path="Db/ContactsDatabase" element={<ContactsDatabase />}></Route>
      <Route
        path="Db/DetailsDb/:id"
        element={<CamapaignsDetailDatabase />}
      ></Route>
      <Route path="Campaign/Details/:id" element={<CampaignDetail />}></Route>

      <Route path="/User" element={<UserInfo />} />
      <Route path="/Security" element={<Security />} />
      <Route path="/ShortLinks" element={<ShortLinks />} />
      <Route path="Customer/Details/:id" element={<CustomerDetail />}></Route>

      <Route path="/Reports/ReportUser" element={<ReportUser />} />
      <Route path="/Reports/ReportDate" element={<ReportDate />} />
      <Route path="/Reports/ReportsUser" element={<ReportFiles />} />
      <Route
        path="/Reports/ReportSmsSmryUser"
        element={<ReportSmsSummaryByUser />}
      />
      <Route
        path="/Reports/ReportSmsSmryCarrier"
        element={<ReportSmsSummaryByCarrier />}
      />
      <Route path="/Reports/ReportSmsSmry" element={<ReportSmsSummary />} />

      <Route path="/Reports/ReportMoDate" element={<ReportMoPartner />} />
      <Route
        path="/AdminReports/campaigns"
        element={<ReportCampaignSmsAdmin />}
      />

      <Route path="/AdminReports/Mo" element={<ReportMoAdmin />} />
      <Route path="/AdminReports/Sms" element={<ReportSmsSummaryAdmin />} />
      <Route
        path="/AdminReports/Smsdetail"
        element={<ReportSmsByDateAdmin />}
      />
      <Route
        path="/AdminReports/Portabilidad"
        element={<ReportPortabilidadAdmin />}
      />
      <Route
        path="/AdminReports/SummaryCarrier"
        element={<ReportCustomerCarrierAdmin />}
      />
    </Route>

    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default Router;
