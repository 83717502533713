import axios from "axios";
import React, { useEffect, useState } from "react";

// Bootstrap
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import Badge from "react-bootstrap/Badge";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { FormatDate, FormatDateWTime } from "../../utils/FormatData"; // Icons
import * as FiIcons from "react-icons/fi";
import { useAuth } from "../../Context/AuthContext";
import { Link } from "react-router-dom";
import { httpClient } from "../../utils/HttpClient";

function WidgetToSend() {
  const Auth = useAuth();
  const [dataReport, setDataReport] = useState(null);

  const getSmsData = () => {
    var userData = Auth.getSessionData();

    const URIReport = ApiEndpoint + "reports/getLastMessages";
    httpClient(
      "POST",
      URIReport,
      {
        idpartner: userData.uid_partner,
        limit: 10,
        user: userData.uid_user,
      },
      userData.token
    )
      .then(function (response) {
        setDataReport(response.data);
        console.log("response");
        console.log(response.data);
        return;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getSmsData();
  }, []);

  return (
    <>
      {dataReport && (
        <div className="MessagesSended">
          <Stack direction="horizontal" gap={2}>
            <h6>Últimos mensajes enviados</h6>
            <div className="ms-auto">
              <FiIcons.FiRefreshCw onClick={() => getSmsData()} />
            </div>
          </Stack>
          <div className="Scroll">
            {dataReport &&
              dataReport.map((sms) => {
                return (
                  <Card>
                    <Card.Body>
                      <Card.Text>
                        <span>
                          {sms.i_messages > 1 ? (
                            <Badge bg="success">Ext</Badge>
                          ) : (
                            <></>
                          )}
                        </span>
                        {sms.j_details.Message}
                      </Card.Text>
                      <Stack direction="horizontal" gap={2}>
                        <div>
                          <span>{sms.vc_msisdn}</span>
                        </div>
                        <div>·</div>
                        <div>
                          <span>
                            {sms.b_ok ? (
                              <Badge bg="success">Entregado</Badge>
                            ) : sms.b_waiting ? (
                              <Badge bg="warning">En Proceso</Badge>
                            ) : sms.b_errorcarrier ? (
                              <Badge bg="danger">Error Operadora</Badge>
                            ): (
                              <Badge bg="danger">No Valido</Badge>
                            )}
                          </span>
                        </div>{" "}
                        <div className="ms-auto">
                          <span>{FormatDateWTime(sms.time_id)}</span>
                        </div>
                      </Stack>
                    </Card.Body>
                  </Card>
                );
              })}
          </div>
          {/* <div className="details">
            <Link to="/Reports/ReportDate">Ver detalles</Link>
          </div> */}
        </div>
      )}
    </>
  );
}

export default WidgetToSend;
