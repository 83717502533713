import React, { useEffect, useState } from "react";
import { Stack, Table } from "react-bootstrap";

const ShortCodesListByPartner = ({ shortCodes }) => {
  return (
    <>
      <div>
        <Stack direction="horizontal" gap={2} className="wrappTitle">
          <h4>Marcaciones Asignadas</h4>
        </Stack>
        <Table striped>
          <thead>
            <tr>
              <th>Marcación</th>
              <th>Mask</th>
              <th>Carrier</th>
            </tr>
          </thead>
          <tbody>
            {shortCodes &&
              shortCodes.map((item) => {
                return (
                  <tr>
                    <td>{item.vc_shortcode}</td>
                    <td>{item.vc_mask ?? "-"}</td>
                    <td>{item.vc_carrier}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ShortCodesListByPartner;
