import React, { useEffect, useState } from "react";
import { Badge, Button, Stack } from "react-bootstrap";
import * as AiIcons from "react-icons/ai";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import axios from "axios";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import EditShortCodeService from "./_EditShortCodeService";
import ModalEdit from "../../components/ModalEdit";
import Confirm from "../../components/Confirm";
import Notification from "../../components/notification";
import { httpClient } from "../../utils/HttpClient";
import { useAuth } from "../../Context/AuthContext";

const ShortCodesListByCustomer = ({ uidCustomer, idCustomer }) => {
  const Auth = useAuth();
  const [shortCodes, setShortCodes] = useState(null);
  const [showEditShortCode, setShowEditShortCode] = useState(null);
  const [modalShowDeleteConfirm, setModalShowDeleteConfirm] = useState(null);
  const [idShortCodeService, setIdShortCodeService] = useState(0);
  const [displayMessage, setDisplayMessage] = useState(null);
  const [message, setMessage] = useState(null);
  const [notificationType, setNotificationType] = useState(null);
  var userData = Auth.getSessionData();

  const handleCloseNotificacion = () => {
    setDisplayMessage(false);
  };

  const unassignShortCodePartner = () => {
    //TODO
    if (idShortCodeService < 1) return;
    const URI = ApiEndpoint + "administration/unasignhortcode/";

    httpClient("DELETE", URI + idShortCodeService, {}, userData.token)
      .then(function (response) {
        if (response.data.error) {
          setMessage(response.data.message);
          setDisplayMessage(true);
          setNotificationType("warning");
          setModalShowDeleteConfirm(false);
          return;
        }
        setMessage("Registro eliminado exitosamente");
        setDisplayMessage(true);
        setNotificationType("");
        setModalShowDeleteConfirm(false);
        getShortCodes();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleCloseNewShortCode = () => {
    setShowEditShortCode(false);
    getShortCodes();
  };
  const getShortCodes = () => {
    const URI = ApiEndpoint + "administration/shortcodesbycustomer/";
    httpClient("GET", URI + uidCustomer, {}, userData.token).then(
      (response) => {
        setShortCodes(response.data);
      }
    );
  };

  useEffect(() => {
    getShortCodes();
  }, []);

  const columns = [
    {
      accessor: "vc_partner",
      Header: "Partner",
    },
    {
      accessor: "vc_carrier",
      Header: "Carrier",
    },
    {
      accessor: "vc_shortcode",
      Header: "Marcacion",
    },
    {
      accessor: "vc_mask",
      Header: "Mascara",
    },
    {
      accessor: "b_default",
      Header: "Predeterminada",
      Cell: (rowData) => {
        let active = rowData.row.original.b_default;
        return (
          <>
            {active ? (
              <Badge bg="primary">Default</Badge>
            ) : (
              <Badge bg="secondary"></Badge>
            )}
          </>
        );
      },
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let idToEdit = rowData.row.original.id_shortcodeservice;
        return (
          <>
            <AiIcons.AiFillDelete
              className="IconTable"
              title="Eliminar Registro"
              onClick={() => {
                setIdShortCodeService(idToEdit);
                setModalShowDeleteConfirm(true);
              }}
            />
          </>
        );
      },
    },
  ];
  return (
    <>
      <div>
        <Stack direction="horizontal" gap={2} className="wrappTitle">
          <h4>Lista de Marcaciones Asignadas</h4>
          <div className="ms-auto">
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => {
                setShowEditShortCode(true);
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>{" "}
          </div>
        </Stack>
        {shortCodes && (
          <AdvanceTableWrapper
            columns={columns}
            data={shortCodes}
            sortable
            pagination
            perPage={10}
          >
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            <AdvanceTablePagination table />
            <AdvanceTableFooter
              rowCount={shortCodes.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
              pageSize={10}
            />
          </AdvanceTableWrapper>
        )}
      </div>
      <ModalEdit
        show={showEditShortCode}
        handleClose={handleCloseNewShortCode}
        title={"Detalle de Marcaciones Asignadas"}
        size="lg"
      >
        <EditShortCodeService
          refreshData={handleCloseNewShortCode}
          idCustomer={idCustomer}
          handleClose={() => {
            getShortCodes();
          }}
        ></EditShortCodeService>
      </ModalEdit>
      <Confirm
        show={modalShowDeleteConfirm}
        handleClose={() => {
          setModalShowDeleteConfirm(false);
        }}
        handleConfirm={() => {
          unassignShortCodePartner();
        }}
        message={"Desea quitar la marcacion seleccionada?"}
      />
      {displayMessage && (
        <Notification
          show={displayMessage}
          setShow={() => {
            setDisplayMessage(false);
          }}
          message={message}
          alertType={notificationType}
        />
      )}
    </>
  );
};

export default ShortCodesListByCustomer;
