import React, { useState } from "react";
import { MissInfoReport } from "../../components/MissInfo";
import ReportsByDateFilters from "./ReportFilters";
import { Stack } from "react-bootstrap";
import ReportMoSmsList from "./_ReportMoSmsList";
import styled from "styled-components";
import ReportMoFilters from "./_ReportMoFilters";
const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ReportMoPartner = () => {
  const [reportData, setReportData] = useState(null);
  const [periodStr, setPeriodStr] = useState("");
  return (
    <>
      <div className="">
        <Stack direction="horizontal" gap={2}>
          <div>
            <h2>Mensajes recibidos por fecha</h2>
            <h3>Consulte los mensajes recibidos con base en la fecha.</h3>
          </div>
        </Stack>

        <div className="ReportDate">
          <ReportMoFilters
            setReportData={setReportData}
            setPeriodStr={setPeriodStr}
          />

          {/*} <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Filtros</Accordion.Header>
                <Accordion.Body>
                  
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
        </div>

        <Divider />

        {/* <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="DbDetails2">
                <Stack direction="horizontal" gap={2}>
                  <AiIcons.AiOutlineCalendar />
                  <div className="ms-auto">
                    <p>{periodStr}</p>
                  </div>
                </Stack>
                <div className="details">Rango de fechas</div>
              </div>
            </Col>
          </Row> */}
        {reportData ? (
          <ReportMoSmsList
            periodStr={periodStr}
            listMessages={reportData}
          ></ReportMoSmsList>
        ) : (
          <MissInfoReport />
        )}
      </div>
    </>
  );
};

export default ReportMoPartner;
