import React, { useEffect, useState } from "react";
import { FormatDate, FormatInt } from "../../utils/FormatData";
import { Badge, Button, Col, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import AdvanceTableSearchBox from "../../components/AdvanceTableSearchBox";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import ModalEdit from "../../components/ModalEdit";
import CampaignEdit from "../../components/CampaignEdit";
import * as AiIcons from "react-icons/ai";
import { useAuth } from "../../Context/AuthContext";
import { httpClient } from "../../utils/HttpClient";

const CampaignsAdmin = () => {
  const Auth = useAuth();
  const [show, setShow] = useState(false);
  const [listCampaigns, setListCampaigns] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  var userData = Auth.getSessionData();
  const columns = [
    {
      accessor: "vc_partnername",
      Header: "Partner",
    },
    {
      accessor: "vc_campaignname",
      Header: "Nombre",
    },
    {
      accessor: "vc_databasename",
      Header: "Grupo de Contactos",
    },
    {
      accessor: "vc_mask",
      Header: "Mascara",
    },
    {
      accessor: "i_records",
      Header: "Contactos",
      Cell: (rowData) => {
        let rowInfo = rowData.row.original;
        return (
          <Col style={{ textAlign: "center" }}>
            {FormatInt(rowInfo.i_records)}
          </Col>
        );
      },
    },
    {
      accessor: "create",
      Header: "Ult. Mensaje",
      Cell: (rowData) => {
        let create = rowData.row.original.tst_create;
        return <>{FormatDate(create)}</>;
      },
    },
    {
      accessor: "i_programmed",
      Header: "Progr.",
      Cell: (rowData) => {
        let rowInfo = rowData.row.original;
        return (
          <Col style={{ textAlign: "center" }}>{rowInfo.i_programmed}</Col>
        );
      },
    },
    {
      accessor: "i_process",
      Header: "En Proceso",
      Cell: (rowData) => {
        let rowInfo = rowData.row.original;
        return <Col style={{ textAlign: "center" }}>{rowInfo.i_process}</Col>;
      },
    },
    {
      accessor: "i_finished",
      Header: "Enviados",
      Cell: (rowData) => {
        let rowInfo = rowData.row.original;
        return <Col style={{ textAlign: "center" }}>{rowInfo.i_finished}</Col>;
      },
    },
    {
      accessor: "i_canceled",
      Header: "Canc.",
      Cell: (rowData) => {
        let rowInfo = rowData.row.original;
        return <Col style={{ textAlign: "center" }}>{rowInfo.i_canceled}</Col>;
      },
    },
    {
      accessor: "i_total",
      Header: "Mensajes",
      Cell: (rowData) => {
        let rowInfo = rowData.row.original;
        return <Col style={{ textAlign: "center" }}>{rowInfo.i_total}</Col>;
      },
    },
    {
      accessor: "i_canceled1",
      Header: "Estatus",
      Cell: (rowData) => {
        let rowDataItem = rowData.row.original;
        return (
          <div style={{ textAlign: "right" }}>
            {rowDataItem.i_canceled == rowDataItem.i_total ? (
              <Badge bg="dark">Inactivo</Badge>
            ) : (
              ""
            )}
            {rowDataItem.i_programmed > 0 ? (
              <Badge bg="info">Programada</Badge>
            ) : rowDataItem.i_process > 0 ? (
              <Badge bg="warning">En Proceso</Badge>
            ) : (
              ""
            )}

            {rowDataItem.i_finished == rowDataItem.i_total ? (
              <Badge bg="success">Concluida</Badge>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let uiddatabase = rowData.row.original.uid_campaigns;
        return (
          <>
            <Link to={"/Campaign/Details/" + uiddatabase}>
              <AiIcons.AiOutlineSearch
                className="IconTable"
                title="Detalles"
                onClick={""}
              />
            </Link>
          </>
        );
      },
    },
  ];

  const getListCampaigns = () => {
    const URI = ApiEndpoint + "configuration/getCampaigns/";
    httpClient("GET", URI, {}, userData.token).then((response) => {
      setListCampaigns(response.data);
    });
  };

  useEffect(() => {
    getListCampaigns();
  }, []);

  return (
    <>
      <div className="">
        <Stack direction="horizontal" gap={2}>
          <div>
            <h2>Campañas</h2>
            <h3>Administre los grupos de campañas.</h3>
          </div>
          <div className="ms-auto">
            <Link to="/Administration/NewCampaignAdmin">
              <Button variant="outline-primary" size="sm">
                <AiIcons.AiOutlinePlus /> Agregar
              </Button>{" "}
            </Link>{" "}
          </div>
        </Stack>

        {listCampaigns && (
          <AdvanceTableWrapper
            columns={columns}
            data={listCampaigns}
            sortable
            pagination
            perPage={10}
          >
            <div className="SearchBar">
              <Stack direction="horizontal" gap={3}>
                <AdvanceTableSearchBox table />
              </Stack>
            </div>
            <h4>Lista de Campañas</h4>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            <AdvanceTableFooter
              rowCount={listCampaigns.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
              pageSize={10}
            />
          </AdvanceTableWrapper>
        )}
      </div>
      <ModalEdit
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        title="Editando Campaña"
      >
        <CampaignEdit />
      </ModalEdit>
    </>
  );
};

export default CampaignsAdmin;
