import React, { useState } from "react";
import styled from "styled-components";

// Bootstrap
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useAuth } from "../../Context/AuthContext";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import Notification from "../../components/notification";

// Assets
import Avatar from "../../assets/avatar.jpg";
import { httpClient } from "../../utils/HttpClient";

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 0px;
  margin-bottom: 20px;
`;

const UserInfo = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [validated, setValidated] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [messageNotification, setMessageNotification] =
    useState("Campaña creada");
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    if (values.password != values.confirmPassword) {
      setMessageNotification("Las contraseñas no coinciden");
      setNotificationType("warning");
      setShowNotification(true);
      return;
    }
    var formatregex =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;
    if (formatregex.test(values.password)) {
    } else {
      setMessageNotification("Las contraseña no cumple el formato requerido");
      setNotificationType("warning");
      setShowNotification(true);
      return;
    }

    var userDataFromSess = Auth.getSessionData();
    setValidated(true);
    const URIUpdate = ApiEndpoint + "users/updPass";

    httpClient(
      "PUT",
      URIUpdate,
      {
        uiduser: userDataFromSess.uid_user,
        password: values.password,
      },
      userData.token
    )
      .then(function (response) {
        if (!response.error) {
          setMessageNotification(
            "Datos Actualizados los cambios se reflejaran en su proximo inicio de sesion"
          );
          setNotificationType("");
          setShowNotification(true);
        } else {
          setMessageNotification("Ha ocurrido un error");
          setNotificationType("warning");
          setShowNotification(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <div className="bodyPage">
        <Stack direction="horizontal" gap={2}>
          <div>
            <h2>Seguridad</h2>
          </div>
          <div className="ms-auto"></div>
        </Stack>

        <div
          className="UserPicBig"
          style={{ background: `url(${Avatar})` }}
        ></div>
        <Divider className="DividerUserPic" />

        <Form noValidate validated={validated} onSubmit={handleSubmit1}>
          <FloatingLabel label="Nueva contraseña:" className="mb-3">
            <Form.Control
              type="password"
              name="password"
              id="password"
              required
              onChange={(event) => {
                onChange(event);
              }}
              placeholder="Nueva contraseña:"
            />
          </FloatingLabel>
          <FloatingLabel label="Confirmar contraseña:" className="mb-3">
            <Form.Control
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              required
              onChange={(event) => {
                onChange(event);
              }}
              placeholder="Confirmar contraseña:"
            />
          </FloatingLabel>
          <h5
            style={{
              marginTop: "0px",
              marginBottom: "10px",
              borderRadius: "5px",
              padding: "5px",
              background: "#6d757d",
              color: "#fff",
              width: "fit-content",
              fontWeight: "400",
              fontSize: "12px",
            }}
          >
            La contraseña debe tener una longitud minima de 8 caracteres y al
            menos debe contener al menos un numero, una letra minuscula, una
            letra mayuscula, y un caracter especial
          </h5>
          <div className="text-right">
            <Button variant="primary" type="submit" className="align-self-end">
              Actualizar
            </Button>
          </div>
        </Form>
      </div>
      <Notification
        alertType={notificationType}
        show={showNotification}
        setShow={setShowNotification}
        message={messageNotification}
      />
    </>
  );
};

export default UserInfo;
