import GlobalNotification from "../components/GlobalNotification";
import { createRoot } from "react-dom/client";

export const getStatus = (idStatus) => {
  return idStatus == 1
    ? "PROGRAMADO"
    : idStatus == 2
    ? "EN PROCESO"
    : idStatus == 3
    ? "CONCLUIDO"
    : "CANCELADO";
};

export const currentVersion = "2023.01";
