import React from "react";

export const MissInfo = () => {
  return (
    <>
      <h6 className="MissInfo">Aún no hay información</h6>
    </>
  );
};

export const MissInfoMessage = () => {
  return (
    <>
      <h6 className="MissInfo">Aún no hay información de este mensaje</h6>
    </>
  );
};

export const MissInfoReport = () => {
  return (
    <>
      <h6 className="MissInfo">
        Realiza una consulta para desplegar la información
      </h6>
    </>
  );
};

export const MissInfoNoPermission = () => {
  return (
    <>
      <h6 className="MissInfo">
        No existen registros o no tiene permisos para acceder a ellos
      </h6>
    </>
  );
};

export default MissInfo;
