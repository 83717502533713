import React, { useEffect, useState } from "react";
import Notification from "../../components/notification";
import { Alert, Button, FloatingLabel, Form, Tab, Tabs } from "react-bootstrap";
import { Badge, Col, Row } from "react-bootstrap";
import { useAuth } from "../../Context/AuthContext";
import styled from "styled-components";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import SmsTextBox from "../../components/generic/_SmsTextBox";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { httpClient } from "../../utils/HttpClient";
const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

//var today = moment(new Date()).format("YYYY/MM/DD hh:mm");

var today = moment(new Date()).format().split("T")[0];
const NewCampaignAdmin = () => {
  const Auth = useAuth();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showPrev, setShowPrev] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [listShortLinks, setListShortLinks] = useState(false);
  const [messageText, setMessageText] = useState(false);
  const [listDatabases, setListDatabases] = useState(null);
  const [notificationType, setNotificationType] = useState("");
  const [customersList, setCustomersList] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(false);
  const [partnerList, setPartnerList] = useState(false);
  const [fieldsInfo, setFieldsInfo] = useState(false);
  const [masksList, setMasksList] = useState(null);

  var userData = Auth.getSessionData();
  const [messageNotification, setMessageNotification] =
    useState("Campaña creada");
  const [values, setValues] = useState({
    campaingName: "",
    uiddatabase: "",
    message: "",
    dateSend: "",
    msisdn: "",
    uid_partner: "",
    template: "",
    mask: "",
  });

  const getListDatabases = (uidpartner) => {
    const URI = ApiEndpoint + "configuration/getAdminDatabases";
    httpClient("GET", URI, {}, userData.token).then((response) => {
      setListDatabases(response.data);
    });
  };

  const getPartnersList = (idCustomer) => {
    const URIPartners = ApiEndpoint + "partners/";
    httpClient("GET", URIPartners + idCustomer, {}, userData.token).then(
      (response) => {
        setPartnerList(response.data);
      }
    );
  };

  const getCustomersList = () => {
    const URICustomers = ApiEndpoint + "customers";
    httpClient("GET", URICustomers, {}, userData.token).then((response) => {
      setCustomersList(response.data);
    });
  };

  const getShortLinks = (selectedPartner) => {
    const URI = ApiEndpoint + "campaigns/getShortLinks/" + selectedPartner;
    httpClient("GET", URI, {}, userData.token).then((response) => {
      setListShortLinks(response.data.filter((x) => x.active));
    });
  };

  useEffect(() => {
    if (selectedPartner) getListDatabases(selectedPartner);
  }, [selectedPartner]);

  useEffect(() => {
    if (selectedCustomer > 0) {
      getPartnersList(selectedCustomer);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (selectedPartner) {
      getListDatabases(selectedPartner);
      getShortLinks(selectedPartner);
      getAvMask();
    }
  }, [selectedPartner]);

  const getAvMask = () => {
    const URI = ApiEndpoint + "campaigns/masks/";
    httpClient("GET", URI + `${selectedPartner}`, {}, userData.token).then(
      (response) => {
        setMasksList(response.data);
      }
    );
  };

  useEffect(() => {
    getCustomersList();
  }, []);

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    var userData = Auth.getSessionData();
    setValidated(true);
    let URICustomer = ApiEndpoint + "campaigns/createCampaign";
    if (isScheduled) {
      URICustomer = ApiEndpoint + "campaigns/programCampaign";
    }
    var sendDate = new Date();

    // getListDatabases(va.uid_partner);

    httpClient(
      "POST",
      URICustomer,
      {
        name: values.campaingName,
        uiddatabase: values.uiddatabase,
        partner: `${values.uid_partner}`,
        message: values.message,
        send: values.dateSend,
        hour: isScheduled ? values.proghour.split(":")[0] : 0,
        minute: isScheduled ? values.proghour.split(":")[1] : 0,
        uidUser: userData.uid_user,
        mask: values.mask,
      },
      userData.token
    )
      .then(function (response) {
        console.log(response);
        if (response.data.error) {
          setMessageNotification("Ha ocurrido un error");
          setNotificationType("warning");
          setShow(true);
        } else {
          setMessageNotification("Campaña creada");
          setNotificationType("");
          setShow(true);
          navigate("/Administration/Campaigns");
        }
        return;
      })
      .catch(function (error) {
        setMessageNotification("Ha ocurrido un error");
        setNotificationType("warning");
        setShow(true);
        console.log(error);
      });
  };

  const getXtraFieldsInfo = (uidDatabase) => {
    setFieldsInfo(null);
    const URIFields = ApiEndpoint + "campaigns/fieldsdatabase/";
    httpClient("GET", URIFields + uidDatabase, {}, userData.token).then(
      (response) => {
        setFieldsInfo(response.data);
      }
    );
  };

  useEffect(() => {
    setFieldsInfo(null);
    if (!values.uiddatabase) return;
    getXtraFieldsInfo(values.uiddatabase);
  }, [values.uiddatabase]);

  useEffect(() => {
    if (!fieldsInfo) {
      setShowPrev(true);
      return;
    }
    let messageWithTemplate = values.message;
    var pattern = RegExp("{(.*?)}", "g");
    let result;
    while ((result = pattern.exec(messageWithTemplate))) {
      // Do something with result[0].
      console.log(result[0]);
      try {
        var currentK = fieldsInfo.filter(
          (item) => "{" + item.vc_keycolumn + "}" == result[0]
        );
        console.log(currentK);
        messageWithTemplate = messageWithTemplate.replace(
          result[0],
          currentK[0].vc_value
        );
      } catch (error) {}
    }
    setValues({
      ...values,
      ["template"]: messageWithTemplate.replaceAll("{", "").replaceAll("}", ""),
    });
  }, [values.message]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <div className="">
        <Form noValidate validated={validated} onSubmit={handleSubmit1}>
          <h2>Nueva campaña</h2>
          <h3>1. Datos generales</h3>

          <FloatingLabel label="Nombre de la campaña *" className="mb-3">
            <Form.Control
              type="text"
              name="campaingName"
              id="campaingName"
              value={values.campaingName}
              required
              onChange={(event) => {
                onChange(event);
              }}
              placeholder="Nombre de la campaña *"
            />
          </FloatingLabel>
          <FloatingLabel label="Cliente *" className="mb-3">
            <Form.Select
              id="idcliente"
              name="idcliente"
              onChange={(selectedOption) => {
                setSelectedCustomer(`${selectedOption.target.value}`);
              }}
              value={selectedCustomer}
              required
              aria-label="select_customer"
            >
              <option value="">Seleccione una opcion de la lista</option>
              {!!customersList?.length &&
                customersList.map((customer) => (
                  <option value={customer.idcustomer}>
                    {customer.comercialname}
                  </option>
                ))}
            </Form.Select>
          </FloatingLabel>

          <FloatingLabel label="Partner *" className="mb-3">
            <Form.Select
              id="uid_partner"
              name="uid_partner"
              onChange={(selectedOption) => {
                onChange(selectedOption);
                setSelectedPartner(`${selectedOption.target.value}`);
              }}
              value={values.uid_partner}
              required
              aria-label="select_carrier"
            >
              <option value="">Seleccione una opcion de la lista</option>
              {!!partnerList?.length &&
                partnerList.map((partner) => (
                  <option value={partner.uidpartner}>{partner.name}</option>
                ))}
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel label="Base de datos *" className="mb-3">
            <Form.Select
              id="uiddatabase"
              name="uiddatabase"
              value={values.uiddatabase}
              required
              aria-label="asd"
              onChange={(event) => {
                onChange(event);
              }}
            >
              <option value="">Seleccione una opcion de la lista</option>
              {!!listDatabases?.length &&
                listDatabases.map((item) => (
                  <option value={item.uiddatabase}>{item.name}</option>
                ))}
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel label="Mascara (opcional)" className="mb-3">
            <Form.Select
              id="mask"
              name="mask"
              onChange={(selectedOption) => {
                onChange(selectedOption);
              }}
              value={values.mask}
              aria-label="select_mask"
            >
              <option value="">Predeterminada</option>
              {!!masksList?.length &&
                masksList.map((mask) => (
                  <option value={mask.vc_mask}>{mask.vc_mask}</option>
                ))}
            </Form.Select>
          </FloatingLabel>
          <Divider />
          <h3>2. Redacte el mensaje</h3>
          <Row>
            <Col sm={8}>
              <SmsTextBox
                name="message"
                defaultValue={values.message}
                changeHandler={onChange}
              ></SmsTextBox>
              {values.template && showPrev && (
                <>
                  <h5 className="PrevTitleModal">Previsualización:</h5>
                  <div class="contenedo_msj">
                    <div class="mensaje-izq">
                      <div class="cuerpo_msj">
                        <div class="msj-texto">{values.template}</div>
                      </div>
                    </div>
                    <Badge
                      className="mt-2"
                      style={{ minWidth: "143px" }}
                      bg="secondary"
                    >
                      Max. Caracteres: {values.template.length}
                    </Badge>
                  </div>
                  <hr />
                </>
              )}
            </Col>
            <Col sm={4}>
              <Tabs
                defaultActiveKey="ShortLinks"
                id="fill-tab-example"
                className="tabsBottom"
                fill
              >
                <Tab eventKey="ShortLinks" title="Urls Cortas">
                  <div className="ShortLink">
                    {/* <h3>Urls Cortas</h3> */}
                    <h5 style={{ marginBottom: "10px" }}>
                      Al darle click se agregará la url corta relacionada con el
                      alias que aparece a continuación:
                    </h5>
                    <div className="AllLinks">
                      <>
                        {listShortLinks &&
                          listShortLinks.map((shortlink) => {
                            return (
                              <>
                                {" "}
                                <Badge
                                  onClick={() => {
                                    setMessageText(shortlink.shortlinks);
                                    setValues({
                                      ...values,
                                      message:
                                        values.message +
                                        " " +
                                        shortlink.shortlinks +
                                        " ",
                                      template:
                                        values.template +
                                        " " +
                                        shortlink.shortlinks +
                                        " ",
                                    });
                                  }}
                                  bg="primary"
                                >
                                  {shortlink.displayname}
                                </Badge>{" "}
                              </>
                            );
                          })}
                      </>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="AddFields" title="Template">
                  {fieldsInfo ? (
                    <div className="ShortLink">
                      {/* <h3>Campos Adicionales</h3> */}
                      <h5 style={{ marginBottom: "10px" }}>
                        Al darle click se agregará el campo relacionado con el
                        alias que aparece a continuación:
                      </h5>
                      <div className="AllLinks">
                        <>
                          {fieldsInfo &&
                            fieldsInfo.map((field) => {
                              return (
                                <>
                                  {" "}
                                  <Badge
                                    onClick={() => {
                                      setMessageText(field.vc_keycolumn);
                                      setValues({
                                        ...values,
                                        message:
                                          values.message +
                                          " {" +
                                          field.vc_keycolumn +
                                          "} ",
                                        template:
                                          values.template +
                                          " {" +
                                          field.vc_value +
                                          "} ",
                                      });
                                    }}
                                    bg="primary"
                                  >
                                    {field.vc_keycolumn}
                                  </Badge>
                                </>
                              );
                            })}
                        </>
                      </div>
                    </div>
                  ) : (
                    <div className="ShortLink">
                      <p
                        style={{
                          textAlign: "center",
                          fontWeight: "400",
                          color: "rgb(106, 106, 106)",
                          fontSize: "14px",
                        }}
                      >
                        Selecciona una <b>base de datos</b> para visualizar los
                        campos adicionales que contiene.
                      </p>
                    </div>
                  )}
                </Tab>
              </Tabs>
            </Col>
          </Row>

          <Divider />
          <h3>3. Programe la campaña (opcional)</h3>
          <h5>
            En caso de no seleccionar esta opción tu campaña se procesará de
            inmediato.
          </h5>

          <FloatingLabel label="" className="mb-3">
            <Form.Check
              type="switch"
              id="isScheduled"
              value={isScheduled}
              label="¿Desas programar la campaña?"
              onChange={(event) => {
                setIsScheduled(!isScheduled);
              }}
              className="prevent-validation"
            />
          </FloatingLabel>
          {isScheduled && (
            <>
              {isScheduled ? (
                <>
                  <FloatingLabel label="Fecha de envío:" className="mb-3">
                    <Form.Control
                      type="date"
                      name="dateSend"
                      id="dateSend"
                      placeholder="Programar"
                      min={today}
                      onChange={(event) => {
                        onChange(event);
                      }}
                      required
                    />
                  </FloatingLabel>
                  <FloatingLabel label="Hora de envío:" className="mb-3">
                    <Form.Control
                      type="time"
                      name="proghour"
                      id="proghour"
                      placeholder="Programar"
                      onChange={(event) => {
                        onChange(event);
                      }}
                      required
                    />
                  </FloatingLabel>
                  <Alert
                    key={"secondary"}
                    variant={"secondary"}
                    className="Notice"
                  >
                    <b>Nota:</b> Si la hora seleccionada ya transcurrió la
                    campaña se ejecutará inmediatamente.
                  </Alert>
                </>
              ) : (
                <>
                  <FloatingLabel label="Fecha de envío:" className="mb-3">
                    <Form.Control
                      type="date"
                      name=""
                      id=""
                      placeholder="Programar"
                      min={today}
                    />
                  </FloatingLabel>
                  <FloatingLabel label="Hora de envío:" className="mb-3">
                    <Form.Control
                      type="time"
                      name=""
                      id=""
                      placeholder="Programar"
                    />
                  </FloatingLabel>
                  <Alert
                    key={"secondary"}
                    variant={"secondary"}
                    className="Notice"
                  >
                    <b>Nota:</b> Si la hora seleccionada ya transcurrió la
                    campaña se ejecutará inmediatamente.
                  </Alert>
                </>
              )}
            </>
          )}

          <div className="text-right">
            <Button variant="primary" type="submit" className="align-self-end">
              Enviar
            </Button>
          </div>
        </Form>
      </div>

      <Notification
        alertType={notificationType}
        show={show}
        setShow={setShow}
        message={messageNotification}
      />
    </>
  );
};

export default NewCampaignAdmin;
