import React from 'react';

// Bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Message({ show, handleClose, message, handleConfirm, }) {

    return (
        <>
        <Modal
        show={show}
        onHide={handleClose}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Importante
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {message}
        </Modal.Body>
        <Modal.Footer>
            <Button
            variant="primary"
            size='sm'
            onClick={handleClose}>
                Aceptar
            </Button>
        </Modal.Footer>
        </Modal>
        </>
    );
}

export default Message;