import React, { useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { httpClient } from "../../utils/HttpClient";
import { useAuth } from "../../Context/AuthContext";

const CreateCustomerPrice = ({
  handleClose,
  idCustomer,
  uidCustomer,
  setMessage,
  setDisplayMessage,
  setNotificationType,
}) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({
    limitMin: "",
    limitMax: "",
    unitPrice: "0",
  });
  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    if (values.unitPrice <= 0) {
      setMessage("El precio debe ser mayor a $0");
      setDisplayMessage(true);
      setNotificationType("warning");
      return;
    }
    setValidated(true);
    const URICustomer = ApiEndpoint + "configuration/savepricesbycustomer";

    httpClient(
      "POST",
      URICustomer,
      {
        min: values.limitMin,
        max: values.limitMax,
        unitprice: values.unitPrice,
        idcustomer: uidCustomer,
      },
      userData.token
    )
      .then(function (response) {
        console.log(response);
        if (!response.data.error) {
          handleClose();
          return;
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit1}>
        <FloatingLabel label="Rango Minimo" className="mb-3">
          <Form.Control
            type="text"
            name="limitMin"
            id="limitMin"
            value={values.limitMin}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el rango minimo"
          />
        </FloatingLabel>
        <FloatingLabel label="Rango Maximo" className="mb-3">
          <Form.Control
            type="text"
            name="limitMax"
            id="limitMax"
            value={values.limitMax}
            required
            maxLength={10}
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese un Rango Maximo"
          />
        </FloatingLabel>
        <FloatingLabel label="Precio Unitario" className="mb-3">
          <Form.Control
            type="numeric"
            name="unitPrice"
            id="unitPrice"
            value={values.unitPrice}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el Precio"
          />
        </FloatingLabel>
        <div className="text-right">
          <Button variant="primary" type="submit" className="align-self-end">
            {"Guardar"}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CreateCustomerPrice;
