import React, { useEffect, useState } from "react";
import { Badge, Stack } from "react-bootstrap";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import AdvanceTableSearchBox from "../../components/AdvanceTableSearchBox";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
// Icons
import * as AiIcons from "react-icons/ai";
import { useAuth } from "../../Context/AuthContext";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { httpClient } from "../../utils/HttpClient";
import ModalEdit from "../../components/ModalEdit";
import CreateEndPoint from "./_CreateEndPoint";
import Confirm from "../../components/Confirm";

const PartnerEndpoints = () => {
  const Auth = useAuth();

  const [partnerEndpoints, setPartnerEndpoints] = useState(null);
  const [idPartnerEndpoint, setIdPartnerEndpoint] = useState(null);
  const [idToDeletePartnerEndpoint, setIdToDeletePartnerEndpoint] =
    useState(null);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isMo, setIsMo] = useState(false);
  const handleClose = () => {
    getPartnerEndpoints();
    setShow(false);
    setShowConfirmDelete(false);
  };

  const handleConfirm = () => {
    deleteEndpoint();
    setShowConfirmDelete(false);
  };

  const deleteEndpoint = (event) => {
    var userData = Auth.getSessionData();

    var userData = Auth.getSessionData();
    if (idToDeletePartnerEndpoint > 0) {
      httpClient(
        "DELETE",
        ApiEndpoint +
          "partner/destroyEndpointPartner/" +
          idToDeletePartnerEndpoint,
        {},
        userData.token
      )
        .then(function (response) {
          console.log(response);
          handleClose();
          return;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const columns = [
    {
      accessor: "endpoint",
      Header: "EndPoint",
    },
    {
      accessor: "method",
      Header: "Metodo",
    },
    {
      accessor: "isresult",
      Header: "Notificar",
      Cell: (rowData) => {
        let isResult = rowData.row.original.isresult;
        let ismo = rowData.row.original.ismo;
        return (
          <>
            {isResult ? <Badge bg="primary">Confirmar Entrega</Badge> : <></>}{" "}
            {ismo ? <Badge bg="secondary">Recepcion MO</Badge> : <></>}
          </>
        );
      },
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let id = rowData.row.original.idpartnerendpoint;
        let ismo = rowData.row.original.ismo;
        return (
          <>
            <AiIcons.AiFillEdit
              className="IconTable"
              title="Modificar"
              onClick={() => {
                setIdPartnerEndpoint(id);
                setIsMo(ismo);
                setShow(true);
              }}
            />

            {id > 0 && (
              <>
                |
                <AiIcons.AiFillDelete
                  className="IconTable"
                  title="Eliminar"
                  onClick={() => {
                    setIdToDeletePartnerEndpoint(id);
                    setShowConfirmDelete(true);
                  }}
                />
              </>
            )}
          </>
        );
      },
    },
  ];

  const getPartnerEndpoints = () => {
    var userData = Auth.getSessionData();
    const URI =
      ApiEndpoint + "partner/partnerendpoints/" + userData.uid_partner;
    httpClient("GET", URI, {}, userData.token).then((response) => {
      var resultEndpoint = response.data.filter((item) => item.isresult);
      var moEndpoint = response.data.filter((item) => item.ismo);

      let endpoints = [];
      if (resultEndpoint.length > 0) {
        endpoints.push(resultEndpoint[0]);
      } else {
        endpoints.push({
          idpartnerendpoint: 0,
          endpoint: "No configurado",
          method: "-",
          data: "",
          headers: "",
          isresult: false,
          ismo: false,
        });
      }
      if (moEndpoint.length > 0) {
        endpoints.push(moEndpoint[0]);
      } else {
        endpoints.push({
          idpartnerendpoint: 0,
          endpoint: "No configurado",
          method: "-",
          data: "",
          headers: "",
          isresult: false,
          ismo: true,
        });
      }

      setPartnerEndpoints(endpoints);
    });
  };

  useEffect(() => {
    getPartnerEndpoints();
  }, []);
  return (
    <>
      <div className="">
        <Stack direction="horizontal" gap={2}>
          <div>
            <h2>Urls de Notificacion</h2>
            <h3>Administre las urls de notificacion</h3>
          </div>
          <div className="ms-auto"></div>
        </Stack>

        {partnerEndpoints && (
          <AdvanceTableWrapper
            columns={columns}
            data={partnerEndpoints}
            sortable
            pagination
            perPage={10}
          >
            <div className="SearchBar">
              <Stack direction="horizontal" gap={3}>
                <AdvanceTableSearchBox table />
              </Stack>
            </div>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            <AdvanceTablePagination table />
            <AdvanceTableFooter
              rowCount={partnerEndpoints.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
              pageSize={10}
            />
          </AdvanceTableWrapper>
        )}
      </div>
      <ModalEdit
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        title="Agregar Endpoint"
      >
        <CreateEndPoint
          idpartnerendpoint={idPartnerEndpoint}
          isMo={isMo}
          handleClose={handleClose}
        ></CreateEndPoint>
      </ModalEdit>
      <Confirm
        show={showConfirmDelete}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        message={"¿Desea eliminar esta URL de Notificacion?"}
      />
    </>
  );
};

export default PartnerEndpoints;
