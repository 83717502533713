import React, { useEffect, useState } from "react";
import { Alert, Badge, Card, Col, ListGroup, Row } from "react-bootstrap";

const ListDatabase = ({
  dataitems,
  fieldsFromFile,
  invalidItemsCount,
  invalidItems,
}) => {
  return (
    <>
      <Alert key={"secondary"} variant={"secondary"} className="Notice">
        El archivo contiene los siguientes campos adicionales:
        {fieldsFromFile}
      </Alert>
      {dataitems && (
        <Card
          style={{
            maxHeight: "300px",
            overflow: "scroll",
          }}
        >
          <Card.Header>
            <Row>
              <Col>
                Registros
                {/* {fieldsFromFile[0]} */}
              </Col>
              <Col style={{ textAlign: "right" }}>
                <Badge bg="secondary">{dataitems.length}</Badge>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <ListGroup variant="flush" as="ol" numbered>
              {/* <>
                <ListGroup.Item as="li">
                  {fieldsFromFile &&
                    fieldsFromFile.map((item) => {
                      return <> {item} </>;
                    })}
                </ListGroup.Item>
              </> */}

              {dataitems &&
                dataitems.map((item) => {
                  return <ListGroup.Item>{item.msisdn}</ListGroup.Item>;
                })}
            </ListGroup>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col>Invalidos</Col>
              <Col style={{ textAlign: "right" }}>
                <Badge bg="warning">{invalidItemsCount}</Badge>
              </Col>
            </Row>
            <Row>
              <ListGroup variant="flush">
                {invalidItems &&
                  invalidItems.map((item) => {
                    return <ListGroup.Item>{item.msisdn}</ListGroup.Item>;
                  })}
              </ListGroup>
            </Row>
          </Card.Footer>
        </Card>
      )}
    </>
  );
};

export default ListDatabase;
