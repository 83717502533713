import React, { useState } from "react";
import styled from "styled-components";
import { Stack } from "react-bootstrap";
import { MissInfoReport } from "../../components/MissInfo";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import GenericAdminFilters from "./_GenericAdminFilters";
import AdminReportMoList from "./_AdminReportMoList";
import { useAuth } from "../../Context/AuthContext";
import { httpClient } from "../../utils/HttpClient";
const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ReportMoAdmin = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [reportData, setReportData] = useState(null);
  const [periodStr, setPeriodStr] = useState("");
  const [loading, setLoading] = useState(false);

  const getDataReport = (filters) => {
    setReportData(null);
    setLoading(true);
    const URI = ApiEndpoint + "administration/report/momessages";

    httpClient(
      "POST",
      URI,
      {
        from: filters.dateFrom,
        to: filters.dateTo,
        carrier: filters.idcarrier,
        shortcode: filters.idshortcode,
        onlyread: "false",
        idcustomer: filters.idcustomer,
      },
      userData.token
    )
      .then(function (response) {
        setReportData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className="">
        <Stack direction="horizontal" gap={2}>
          <div>
            <h2>Mensajes MO recibidos por fecha</h2>
            <h3>Consulte los mensajes recibidos con base en la fecha.</h3>
          </div>
        </Stack>

        <div className="ReportDate">
          <GenericAdminFilters
            onRefresh={getDataReport}
            setPeriodStr={setPeriodStr}
            withStatusFilter={false}
            withPartnerFilter={false}
            loading={loading}
          />
        </div>

        <Divider />
        {reportData && reportData.length > 0 ? (
          <AdminReportMoList
            periodStr={periodStr}
            listMessages={reportData}
          ></AdminReportMoList>
        ) : (
          <MissInfoReport />
        )}
      </div>
    </>
  );
};

export default ReportMoAdmin;
