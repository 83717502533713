import React from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";

import { Chart } from "react-chartjs-2";
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const CustomerBarGraph = ({ dataCategories, dataReport }) => {
  const data = {
    labels: dataCategories,
    datasets: [
      {
        type: "line",
        label: "Ingresos por Cliente",
        data: dataReport[1],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgb(255, 99, 132)",
        yAxisID: "y1",
      },
      {
        type: "bar",
        label: "SMS por Cliente",
        data: dataReport[0],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        yAxisID: "y",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      title: {
        display: true,
        text: "SMS por Cliente",
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  return (
    <>
      <Row>
        <Col xs={12} sm={6} md={12} lg={12} xl={12}>
          <div style={{ height: "500px" }}>
            <Chart type="bar" options={options} data={data} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CustomerBarGraph;
