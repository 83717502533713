import React, { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
const moment = require("moment");
const DateFilters = ({ setPeriodStr, setFilterFrom, setFilterTo }) => {
  const [selectRange, setSelectRange] = useState(false);
  const [values, setValues] = useState({
    dateFrom: new Date() + "",
    dateTo: new Date() + "",
  });
  const getFilters = () => {
    let dateFrom = new Date(),
      dateTo = new Date();
    var today = new Date();
    switch (selectRange) {
      case "1":
        dateFrom = new Date(dateFrom.setDate(today.getDate() - 1));
        dateTo = new Date(dateTo.setDate(today.getDate()));
        break;
      case "2":
        dateFrom = new Date(dateFrom.setDate(today.getDate() - 7));
        dateTo = new Date(dateTo.setDate(today.getDate() - 1));
        break;
      case "3":
        dateFrom = new Date(today.getFullYear(), today.getMonth(), 1);
        dateTo = today;
        break;
      case "4":
        dateFrom = new Date(dateFrom.setDate(today.getDate() - 30));
        dateTo = today;
        break;
      case "5":
        dateFrom = new Date(values.dateFrom.replace(/-/g, "/"));
        dateTo = new Date(values.dateTo.replace(/-/g, "/"));
        break;
      default:
        dateFrom = new Date(dateTo.setHours(0, 0, 0));
        dateTo = today;
        break;
    }

    let dateFromM = moment(dateFrom).format("YYYY/MM/DD");
    let dateToM = moment(dateTo).format("YYYY/MM/DD");
    let periodStr = `Del ${moment(dateFrom).format("DD/MM/yyyy")}
        al ${moment(dateToM).format("DD/MM/yyyy")} `;
    setPeriodStr(periodStr);
    setFilterFrom(moment(dateFrom).format("YYYY/MM/DD"));
    setFilterTo(moment(dateTo).format("YYYY/MM/DD"));
  };

  const getFiltersLegend = () => {
    let dateFromM = moment(values.dateFrom);
    let dateToM = moment(values.dateTo);
    let periodStr = `Del ${dateFromM.format("DD/MM/yyyy")}
        al ${dateToM.format("DD/MM/yyyy")} `;
    setPeriodStr(periodStr);
  };

  useEffect(() => {
    getFilters();
  }, [selectRange]);

  useEffect(() => {
    getFiltersLegend();
  }, [values.dateFrom, values.dateTo]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <Form.Group as={Col}>
        <>
          <FloatingLabel label="Periodo:" className="mb-1">
            <Form.Select
              id="selectRange"
              name="selectRange"
              onChange={(selectedOption) => {
                setSelectRange(selectedOption.target.value);
              }}
              value={selectRange}
              required
              aria-label="select_selectRange"
            >
              <option value="0">Hoy</option>
              <option value="1">Últimos 24 horas</option>
              <option value="2">Última semana</option>
              <option value="3">Mes Actual</option>
              <option value="4">Ultimos 30 dias</option>
              <option value="5">Personalizar</option>
            </Form.Select>
          </FloatingLabel>
        </>
      </Form.Group>
      {selectRange == 5 && (
        <>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <FloatingLabel label="Fecha de inicio:" className="mb-1">
                <Form.Control
                  type="date"
                  name="dateFrom"
                  id="dateFrom"
                  placeholder="Del"
                  onChange={(event) => {
                    onChange(event);
                    setFilterFrom(event.target.value);
                  }}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col}>
              <FloatingLabel label="Fecha de fin:" className="mb-1">
                <Form.Control
                  type="date"
                  name="dateTo"
                  id="dateTo"
                  placeholder="Al"
                  onChange={(event) => {
                    onChange(event);
                    setFilterTo(event.target.value);
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Row>
        </>
      )}
    </>
  );
};

export default DateFilters;
