import React from "react";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import { Col, Row, Stack } from "react-bootstrap";
import * as AiIcons from "react-icons/ai";
import BtnExportToExcell from "../../utils/BtnExportToExcell";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import { FormatCurrency2Dec, FormatInt } from "../../utils/FormatData";
const columns = [
  {
    accessor: "vc_period",
    Header: "Periodo",
  },
  {
    accessor: "vc_customer",
    Header: "Cliente",
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <>
          <Col style={{ textAlign: "left", fontSize: "smaller" }}>
            {rowInfo.vc_customer}
          </Col>
        </>
      );
    },
  },
  {
    accessor: "vc_partner",
    Header: "Partner",
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <>
          <Col style={{ textAlign: "left", fontSize: "smaller" }}>
            {rowInfo.vc_partner}
          </Col>
        </>
      );
    },
  },
  {
    accessor: "vc_campaign",
    Header: "Campaign",
    showTotal: true,
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <>
          <Col style={{ textAlign: "left", fontSize: "smaller" }}>
            {rowInfo.vc_campaign}
          </Col>
        </>
      );
    },
    Footer: () => {
      return (
        <Col style={{ textAlign: "right", fontWeight: "bold" }}>Totales:</Col>
      );
    },
  },
  {
    accessor: "i_tries",
    Header: "Total Envios",
    showTotal: true,
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <Col style={{ textAlign: "right" }}>{FormatInt(rowInfo.i_tries)}</Col>
      );
    },
    Footer: (columnData) => {
      return (
        <Col style={{ textAlign: "right", fontWeight: "bold" }}>
          {FormatInt(
            columnData.data.reduce(
              (i_total, item) => i_total + Number(item.i_tries),
              0
            )
          )}
        </Col>
      );
    },
  },
  {
    accessor: "i_errors",
    Header: "Errores",
    showTotal: true,
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <Col style={{ textAlign: "right" }}>{FormatInt(rowInfo.i_errors)}</Col>
      );
    },
    Footer: (columnData) => {
      return (
        <Col style={{ textAlign: "right", fontWeight: "bold" }}>
          {FormatInt(
            columnData.data.reduce(
              (i_total, item) => i_total + Number(item.i_errors),
              0
            )
          )}
        </Col>
      );
    },
  },
  {
    accessor: "i_pending",
    Header: "Pendientes",
    showTotal: true,
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <Col style={{ textAlign: "right" }}>{FormatInt(rowInfo.i_pending)}</Col>
      );
    },
    Footer: (columnData) => {
      return (
        <Col style={{ textAlign: "right", fontWeight: "bold" }}>
          {FormatInt(
            columnData.data.reduce(
              (i_total, item) => i_total + Number(item.i_pending),
              0
            )
          )}
        </Col>
      );
    },
  },
  {
    accessor: "i_api",
    Header: "Envios Exitosos API",
    showTotal: true,
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <Col style={{ textAlign: "right" }}>{FormatInt(rowInfo.i_api)}</Col>
      );
    },
    Footer: (columnData) => {
      return (
        <Col style={{ textAlign: "right", fontWeight: "bold" }}>
          {FormatInt(
            columnData.data.reduce(
              (i_total, item) => i_total + Number(item.i_api),
              0
            )
          )}
        </Col>
      );
    },
  },
  {
    accessor: "i_campaigns",
    Header: "Envios Exitosos Campaña",
    showTotal: true,
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <Col style={{ textAlign: "right" }}>
          {FormatInt(rowInfo.i_campaigns)}
        </Col>
      );
    },
    Footer: (columnData) => {
      return (
        <Col style={{ textAlign: "right", fontWeight: "bold" }}>
          {FormatInt(
            columnData.data.reduce(
              (i_total, item) => i_total + Number(item.i_campaigns),
              0
            )
          )}
        </Col>
      );
    },
  },
  {
    accessor: "i_total",
    Header: "Total Envios  Exitosos",
    showTotal: true,
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <Col style={{ textAlign: "right" }}>{FormatInt(rowInfo.i_total)}</Col>
      );
    },
    Footer: (columnData) => {
      return (
        <Col style={{ textAlign: "right", fontWeight: "bold" }}>
          {FormatInt(
            columnData.data.reduce(
              (i_total, item) => i_total + Number(item.i_total),
              0
            )
          )}
        </Col>
      );
    },
  },
  {
    accessor: "n_unitprice",
    Header: "Precio Unitario",
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <Col style={{ textAlign: "right" }}>
          {FormatCurrency2Dec(rowInfo.n_unitprice)}
        </Col>
      );
    },
  },
  {
    accessor: "n_amount",
    Header: "Monto",
    showTotal: true,
    Footer: (columnData) => {
      return (
        <Col style={{ textAlign: "right", fontWeight: "bold" }}>
          {FormatCurrency2Dec(
            columnData.data.reduce(
              (i_total, item) => i_total + Number(item.n_amount),
              0
            )
          )}
        </Col>
      );
    },
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <>
          <Col style={{ textAlign: "right" }}>
            {FormatCurrency2Dec(rowInfo.n_amount)}
          </Col>
        </>
      );
    },
  },
];
const AdminCampaignsSmsList = ({ listMessages, periodStr }) => {
  return (
    <>
      {listMessages && (
        <AdvanceTableWrapper
          columns={columns}
          data={listMessages}
          sortable
          pagination
          perPage={10}
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="DbDetails2">
                <Stack direction="horizontal" gap={2}>
                  <AiIcons.AiOutlineCalendar />
                  <div className="ms-auto">
                    <p>{periodStr}</p>
                  </div>
                </Stack>
                <div className="details">Rango de fechas</div>
              </div>
            </Col>
          </Row>
          <div>
            <div style={{ textAlign: "left" }}>
              <h4>Lista de Mensajes</h4>
            </div>
            <div style={{ textAlign: "right" }}>
              <BtnExportToExcell
                dataReport={listMessages}
                fileName="SMS_Report"
              ></BtnExportToExcell>
            </div>
          </div>

          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: "sm",
              striped: true,
              className: "fs--1 mb-0 overflow-hidden",
            }}
          />
          {/* <AdvanceTablePagination table /> */}
          <AdvanceTableFooter
            rowCount={listMessages.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
            pageSize={10}
          />
        </AdvanceTableWrapper>
      )}
    </>
  );
};

export default AdminCampaignsSmsList;
