import React, { useEffect, useState } from "react";

// Bootstrap
import Table from "react-bootstrap/Table";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

// Componentes
import Edit from "../../components/CampaignEdit";
import ModalEdit from "../../components/ModalEdit";

// Icons
import * as AiIcons from "react-icons/ai";
import { Badge } from "react-bootstrap";
import { useAuth } from "../../Context/AuthContext";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTableSearchBox from "../../components/AdvanceTableSearchBox";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";

import Confirm from "../../components/Confirm";
import { stringGen } from "../../utils/Encrypt";
import EditCustomerUser from "./_EditCustomerUser";
import UpdCustomerUser from "./_UpdCustomerUser";
import { httpClient } from "../../utils/HttpClient";

const UsersByCustomer = ({ idCustomer }) => {
  const Auth = useAuth();
  const [showAddUser, setShowAddUser] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [partnerUsers, setPartnerUsers] = useState(null);
  var userData = Auth.getSessionData();
  const handleClose = () => {
    setShowAddUser(false);
    getPartnerUsers();
  };

  const handleCloseEdit = () => {
    setShowEditUser(false);
    getPartnerUsers();
  };
  const handleShow = () => setShowAddUser(true);
  const handleShowEdit = () => setShowEditUser(true);
  const [modalMessage, setModalMessage] = useState(
    "Desea Cambiar el status del registro?"
  );
  const ModalHandleClose = () => setModalShowConfirm(false);
  const ModalHandleClosePassword = () => setModalShowConfirmPass(false);
  const [idRecordToInactivate, setIdRecordToInactivate] = useState(false);
  const [idRecordToChangePass, setIdRecordToChangePass] = useState(false);
  const [idRecordToChange, setIdRecordToChange] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  const [modalShowConfirmPass, setModalShowConfirmPass] = useState(false);

  const [modalShowConfirmNewPass, setModalShowConfirmNewPass] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState(false);

  const handleConfirm = () => {
    changeStatusUser();
    setModalShowConfirm(false);
  };
  const handleConfirmPass = () => {
    createNewUserPasword();
    setModalShowConfirmPass(false);
  };
  const columns = [
    {
      accessor: "vc_partnername",
      Header: "Partner",
    },
    {
      accessor: "vc_name",
      Header: "Nombre",
    },
    {
      accessor: "vc_username",
      Header: "Usuario",
    },
    {
      accessor: "vc_email",
      Header: "Email",
    },
    {
      accessor: "vc_phone",
      Header: "Telefono",
    },
    {
      accessor: "vc_usertype",
      Header: "Tipo",
    },
    {
      accessor: "i_monthlysms",
      Header: "Limite",
    },
    {
      accessor: "active",
      Header: "Estatus",
      Cell: (rowData) => {
        let active = rowData.row.original.b_active;
        return (
          <>
            {active ? (
              <Badge bg="primary">Activo</Badge>
            ) : (
              <Badge bg="secondary">Inactivo</Badge>
            )}
          </>
        );
      },
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let userid = rowData.row.original.uid_user;
        return (
          <>
            <AiIcons.AiFillEyeInvisible
              className="IconTable"
              title="Desactivar"
              onClick={() => {
                setIdRecordToInactivate(userid);
                setModalMessage("Desea Cambiar el status del registro");
                setModalShowConfirm(true);
              }}
            />
            <AiIcons.AiFillEdit
              className="IconTable"
              title="Modificar Usuario"
              onClick={() => {
                setIdRecordToChange(userid);
                setShowEditUser(true);
              }}
            />
            <AiIcons.AiFillLock
              className="IconTable"
              title="Generar Contraseña"
              onClick={() => {
                setIdRecordToChangePass(userid);
                setModalMessage(
                  "Desea generar una contraseña temporal a este usuario?"
                );
                setModalShowConfirmPass(true);
              }}
            />
          </>
        );
      },
    },
  ];

  const changeStatusUser = () => {
    const URI = ApiEndpoint + "administration/changestatususer/";

    httpClient(
      "POST",
      URI,
      {
        uiduser: idRecordToInactivate,
      },
      userData.token
    ).then((response) => {
      getPartnerUsers();
    });
  };

  const createNewUserPasword = () => {
    const URI = ApiEndpoint + "partner/changepasswordfromadm";
    const newPass = stringGen();

    httpClient(
      "POST",
      URI,
      {
        password: newPass,
        uiduser: idRecordToChangePass,
      },
      userData.token
    ).then((response) => {
      setMessageConfirm("La contraseña nueva es: " + newPass);
      setModalShowConfirmNewPass(true);
      //getPartnerUsers();

      //TODO: mostrar resultado
    });
  };

  const getPartnerUsers = () => {
    const URI = ApiEndpoint + "administration/usersbycustomer/" + idCustomer;

    httpClient("GET", URI, {}, userData.token).then((response) => {
      setPartnerUsers(response.data);
    });
  };

  useEffect(() => {
    getPartnerUsers();
  }, []);

  return (
    <>
      <div className="">
        <Stack direction="horizontal" className="wrappTitle" gap={2}>
          <div>
            <h4>Usuarios</h4>
          </div>
          <div className="ms-auto">
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => {
                // setIdUser(0);
                setShowAddUser(true);
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>{" "}
          </div>
        </Stack>

        {partnerUsers && (
          <AdvanceTableWrapper
            columns={columns}
            data={partnerUsers}
            sortable
            pagination
            perPage={10}
          >
            <div className="SearchBar">
              <Stack direction="horizontal" gap={3}>
                <AdvanceTableSearchBox table />
              </Stack>
            </div>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            <AdvanceTablePagination table />
            <AdvanceTableFooter
              rowCount={partnerUsers.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
              pageSize={10}
            />
          </AdvanceTableWrapper>
        )}
      </div>
      <ModalEdit
        show={showAddUser}
        handleShow={handleShow}
        handleClose={handleClose}
        title="Agregar un usuario"
      >
        <EditCustomerUser
          idCustomer={idCustomer}
          handleClose={handleClose}
        ></EditCustomerUser>
      </ModalEdit>
      <ModalEdit
        show={showEditUser}
        handleShow={handleShowEdit}
        handleClose={handleCloseEdit}
        title="Modificar un usuario"
      >
        <UpdCustomerUser
          idUser={idRecordToChange}
          handleClose={handleCloseEdit}
        ></UpdCustomerUser>
      </ModalEdit>
      <Confirm
        show={modalShowConfirm}
        handleClose={ModalHandleClose}
        handleConfirm={handleConfirm}
        message={"Desea Cambiar el status del usuario?"}
      />
      <Confirm
        show={modalShowConfirmPass}
        handleClose={ModalHandleClosePassword}
        handleConfirm={handleConfirmPass}
        message={modalMessage}
      />
      <Confirm
        show={modalShowConfirmNewPass}
        handleClose={() => {
          setModalShowConfirmNewPass(false);
        }}
        handleConfirm={() => {
          setModalShowConfirmNewPass(false);
        }}
        message={messageConfirm}
      />
    </>
  );
};

export default UsersByCustomer;
