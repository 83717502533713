import React, { useEffect, useState } from "react";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { Badge, Button, Stack } from "react-bootstrap";
import * as AiIcons from "react-icons/ai";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import AdvanceTableSearchBox from "../../components/AdvanceTableSearchBox";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import ModalEdit from "../../components/ModalEdit";
import Confirm from "../../components/Confirm";
import EditKeyword from "./_EditKeyword";
import { useAuth } from "../../Context/AuthContext";
import { httpClient } from "../../utils/HttpClient";

const Keywords = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [keywords, setKeywords] = useState([]);
  const [show, setShow] = useState(false);
  const [idKeywords, setIdKeywords] = useState(0);
  const handleClose = () => {
    getKeywords();
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  const [modalMessage, setModalMessage] = useState(
    "Desea Cambiar el status del registro?"
  );
  const ModalHandleClose = () => setModalShowConfirm(false);
  const [idRecordToInactivate, setIdRecordToInactivate] = useState(false);
  const handleConfirm = () => {
    setModalShowConfirm(false);
    InactivateKeyword();
  };
  const URI = ApiEndpoint + "administration/keywords";

  const columns = [
    {
      accessor: "cat_shortcode.shortcode",
      Header: "Marcacion",
    },
    {
      accessor: "keyword",
      Header: "Keyword",
    },
    {
      accessor: "alias",
      Header: "Alias",
    },
    {
      accessor: "cat_partner.name",
      Header: "Partner",
    },

    {
      accessor: "cat_moaction.name",
      Header: "Accion",
    },
    {
      accessor: "active",
      Header: "Estatus",
      Cell: (rowData) => {
        let active = rowData.row.original.active;
        return (
          <>
            {active ? (
              <Badge bg="primary">Activo</Badge>
            ) : (
              <Badge bg="secondary">Inactivo</Badge>
            )}
          </>
        );
      },
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let idkeyword = rowData.row.original.idkeyword;
        return (
          <>
            <AiIcons.AiFillEyeInvisible
              className="IconTable"
              title="Desactivar"
              onClick={() => {
                setIdRecordToInactivate(idkeyword);
                setModalMessage("Desea Cambiar el status del registro");
                setModalShowConfirm(true);
              }}
            />
            |
            <AiIcons.AiFillEdit
              className="IconTable"
              title="Editar"
              onClick={() => {
                setIdKeywords(idkeyword);
                setShow(true);
              }}
            />
          </>
        );
      },
    },
  ];

  const getKeywords = () => {
    httpClient("GET", URI, {}, userData.token).then((response) => {
      setKeywords(response.data);
    });
  };

  useEffect(() => {
    getKeywords();
  }, []);

  const InactivateKeyword = () => {
    const URIDb =
      ApiEndpoint + "administration/deactivatekeyword/" + idRecordToInactivate;

    httpClient("PUT", URIDb, userData.token)
      .then(function (response) {
        getKeywords();
        setModalShowConfirm(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div>
        <Stack direction="horizontal" gap={2} className="AlignItemsTop">
          <div>
            <h2>Keywords</h2>
            <h3>Administre la lista de keywords.</h3>
          </div>
          <div className="ms-auto">
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                setIdKeywords(0);
                setShow(true);
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>{" "}
          </div>
        </Stack>

        {keywords && (
          <AdvanceTableWrapper
            columns={columns}
            data={keywords}
            sortable
            pagination
            perPage={10}
          >
            <div className="SearchBar">
              <Stack direction="horizontal" gap={3}>
                <AdvanceTableSearchBox table />
              </Stack>
            </div>
            <h4>Lista de Keywords</h4>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            <AdvanceTablePagination table />
            <AdvanceTableFooter
              rowCount={keywords.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
              pageSize={10}
            />
          </AdvanceTableWrapper>
        )}
      </div>
      <ModalEdit
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        title="Editando Keyword"
      >
        <EditKeyword
          show={show}
          idKeyword={idKeywords}
          handleClose={handleClose}
        ></EditKeyword>
      </ModalEdit>
      <Confirm
        show={modalShowConfirm}
        handleClose={ModalHandleClose}
        handleConfirm={handleConfirm}
        message={"Desea Cambiar desactivar la Keyword seleccionada?"}
      />
    </>
  );
};

export default Keywords;
