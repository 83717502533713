import { useState } from "react";
import { decryptSessionData, encryptSessionData } from "../utils/Encrypt";

export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = decryptSessionData(localStorage.getItem(keyName));
      if (value) {
        return JSON.parse(value);
      } else {
        localStorage.setItem(
          keyName,
          encryptSessionData(JSON.stringify(defaultValue))
        );
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue) => {
    try {
      localStorage.setItem(
        keyName,
        encryptSessionData(JSON.stringify(newValue))
      );
    } catch (err) {}
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};
