import React, { useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { stringGen } from "../../utils/Encrypt";
import { v4 as uuidv4 } from "uuid";
import { httpClient } from "../../utils/HttpClient";
import { useAuth } from "../../Context/AuthContext";

const CreatePartner = ({ handleClose, idCustomer }) => {
  const Auth = useAuth();
  const [validated, setValidated] = useState(false);
  var userData = Auth.getSessionData();
  const [values, setValues] = useState({
    name: "",
    partnerid: "",
    secretkey: stringGen(),
    servicename: "",
    serviceid: "",
    description: "",
  });

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    const URICustomer = ApiEndpoint + "configuration/savepartner";

    httpClient(
      "POST",
      URICustomer,
      {
        name: values.name,
        partnerid: values.partnerid,
        secretkey: values.secretkey,
        servicename: values.servicename,
        serviceid: values.serviceid,
        description: values.description,
        idCustomer: idCustomer,
        uidPartner: uuidv4(),
        uidService: uuidv4(),
      },
      userData.token
    )
      .then(function (response) {
        if (!response.data.error) {
          handleClose();
          return;
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit1}>
        <FloatingLabel label="Nombre" className="mb-3">
          <Form.Control
            type="text"
            name="name"
            id="name"
            value={values.name}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el nombre del cliente"
          />
        </FloatingLabel>
        <FloatingLabel label="Api Id" className="mb-3">
          <Form.Control
            type="text"
            name="partnerid"
            id="partnerid"
            value={values.partnerid}
            required
            maxLength={10}
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese un identificador"
          />
        </FloatingLabel>
        <FloatingLabel label="Api Pass" className="mb-3">
          <Form.Control
            type="text"
            name="secretkey"
            id="secretkey"
            value={values.secretkey}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese una Contraseña"
          />
        </FloatingLabel>
        <FloatingLabel label="Nombre del Servicio" className="mb-3">
          <Form.Control
            type="text"
            name="servicename"
            id="servicename"
            value={values.servicename}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el Nombre del Servicio Predeterminado"
          />
        </FloatingLabel>
        <FloatingLabel label="Identificador del Servicio" className="mb-3">
          <Form.Control
            type="text"
            name="serviceid"
            id="serviceid"
            value={values.serviceid}
            required
            maxLength={10}
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el Id del Servicio Predeterminado"
          />
        </FloatingLabel>
        <FloatingLabel label="Descripcion del Servicio" className="mb-3">
          <Form.Control
            type="text"
            name="description"
            id="description"
            value={values.description}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese una descripcion breve"
          />
        </FloatingLabel>

        <div className="text-right">
          <Button variant="primary" type="submit" className="align-self-end">
            {"Guardar"}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CreatePartner;
