import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

// Components
import Message from "../components/Message";

// Assets
import Logo from "../assets/logo.png";
import { Toast } from "react-bootstrap";
import ToastContainer from "react-bootstrap/ToastContainer";
import { ApiEndpoint } from "../utils/ApiEndpoint";
import axios from "axios";
import { useAuth } from "../Context/AuthContext";

const Login = () => {
  const Auth = useAuth();
  let history = useNavigate();
  const [show, setShow] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const ModalHandleClose = () => setModalShow(false);
  const ModalHandleShow = () => setModalShow(true);
  const [modalMessage, setModalMessage] = useState(null);

  const [errorMessage, setErrorMessage] = useState(true);
  const [validated, setValidated] = useState(false);
  // State
  const [formData, setFormData] = useState({
    user: "",
    password: "",
    remember: false,
  });

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    setValidated(true);

    const URI = ApiEndpoint + "users/login";
    axios
      .post(URI, {
        user: formData.user,
        password: formData.password,
      })
      .then(function (response) {
        if (!response.data.error) {
          Auth.login(response.data.user, response.data.token);
        } else {
          setErrorMessage(
            response.data.message == "Blocked"
              ? "Inicio de sesion no valida, Contacte al administrador del sistema"
              : " Proporcione informacion Valida"
          );
          setShow(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
          <div className="Form d-flex align-items-center justify-content-center">
            <img src={Logo} alt="logotipo" className="Logotipo" />
            <Col xs={8} sm={8} md={11} lg={10} xl={7}>
              <div className="FormLogin">
                <h2 className="mb-4">Iniciar sesión</h2>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Usuario:</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={handleFieldChange}
                      name="user"
                      id="user"
                      value={formData.user}
                      className="text-muted"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Contraseña:</Form.Label>
                    <Form.Control
                      onChange={handleFieldChange}
                      type="password"
                      id="password"
                      value={formData.password}
                      name="password"
                      placeholder=""
                    />
                  </Form.Group>
                  <Form.Control.Feedback>
                    Provide a valid User and Password
                  </Form.Control.Feedback>
                  <h6
                    className="forgetPass"
                    onClick={() => {
                      setModalMessage("Contacta a tu ejecutivo de ventas");
                      ModalHandleShow();
                    }}
                  >
                    ¿Olvidaste tu contraseña?
                  </h6>
                  <div className="Send">
                    <Button
                      disabled={!formData.user || !formData.password}
                      variant="primary"
                      type="submit"
                    >
                      Ingresar
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
            <ToastContainer className="ToastLogin" position={"bottom-start"}>
              <Toast
                show={show}
                onClose={() => setShow(!show)}
                delay={3000}
                autohide
              >
                <Toast.Header>
                  <strong className="me-auto">Inicio de Sesion invalido</strong>
                </Toast.Header>
                <Toast.Body>{errorMessage}</Toast.Body>
              </Toast>
            </ToastContainer>
          </div>
        </Col>
        <Col xs={12} sm={12} md={7} lg={7} xl={7}>
          <div className="Cover">
            <div className="LoginCover">
              <p>Copyright @ODT 2023 - Todos los derechos reservados</p>
            </div>
          </div>
        </Col>
      </Row>
      <Message
        show={modalShow}
        handleClose={ModalHandleClose}
        message={modalMessage}
      />
    </Container>
  );
};
export default Login;
