import React, { useEffect, useState } from "react";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import Notification from "../../components/notification";
import { httpClient } from "../../utils/HttpClient";
import { useAuth } from "../../Context/AuthContext";

const EditMaskShortCode = ({ show, idMaskShortCode, handleClose }) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [validated, setValidated] = useState(false);
  const [carrierList, setCarrierList] = useState(false);
  const [shortCodesList, setShortCodesList] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(false);
  const [notify, setNotify] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [messageText, setMessageText] = useState(false);
  const [keywordsError, setKeywordsError] = useState(null);
  const [values, setValues] = useState({
    idshortcodemask: "0",
    idshortcode: "0",
    mask: "",
    active: "true",
  });

  const getMaskShortCode = () => {
    if (idMaskShortCode > 0) {
      const URIKw = ApiEndpoint + "maskshortcode/" + idMaskShortCode;

      httpClient("GET", URIKw, {}, userData.token).then((response) => {
        setValues({
          idshortcodemask: response.data.idshortcodemask,
          idshortcode: response.data.idshortcode,
          mask: response.data.mask,
          active: response.data.active,
        });
      });
    }
  };

  useEffect(() => {
    getMaskShortCode();
  }, []);

  const getCarriersList = () => {
    const URICarriers = ApiEndpoint + "carrierspanel";

    httpClient("GET", URICarriers, {}, userData.token).then((response) => {
      setCarrierList(response.data);
    });
  };

  const getShortCodes = (idCarrier) => {
    const URIShortCodes = ApiEndpoint + "shortcodesbycarrier/";

    httpClient("GET", URIShortCodes + idCarrier, {}, userData.token).then(
      (response) => {
        setShortCodesList(response.data);
      }
    );
  };
  useEffect(() => {
    if (selectedCarrier) getShortCodes(selectedCarrier);
  }, [selectedCarrier]);

  useEffect(() => {
    getCarriersList();
  }, []);

  const handleSubmit1 = (event) => {
    event.preventDefault();
    setKeywordsError(null);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    const URICustomer = ApiEndpoint + "maskshortcode/";
    if (idMaskShortCode > 0) {
      httpClient(
        "PUT",
        URICustomer + idMaskShortCode,
        {
          idshortcode: values.idshortcode,
          mask: values.mask,
          active: values.active,
        },
        userData.token
      )
        .then(function (response) {
          if (response.data.error) {
            setAlertType("warning");
            setMessageText(response.data.message);

            setNotify(true);
          } else {
            handleClose();
            return;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      httpClient(
        "POST",
        URICustomer,
        {
          idshortcode: values.idshortcode,
          mask: values.mask,
          active: values.active,
        },
        userData.token
      )
        .then(function (response) {
          if (response.data.error) {
            setAlertType("warning");
            setMessageText(response.data.message);
            setNotify(true);
          } else {
            handleClose();
            return;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit1}>
        {idMaskShortCode == 0 && (
          <>
            <FloatingLabel label="Operadora *" className="mb-3">
              <Form.Select
                id="idcarrier"
                name="idcarrier"
                onChange={(selectedOption) => {
                  setSelectedCarrier(`${selectedOption.target.value}`);
                }}
                value={selectedCarrier}
                required
                aria-label="select_carrier"
              >
                <option value="">Seleccione una opcion de la lista</option>
                {!!carrierList?.length &&
                  carrierList.map((carrier) => (
                    <option value={carrier.idcarrier}>{carrier.carrier}</option>
                  ))}
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel label="Numero corto *" className="mb-3">
              <Form.Select
                id="idshortcode"
                name="idshortcode"
                onChange={(selectedOption) => {
                  onChange(selectedOption);
                }}
                value={values.idshortcode}
                required
                aria-label="select_shortCode"
              >
                <option value="">Seleccione una opcion de la lista</option>
                {!!shortCodesList?.length &&
                  shortCodesList.map((shortcode) => (
                    <option value={shortcode.idshortcode}>
                      {shortcode.shortcode}
                    </option>
                  ))}
              </Form.Select>
            </FloatingLabel>
          </>
        )}

        <FloatingLabel label="Mascara" className="mb-3">
          <Form.Control
            type="text"
            name="mask"
            id="mask"
            value={values.mask}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese la Mascara"
          />
        </FloatingLabel>

        <div className="text-right">
          <Button variant="primary" type="submit" className="align-self-end">
            {idMaskShortCode > 0 ? "Actualizar" : "Guardar"}
          </Button>
        </div>
      </Form>
      <Notification
        show={notify}
        alertType={alertType}
        setShow={setNotify}
        message={messageText}
      />
    </>
  );
};

export default EditMaskShortCode;
