import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { useAuth } from "../../Context/AuthContext";
import { httpClient } from "../../utils/HttpClient";

const EditShortcode = ({ show, idShortCode, handleClose }) => {
  const Auth = useAuth();
  const [validated, setValidated] = useState(false);
  const [carrierList, setCarrierList] = useState(false);
  var userData = Auth.getSessionData();
  const [values, setValues] = useState({
    idpartner: "0",
    idcarrier: "0",
    shortcode: "",
    active: "",
    queuename: "",
    description: "",
  });

  const getShortCode = () => {
    const URICustomer = ApiEndpoint + "shortcode/" + idShortCode;
    httpClient("GET", URICustomer, {}, userData.token).then((response) => {
      setValues(response.data);
    });
  };

  const getCarriersList = () => {
    const URICarriers = ApiEndpoint + "carriers";
    httpClient("GET", URICarriers, {}, userData.token).then((response) => {
      setCarrierList(response.data);
    });
  };

  useEffect(() => {
    getCarriersList();
    getShortCode();
  }, []);

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    console.log(values);
    const URICustomer = ApiEndpoint + "shortcode/";
    if (idShortCode > 0) {
      httpClient(
        "PUT",
        URICustomer + idShortCode,
        {
          idcarrier: values.idcarrier,
          shortcode: values.shortcode,
          active: values.active,
          queuename: values.queuename,
          description: values.description,
        },
        userData.token
      )
        .then(function (response) {
          handleClose();
          return;
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      httpClient(
        "POST",
        URICustomer,
        {
          idcarrier: values.idcarrier,
          shortcode: values.shortcode,
          active: values.active,
          queuename: values.queuename,
          description: values.description,
        },
        userData.token
      )
        .then(function (response) {
          console.log(response);
          handleClose();
          return;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit1}>
        <FloatingLabel label="Carrier" className="mb-3">
          <Form.Select
            id="idcarrier"
            name="idcarrier"
            onChange={(selectedOption) => {
              onChange(selectedOption);
            }}
            value={values.idcarrier}
            required
            aria-label="select_carrier"
          >
            <option value="">Seleccione una opcion de la lista</option>
            {!!carrierList?.length &&
              carrierList.map((carrier) => (
                <option value={carrier.idcarrier}>{carrier.carrier}</option>
              ))}
          </Form.Select>
        </FloatingLabel>
        <FloatingLabel label="Marcacion" className="mb-3">
          <Form.Control
            type="text"
            name="shortcode"
            id="shortcode"
            value={values.shortcode}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el numero corto"
          />
        </FloatingLabel>

        <FloatingLabel label="Queue" className="mb-3">
          <Form.Control
            type="text"
            name="queuename"
            id="queuename"
            required
            value={values.queuename}
            onChange={(event) => onChange(event)}
            placeholder="Ingrese el queue"
          />
        </FloatingLabel>

        <FloatingLabel label="Descripcion" className="mb-3">
          <Form.Control
            type="text"
            name="description"
            id="description"
            value={values.description}
            onChange={(event) => onChange(event)}
            required
            placeholder="Ingrese  una descripcion"
          />
        </FloatingLabel>
        <FloatingLabel label="" className="mb-3">
          <Form.Check
            type="switch"
            onChange={({ target: { checked } }) => {
              console.log("event.target.value");
              setValues({ ...values, active: checked });
            }}
            checked={values.active}
            id={`default-active`}
            label="Activo?"
          />
        </FloatingLabel>
        <div className="text-right">
          <Button variant="primary" type="submit" className="align-self-end">
            {idShortCode > 0 ? "Actualizar" : "Guardar"}
          </Button>
        </div>
      </Form>
    </>
  );
};
export default EditShortcode;
