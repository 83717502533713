import React, { useEffect, useState } from "react";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { Badge, Button, Stack } from "react-bootstrap";
import * as AiIcons from "react-icons/ai";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTableSearchBox from "../../components/AdvanceTableSearchBox";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import ModalEdit from "../../components/ModalEdit";
import CreatePartner from "./_CreatePartner";
import DetailPartnerApi from "../Generic/DetailPartnerApi";
import ShortCodesByCustomer from "./_ShortCodesByCustomer";
import { httpClient } from "../../utils/HttpClient";
import { useAuth } from "../../Context/AuthContext";

const CustomerPartners = ({ idCustomer }) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [partnersData, setPartnersData] = useState(null);
  const [partnerIdToEdit, setPartnerIdToEdit] = useState(null);
  const [partnerIdToShow, setPartnerIdToShow] = useState(null);
  const [show, setShow] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showInfoPartner, setShowInfoPartner] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseInfo = () => setShowInfo(false);
  const handleShowInfo = () => setShowInfo(true);
  const handleShowInfoPartner = () => setShowInfoPartner(true);
  const handleCloseInfoPartner = () => setShowInfoPartner(false);
  const getPartnersData = () => {
    const URI = ApiEndpoint + "administration/services/" + idCustomer;
    httpClient("GET", URI, "", userData.token)
      .then(function (response) {
        setPartnersData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getPartnersData();
  }, []);

  const columns = [
    {
      accessor: "cat_partner.name",
      Header: "Partner",
    },
    {
      accessor: "cat_partner.partnerid",
      Header: "API Id",
    },
    {
      accessor: "servicename",
      Header: "Servicio",
    },
    {
      accessor: "serviceid",
      Header: "Id Servicio",
    },

    {
      accessor: "description",
      Header: "Descripcion",
    },
    {
      accessor: "cat_partner.active",
      Header: "Estatus",
      Cell: (rowData) => {
        let active = rowData.row.original.cat_partner.active;
        return (
          <>
            {active ? (
              <Badge bg="primary">Activo</Badge>
            ) : (
              <Badge bg="secondary">Inactivo</Badge>
            )}
          </>
        );
      },
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let idToEdit = rowData.row.original.cat_partner.uidpartner;
        return (
          <>
            {" "}
            <AiIcons.AiOutlineNumber
              className="IconTable"
              title="Marcaciones asignadas"
              onClick={() => {
                setPartnerIdToShow(idToEdit);
                setShowInfoPartner(true);
              }}
            />
            <AiIcons.AiOutlineSearch
              className="IconTable"
              onClick={() => {
                setPartnerIdToShow(idToEdit);
                setShowInfo(true);
              }}
            />
          </>
        );
      },
    },
  ];
  return (
    <>
      <div>
        <Stack direction="horizontal" gap={2} className="wrappTitle">
          <h4>Lista de Usuarios para API</h4>
          <div className="ms-auto">
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => {
                setPartnerIdToEdit(0);
                setShow(true);
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>{" "}
          </div>
        </Stack>
        {partnersData && (
          <AdvanceTableWrapper
            columns={columns}
            data={partnersData}
            sortable
            pagination
            perPage={10}
          >
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            <AdvanceTablePagination table />
            <AdvanceTableFooter
              rowCount={partnersData.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
              pageSize={10}
            />
          </AdvanceTableWrapper>
        )}
      </div>
      <ModalEdit
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        title={
          idCustomer > 0 ? "Editar Usuario de API" : "Agregar Usuario de API"
        }
      >
        <CreatePartner handleClose={handleClose} idCustomer={idCustomer} />
      </ModalEdit>
      <ModalEdit
        show={showInfo}
        handleShow={handleShowInfo}
        handleClose={handleCloseInfo}
        title={"Detalle de API"}
      >
        <DetailPartnerApi uidPartner={partnerIdToShow}></DetailPartnerApi>
      </ModalEdit>
      <ModalEdit
        show={showInfoPartner}
        handleShow={handleShowInfoPartner}
        handleClose={handleCloseInfoPartner}
        title={"Detalle de Marcaciones Asignadas"}
        size="lg"
      >
        <ShortCodesByCustomer
          uidPartner={partnerIdToShow}
        ></ShortCodesByCustomer>
      </ModalEdit>
    </>
  );
};

export default CustomerPartners;
