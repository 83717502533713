import React, { useEffect, useState } from "react";
import { FormatDate, FormatInt } from "../../utils/FormatData";
import { Badge, Button, Col, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import AdvanceTableSearchBox from "../../components/AdvanceTableSearchBox";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import EditDatabasePartner from "../ContactsDatabase/EditDb";
import Notification from "../../components/notification";
import Confirm from "../../components/Confirm";
import * as AiIcons from "react-icons/ai";
import ModalEdit from "../../components/ModalEdit";
import EditDatabaseBigData from "./EditDatabaseBigData";
import { useAuth } from "../../Context/AuthContext";
import { httpClient } from "../../utils/HttpClient";

const PartnerDatabases = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();

  const [modalChangeStatusDatabase, setModalChangeStatusDatabase] =
    useState(false);
  const ModalHandleClose = () => {
    getListDatabases();
    setModalChangeStatusDatabase(false);
    setModalShowDeleteDatabase(false);
  };
  const ModalHandleShow = () => setModalChangeStatusDatabase(true);
  const [modalShowDeleteDatabase, setModalShowDeleteDatabase] = useState(false);

  const [showModalAddDatabase, setShowModalAddDatabase] = useState(false);
  const [showModalAddBigDatabase, setShowModalAddBigDatabase] = useState(false);
  const [listDatabases, setListDatabases] = useState(null);
  const [idToInactivate, setIdToInactivate] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [message, setMessage] = useState(null);
  const [alertType, setAlertType] = useState(null);
  const handleClose = () => {
    //  setDisplayMessage(false);
    setShowModalAddDatabase(false);
    getListDatabases();
  };

  const handleCloseBig = () => {
    //  setDisplayMessage(false);
    setShowModalAddBigDatabase(false);
    getListDatabases();
  };

  const [modalMessage, setModalMessage] = useState(null);

  const handleConfirm = () => {
    if (idToInactivate != null && idToInactivate != "") {
      deactivateDatabase();
      setModalChangeStatusDatabase(false);
    }
  };
  const handleConfirmDelete = () => {
    console.log(idToDelete);
    if (idToDelete != null && idToDelete != "") {
      deleteDatabase(idToDelete);
      setModalShowDeleteDatabase(false);
    }
  };

  const columns = [
    {
      accessor: "vc_name",
      Header: "Nombre",
    },
    {
      accessor: "vc_partner",
      Header: "Partner",
    },
    {
      accessor: "i_records",
      Header: "Número de registros",
      Cell: (rowData) => {
        let create = rowData.row.original.i_records;
        return <Col style={{ textAlign: "center" }}>{FormatInt(create)}</Col>;
      },
    },
    {
      accessor: "tst_create",
      Header: "Fecha de carga",
      Cell: (rowData) => {
        let create = rowData.row.original.tst_create;
        return <>{FormatDate(create)}</>;
      },
    },
    {
      accessor: "tst_lastupdate",
      Header: "Última actualización",
      Cell: (rowData) => {
        let lastupdate = rowData.row.original.tst_lastupdate;
        return <>{FormatDate(lastupdate)}</>;
      },
    },
    {
      accessor: "b_activeactive",
      Header: "Estatus",
      Cell: (rowData) => {
        let active = rowData.row.original.b_active;
        return (
          <>
            {active ? (
              <Badge bg="primary">Activo</Badge>
            ) : (
              <Badge bg="secondary">Inactivo</Badge>
            )}
          </>
        );
      },
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let uiddatabase = rowData.row.original.uid_database;
        let active = rowData.row.original.b_active;
        return (
          <>
            <Link to={"/Db/DetailsDb/" + uiddatabase}>
              <AiIcons.AiOutlineSearch
                className="IconTable"
                title="Detalles"
                onClick={() => {}}
              />
            </Link>
            |
            {active ? (
              <AiIcons.AiOutlinePauseCircle
                className="IconTable"
                title="Desactivar"
                onClick={() => {
                  setIdToInactivate(`${uiddatabase}`);
                  setModalMessage(
                    "¿Seguro que quiere desactivar esta base de datos?"
                  );
                  setMessage("La base de datos se ha desactivado");
                  ModalHandleShow();
                }}
              />
            ) : (
              <AiIcons.AiOutlinePlayCircle
                className="IconTable"
                title="Reactivar"
                onClick={() => {
                  setIdToInactivate(`${uiddatabase}`);
                  setModalMessage(
                    "¿Seguro que quiere activar esta base de datos?"
                  );
                  setMessage("La base de datos se ha activado");
                  ModalHandleShow();
                }}
              />
            )}
            |
            <AiIcons.AiOutlineDelete
              className="IconTable"
              title="Borrar"
              onClick={() => {
                setIdToDelete(`${uiddatabase}`);
                setModalMessage(
                  "¿Seguro que quiere borrar esta base de datos?"
                );
                setMessage("La base de datos ha sido borrada");
                setModalShowDeleteDatabase(true);
              }}
            />
            {/* <AiIcons.AiOutlineDownload
                className="IconTable"
                title="Descargar"
                onClick={""}
              />*/}
          </>
        );
      },
    },
  ];

  const getListDatabases = () => {
    const URI = ApiEndpoint + "configuration/getContactDatabases/";

    httpClient("POST", URI, {}, userData.token).then((response) => {
      setListDatabases(response.data);
    });
  };

  const deactivateDatabase = () => {
    const URI = ApiEndpoint + "campaigns/database/inactivate/" + idToInactivate;
    httpClient("PUT", URI, {}, userData.token)
      .then((response) => {
        setDisplayMessage(true);
        getListDatabases();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteDatabase = (idRecordToDelete) => {
    if (idRecordToDelete < 1) return;
    const URI = ApiEndpoint + "administration/destroyPartnerDatabase/";

    httpClient("DELETE", URI + idRecordToDelete, {}, userData.token)
      .then(function (response) {
        console.log(response.data);
        if (response.data.error) {
          setMessage(response.data.message);
          setAlertType("danger");
          setDisplayMessage(true);
        } else {
          setMessage("Registro eliminado exitosamente");
          setDisplayMessage(true);
          getListDatabases();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getListDatabases();
  }, []);
  return (
    <>
      <div className="">
        <Stack direction="horizontal" gap={2} className="AlignItemsTop">
          <div>
            <h2>Bases de datos</h2>
            <h3>
              Administre las bases de datos a las que se enviarán las campañas.
            </h3>
          </div>
          <div className="ms-auto">
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                //setIdCustomer(0);
                setShowModalAddBigDatabase(true);
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar Base +1M
            </Button>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                //setIdCustomer(0);
                setShowModalAddDatabase(true);
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>
          </div>
        </Stack>

        {listDatabases && (
          <AdvanceTableWrapper
            columns={columns}
            data={listDatabases}
            sortable
            pagination
            perPage={10}
          >
            <div className="SearchBar">
              <Stack direction="horizontal" gap={3}>
                <AdvanceTableSearchBox table />
              </Stack>
            </div>
            <h4>Lista de bases de datos</h4>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            <AdvanceTablePagination table />
            <AdvanceTableFooter
              rowCount={listDatabases.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
              pageSize={10}
            />
          </AdvanceTableWrapper>
        )}
      </div>
      {showModalAddDatabase && (
        <ModalEdit
          show={showModalAddDatabase}
          handleClose={handleClose}
          title="Agregar base de datos"
        >
          <EditDatabasePartner handleClose={handleClose}></EditDatabasePartner>
        </ModalEdit>
      )}{" "}
      {showModalAddBigDatabase && (
        <ModalEdit
          show={showModalAddBigDatabase}
          handleClose={handleCloseBig}
          title="Agregar base de datos Grande"
        >
          <EditDatabaseBigData
            handleClose={handleCloseBig}
          ></EditDatabaseBigData>
        </ModalEdit>
      )}
      {displayMessage && (
        <Notification
          show={displayMessage}
          setShow={setDisplayMessage}
          message={message}
          alertType={alertType}
        />
      )}
      <Confirm
        show={modalChangeStatusDatabase}
        handleClose={ModalHandleClose}
        handleConfirm={handleConfirm}
        message={modalMessage}
      />
      <Confirm
        show={modalShowDeleteDatabase}
        handleClose={ModalHandleClose}
        handleConfirm={handleConfirmDelete}
        message={modalMessage}
      />
    </>
  );
};

export default PartnerDatabases;
