import React, { useState } from "react";
import styled from "styled-components";
import { Accordion, Col, Row } from "react-bootstrap";

// Bootstrap
import Stack from "react-bootstrap/Stack";
import ListGroup from "react-bootstrap/ListGroup";
import { Badge } from "react-bootstrap";

// Icons
import * as AiIcons from "react-icons/ai";

import ReportFilters from "./ReportFilters";
import AdvanceTableSearchBox from "../../components/AdvanceTableSearchBox";
import { MissInfoReport } from "../../components/MissInfo";
import ReportSmsList from "./_ReportSmsList";

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ReportDate = () => {
  const [reportData, setReportData] = useState(null);
  const [periodStr, setPeriodStr] = useState("");
  return (
    <>
      <div className="">
        <Stack direction="horizontal" gap={2}>
          <div>
            <h2>Reporte por fecha</h2>
            <h3>Generar reportes con base en la fecha.</h3>
          </div>
        </Stack>

        <div className="ReportDate">
          <ReportFilters
            setReportData={setReportData}
            setPeriodStr={setPeriodStr}
          />

          {/*} <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Filtros</Accordion.Header>
              <Accordion.Body>
                
              </Accordion.Body>
            </Accordion.Item>
          </Accordion> */}
        </div>

        <Divider />

        {/* <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="DbDetails2">
              <Stack direction="horizontal" gap={2}>
                <AiIcons.AiOutlineCalendar />
                <div className="ms-auto">
                  <p>{periodStr}</p>
                </div>
              </Stack>
              <div className="details">Rango de fechas</div>
            </div>
          </Col>
        </Row> */}
        {reportData ? (
          /*<ReportSmsList
            periodStr={periodStr}
            listMessages={reportData}
          ></ReportSmsList> */
          <>
            {" "}
            <h6 className="MissInfo">
              Se ha generado un reporte, en cuanto este listo puede descargarlo
              desde la pagina de Reportes: <br />
              <a
                href="/Reports/ReportsUser"
                className="LinkBlue"
                rel="noreferrer"
              >
                <AiIcons.AiOutlineLink />
                Visitar Pagina de Reportes
              </a>
            </h6>
          </>
        ) : (
          <MissInfoReport />
        )}
      </div>
    </>
  );
};

export default ReportDate;
