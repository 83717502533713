import axios from "axios";
import React, { useEffect, useState } from "react";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { v4 as uuidv4 } from "uuid";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { httpClient } from "../../utils/HttpClient";
import { useAuth } from "../../Context/AuthContext";

const UpdCustomerUser = ({ idUser, handleClose }) => {
  const Auth = useAuth();
  const [validated, setValidated] = useState(false);
  var userData = Auth.getSessionData();

  const [values, setValues] = useState({
    displayname: "",
    name: "",
    firstname: "",
    lastname: "",
    email: "",
    usertype: "",
    phone: "",
    idtypeuser: 3,
    limitsms: 0,
  });

  const getUserToEdit = () => {
    const URIKw = ApiEndpoint + "users/userData/" + idUser;

    httpClient("GET", URIKw, {}, userData.token).then((response) => {
      setValues({
        displayname: response.data.display,
        name: response.data.name,
        firstname: response.data.firstName,
        lastname: response.data.lastName,
        email: response.data.email,
        usertype: response.data.idusertype,
        phone: response.data.phone,
        limitsms: response.data.smslimit,
      });
    });
  };

  useEffect(() => {
    getUserToEdit();
  }, []);

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    const URICreateUser = ApiEndpoint + "users/updateUser";
    httpClient(
      "PUT",
      URICreateUser,
      {
        display: values.displayname,
        name: values.name,
        firstName: values.firstname,
        lastName: values.lastname,
        email: values.email,
        phone: values.phone,
        smslimit: values.limitsms,
        uiduser: idUser,
      },
      userData.token
    )
      .then(function (response) {
        console.log(response);
        handleClose();
        return;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit1}>
        <FloatingLabel label="Nombre" className="mb-3">
          <Form.Control
            type="text"
            name="name"
            id="name"
            value={values.name}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el nombre del nuevo usuario"
          />
        </FloatingLabel>
        <FloatingLabel label="Apellido Paterno" className="mb-3">
          <Form.Control
            type="text"
            name="firstname"
            id="firstname"
            value={values.firstname}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el Apellido Paterno"
          />
        </FloatingLabel>
        <FloatingLabel label="Apellido Materno" className="mb-3">
          <Form.Control
            type="text"
            name="lastname"
            id="lastname"
            value={values.lastname}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el Apellido Materno"
          />
        </FloatingLabel>
        <FloatingLabel label="Email" className="mb-3">
          <Form.Control
            type="email"
            name="email"
            id="email"
            value={values.email}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese un Email Valido"
          />
        </FloatingLabel>
        <FloatingLabel label="Teléfono" className="mb-3">
          <Form.Control
            type="text"
            name="phone"
            id="phone"
            maxLength="10"
            onkeypress="return event.charCode>=48 && event.charCode<=57"
            value={values.phone}
            required
            pattern="^\d{10}$"
            onChange={(event) => {
              event.target.value = event.target.value.replace(/[^\d]+/g, "");
              onChange(event);
            }}
            placeholder="Ingrese un Telefono Valido"
          />
        </FloatingLabel>
        <FloatingLabel label="limite Mensual" className="mb-3">
          <Form.Control
            type="text"
            name="limitsms"
            id="limitsms"
            value={values.limitsms}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el limite mensual"
          />
        </FloatingLabel>
        <hr />
        <div className="text-right">
          <Button variant="primary" type="submit" className="align-self-end">
            Guardar
          </Button>
        </div>
      </Form>
    </>
  );
};

export default UpdCustomerUser;
