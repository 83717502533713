import React, { useEffect, useState } from "react";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { Stack, Table, Button } from "react-bootstrap";

import * as AiIcons from "react-icons/ai";
import EditShortcode from "./EditShortcode";
import ModalEdit from "../../components/ModalEdit";
import { useAuth } from "../../Context/AuthContext";
import { httpClient } from "../../utils/HttpClient";
const URI = ApiEndpoint + "shortcodes/";

const ShortCodes = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [shortCodes, setShortCodes] = useState([]);
  const [show, setShow] = useState(false);
  const [idShortCode, setIdShortCode] = useState(0);
  const handleClose = () => {
    getShortCodes();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const getShortCodes = () => {
    httpClient("GET", URI, {}, userData.token).then((response) => {
      setShortCodes(response.data);
    });
  };

  useEffect(() => {
    getShortCodes();
  }, []);

  return (
    <>
      <div>
        <Stack direction="horizontal" gap={2} className="AlignItemsTop">
          <div>
            <h2>Marcaciones</h2>
            <h3>Administre la lista de marcaciones.</h3>
          </div>
          <div className="ms-auto">
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                setIdShortCode(0);
                setShow(true);
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>{" "}
          </div>
        </Stack>

        <Table striped>
          <thead>
            <tr>
              <th>Marcación</th>
              <th>Partner</th>
              <th>Carrier</th>
              <th>Activo</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {shortCodes &&
              shortCodes.map((item) => {
                return (
                  <tr>
                    <td>{item.shortcode}</td>
                    <td>{item.cat_partner?.name ?? "-"}</td>
                    <td>{item.cat_carrier?.carrier}</td>
                    <td>{item.active}</td>
                    <td>
                      <AiIcons.AiOutlineEdit
                        className="IconTable"
                        onClick={() => {
                          setIdShortCode(item.idshortcode);
                          handleShow();
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      <ModalEdit
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        title={idShortCode > 0 ? "Editar Marcación" : "Agregar Marcación"}
      >
        <EditShortcode
          show={show}
          idShortCode={idShortCode}
          handleClose={handleClose}
        />
      </ModalEdit>
    </>
  );
};

export default ShortCodes;
