import React, { useEffect, useState } from "react";
import { Button, FloatingLabel, Form, Stack } from "react-bootstrap";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { useAuth } from "../../Context/AuthContext";
import { httpClient } from "../../utils/HttpClient";

const EditShortCodeService = ({ idCustomer, refreshData }) => {
  const Auth = useAuth();
  const [validated, setValidated] = useState(false);
  const [carrierList, setCarrierList] = useState(false);
  const [shortCodesList, setShortCodesList] = useState(false);
  const [masksList, setMasksList] = useState(false);
  const [partnerList, setPartnerList] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(false);
  const [partnerUid, setPartnerUid] = useState("");
  const [selectedShortCode, setSelectedShortCode] = useState(false);
  var userData = Auth.getSessionData();
  const [values, setValues] = useState({
    idshortcode: "0",
    idmask: "0",
  });
  const getPartnersList = () => {
    const URIPartners = ApiEndpoint + "partners/";

    httpClient("GET", URIPartners + idCustomer, {}, userData.token).then(
      (response) => {
        setPartnerList(response.data);
      }
    );
  };

  const getCarriersList = () => {
    const URICarriers = ApiEndpoint + "carriers";
    httpClient("GET", URICarriers, {}, userData.token).then((response) => {
      setCarrierList(response.data);
    });
  };

  const getShortCodes = (idCarrier) => {
    const URIShortCodes = ApiEndpoint + "shortcodesbycarrier/";

    httpClient("GET", URIShortCodes + idCarrier, {}, userData.token).then(
      (response) => {
        setShortCodesList(response.data);
      }
    );
  };
  const getShortCodesMask = (idShortCode) => {
    const URIShortCodes = ApiEndpoint + "masksbyshortcode/";

    httpClient("GET", URIShortCodes + idShortCode, {}, userData.token).then(
      (response) => {
        setMasksList(response.data);
      }
    );
  };
  useEffect(() => {
    getShortCodes(selectedCarrier);
  }, [selectedCarrier]);

  useEffect(() => {
    getShortCodesMask(selectedShortCode);
  }, [selectedShortCode]);

  useEffect(() => {
    getCarriersList();
    getPartnersList();
  }, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    const URICustomer = ApiEndpoint + "administration/asignhortcode";
    httpClient(
      "POST",
      URICustomer,
      {
        shortcode: values.idshortcode,
        mask: values.idmask,
        idpartner: partnerUid,
        carrier: selectedCarrier,
        isdefault: isDefault,
      },
      userData.token
    )
      .then(function (response) {
        if (response.data.error) {
          return;
        } else {
          setValues({
            idshortcode: "0",
            idmask: "0",
          });
          setSelectedCarrier(null);
          setSelectedShortCode(null);
          refreshData();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      <Stack direction="horizontal" gap={2} className="wrappTitle">
        <h4> Asignar Marcacion</h4>
      </Stack>
      <Form noValidate validated={validated} onSubmit={handleSubmit1}>
        <FloatingLabel label="Partner *" className="mb-3">
          <Form.Select
            id="idPartner"
            name="idPartner"
            onChange={(selectedOption) => {
              setPartnerUid(selectedOption.target.value);
            }}
            value={values.idPartner}
            required
            aria-label="select_carrier"
          >
            <option value="">Seleccione una opcion de la lista</option>
            {!!partnerList?.length &&
              partnerList.map((partner) => (
                <option value={partner.uidpartner}>{partner.name}</option>
              ))}
          </Form.Select>
        </FloatingLabel>
        <FloatingLabel label="Operadora *" className="mb-3">
          <Form.Select
            id="idcarrier"
            name="idcarrier"
            onChange={(selectedOption) => {
              onChange(selectedOption);
              setSelectedCarrier(`${selectedOption.target.value}`);
            }}
            value={values.idcarrier}
            required
            aria-label="select_carrier"
          >
            <option value="">Seleccione una opción de la lista</option>
            {!!carrierList?.length &&
              carrierList.map((carrier) => (
                <option value={carrier.idcarrier}>{carrier.carrier}</option>
              ))}
          </Form.Select>
        </FloatingLabel>
        <FloatingLabel label="Numero corto *" className="mb-3">
          <Form.Select
            id="idshortcode"
            name="idshortcode"
            onChange={(selectedOption) => {
              onChange(selectedOption);
              setSelectedShortCode(`${selectedOption.target.value}`);
            }}
            value={values.idshortcode}
            required
            aria-label="select_shortCode"
          >
            <option value="">Seleccione una opción de la lista</option>
            {!!shortCodesList?.length &&
              shortCodesList.map((shortcode) => (
                <option value={shortcode.idshortcode}>
                  {shortcode.shortcode}
                </option>
              ))}
          </Form.Select>
        </FloatingLabel>
        <FloatingLabel label="Mascara" className="mb-3">
          <Form.Select
            id="idmask"
            name="idmask"
            onChange={(selectedOption) => {
              onChange(selectedOption);
            }}
            value={values.idmask}
            aria-label="select_mask"
          >
            <option value="">Seleccione una opción de la lista</option>
            {!!masksList?.length &&
              masksList.map((mask) => (
                <option value={mask.idshortcodemask}>{mask.mask}</option>
              ))}
          </Form.Select>
        </FloatingLabel>
        <FloatingLabel label="" className="mb-3">
          <Form.Check
            type="switch"
            id="isDefault"
            value={isDefault}
            label="¿Marcacion Predeterminada?"
            onChange={(event) => {
              setIsDefault(!isDefault);
            }}
            className="prevent-validation"
          />
        </FloatingLabel>
        <div className="text-right">
          <Button variant="primary" type="submit" className="align-self-end">
            {"Asignar"}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default EditShortCodeService;
