import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Bootstrap
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

// Componentes
import EditDb from "./EditDb";
import ModalEdit from "../../components/ModalEdit";
import Confirm from "../../components/Confirm";

// Icons
import * as AiIcons from "react-icons/ai";
import axios from "axios";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { FormatDate, FormatInt } from "../../utils/FormatData";
import Notification from "../../components/notification";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import AdvanceTableSearchBox from "../../components/AdvanceTableSearchBox";
import { useAuth } from "../../Context/AuthContext";
import { httpClient } from "../../utils/HttpClient";

const ContactsDatabase = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [modalShow, setModalShow] = useState(false);
  const [modalShowDelete, setModalShowDelete] = useState(false);
  const ModalHandleClose = () => setModalShow(false);

  const [show, setShow] = useState(false);
  const [listDatabases, setListDatabases] = useState(null);
  const [idToInactivate, setIdToInactivate] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [displayMessage, setDisplayMessage] = useState(null);
  const [message, setMessage] = useState(null);
  const handleClose = () => {
    setShow(false);
    setDisplayMessage(false);
    getListDatabases();
  };

  const [modalMessage, setModalMessage] = useState(null);

  const handleConfirmedDelete = () => {
    console.log("entre");
    console.log(idToDelete);
    if (idToDelete != null && idToDelete != "") {
      deleteDatabase(idToDelete);
      setModalShowDelete(false);
    }
  };

  const handleConfirm = () => {
    console.log("ebtre a inactivar");
    if (idToInactivate != null && idToInactivate != "") {
      deactivateDatabase();
      setModalShow(false);
    }
  };

  const deleteDatabase = (idRecordToDelete) => {
    if (idRecordToDelete < 1) return;
    const URI = ApiEndpoint + "administration/destroyPartnerDatabase/";

    httpClient("DELETE", URI + idRecordToDelete, {}, userData.token)
      .then(function (response) {
        console.log(response.data);
        if (response.data.error) {
          setMessage(response.data.message);
          setAlertType("danger");
          setDisplayMessage(true);
        } else {
          setMessage("Registro eliminado exitosamente");
          setDisplayMessage(true);
          getListDatabases();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleShow = () => setShow(true);
  const columns = [
    {
      accessor: "name",
      Header: "Nombre",
    },
    {
      accessor: "carrier",
      Header: "Carrier",
    },
    {
      accessor: "records",
      Header: "Número de registros",
      Cell: (rowData) => {
        let create = rowData.row.original.records;
        return <>{FormatInt(create)}</>;
      },
    },
    {
      accessor: "create",
      Header: "Fecha de carga",
      Cell: (rowData) => {
        let create = rowData.row.original.create;
        return <>{FormatDate(create)}</>;
      },
    },
    {
      accessor: "lastupdate",
      Header: "Última actualización",
      Cell: (rowData) => {
        let lastupdate = rowData.row.original.lastupdate;
        return <>{FormatDate(lastupdate)}</>;
      },
    },
    {
      accessor: "active",
      Header: "Estatus",
      Cell: (rowData) => {
        let active = rowData.row.original.active;
        return (
          <>
            {active ? (
              <Badge bg="primary">Activo</Badge>
            ) : (
              <Badge bg="secondary">Inactivo</Badge>
            )}
          </>
        );
      },
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let uiddatabase = rowData.row.original.uiddatabase;
        let active = rowData.row.original.active;
        return (
          <>
            <Link to={"/Db/DetailsDb/" + uiddatabase}>
              <AiIcons.AiOutlineSearch className="IconTable" title="Detalles" />
            </Link>
            |
            {active ? (
              <AiIcons.AiOutlinePauseCircle
                className="IconTable"
                title="Desactivar"
                onClick={() => {
                  setIdToInactivate(`${uiddatabase}`);
                  setModalMessage(
                    "¿Seguro que quiere desactivar esta base de datos?"
                  );
                  setMessage("La base de datos se ha desactivado");
                  setModalShow(true);
                }}
              />
            ) : (
              <AiIcons.AiOutlinePlayCircle
                className="IconTable"
                title="Reactivar"
                onClick={() => {
                  setIdToInactivate(`${uiddatabase}`);
                  setModalMessage(
                    "¿Seguro que quiere activar esta base de datos?"
                  );
                  setMessage("La base de datos se ha activado");
                  setModalShow(true);
                }}
              />
            )}
            |
            <AiIcons.AiOutlineDelete
              className="IconTable"
              title="Borrar"
              onClick={() => {
                setIdToDelete(`${uiddatabase}`);
                setModalMessage(
                  "¿Seguro que quiere borrar esta base de datos?"
                );
                setMessage("La base de datos ha sido borrada");
                setModalShowDelete(true);
              }}
            />
            {/* <AiIcons.AiOutlineDownload
              className="IconTable"
              title="Descargar"
              onClick={""}
            />*/}
          </>
        );
      },
    },
  ];

  const getListDatabases = () => {
    const URI = ApiEndpoint + "campaigns/getPartnerDatabases";

    httpClient(
      "POST",
      URI,
      {
        id: userData.uid_partner,
        iduser: userData.uid_user,
      },
      userData.token
    ).then((response) => {
      setListDatabases(response.data);
    });
  };

  const deactivateDatabase = () => {
    const URI = ApiEndpoint + "campaigns/database/inactivate/" + idToInactivate;

    httpClient("PUT", URI, {}, userData.token)
      .then(function (response) {
        setDisplayMessage(true);
        getListDatabases();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  /*useEffect(() => {
    if (idToInactivate != null && idToInactivate != "") {
      deactivateDatabase();
    }
  }, [idToInactivate]);*/

  useEffect(() => {
    getListDatabases();
  }, []);
  return (
    <>
      <div className="">
        <Stack direction="horizontal" gap={2} className="AlignItemsTop">
          <div>
            <h2>Grupo de contactos</h2>
            <h3>
              Administre los grupos de contactos a las que se enviarán las
              campañas.
            </h3>
          </div>
          <div className="ms-auto">
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                //setIdCustomer(0);
                setShow(true);
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>{" "}
          </div>
        </Stack>

        {listDatabases && (
          <AdvanceTableWrapper
            columns={columns}
            data={listDatabases}
            sortable
            pagination
            perPage={10}
          >
            <div className="SearchBar">
              <Stack direction="horizontal" gap={3}>
                <AdvanceTableSearchBox table />
              </Stack>
            </div>
            <h4>Lista de bases de datos</h4>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            <AdvanceTablePagination table />
            <AdvanceTableFooter
              rowCount={listDatabases.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
              pageSize={10}
            />
          </AdvanceTableWrapper>
        )}
        {/* <Table striped>
          <tbody>
            {listDatabases &&
              listDatabases.map((item) => {
                return (
                  <>
                    <tr>
                      <td>{item.name}</td>
                      <td>1</td>
                      <td>{FormatDate(item.create)}</td>
                      <td>{FormatDate(item.lastupdate)}</td>
                      <td>{item.active ? "Activo" : "Inactivo"}</td>
                      <td>
                        
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody> 
        </Table>*/}
      </div>
      {show && (
        <ModalEdit
          show={show}
          handleShow={handleShow}
          handleClose={handleClose}
          title="Agregar base de datos"
        >
          <EditDb handleShow={handleShow} handleClose={handleClose} />
        </ModalEdit>
      )}
      {displayMessage && (
        <Notification
          show={displayMessage}
          setShow={handleClose}
          message={message}
        />
      )}
      <Confirm
        show={modalShowDelete}
        handleClose={() => {
          setModalShowDelete(false);
        }}
        handleConfirm={handleConfirmedDelete}
        message={modalMessage}
      />
      <Confirm
        show={modalShow}
        handleClose={ModalHandleClose}
        handleConfirm={handleConfirm}
        message={modalMessage}
      />
    </>
  );
};

export default ContactsDatabase;
