import React from "react";
import { FormatDate, FormatDateWTime, FormatInt } from "../../utils/FormatData";
import { Badge, Col, Row, Stack } from "react-bootstrap";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import BtnExportToExcell from "../../utils/BtnExportToExcell";

// Icons
import * as AiIcons from "react-icons/ai";

const columns = [
  {
    accessor: "dt_message",
    Header: "Fecha",
  },
  {
    accessor: "vc_carrier",
    Header: "Carrier",
  },
  {
    accessor: "vc_partner",
    Header: "Partner",
  },
  {
    accessor: "vc_campaign",
    Header: "Campaign",
  },
  {
    accessor: "vc_mask",
    Header: "Mascara",
  },
  {
    accessor: "i_sms",
    Header: "SMS",
    showTotal: true,
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <Col style={{ textAlign: "center" }}>{FormatInt(rowInfo.i_sms)}</Col>
      );
    },
    Footer: (columnData) => {
      return (
        <Col style={{ textAlign: "center", fontWeight: "bold" }}>
          {FormatInt(
            columnData.data.reduce(
              (i_total, item) => i_total + Number(item.i_sms),
              0
            )
          )}
        </Col>
      );
    },
  },
  {
    accessor: "i_responses",
    Header: "Response",
    showTotal: true,
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <Col style={{ textAlign: "center" }}>
          {FormatInt(rowInfo.i_responses)}
        </Col>
      );
    },
    Footer: (columnData) => {
      return (
        <Col style={{ textAlign: "center", fontWeight: "bold" }}>
          {FormatInt(
            columnData.data.reduce(
              (i_total, item) => i_total + Number(item.i_responses),
              0
            )
          )}
        </Col>
      );
    },
  },
];
const ReportSmsSmryList = ({ listMessages, periodStr }) => {
  return (
    <>
      {listMessages && (
        <AdvanceTableWrapper
          columns={columns}
          data={listMessages}
          sortable
          pagination
          perPage={10}
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="DbDetails2">
                <Stack direction="horizontal" gap={2}>
                  <AiIcons.AiOutlineCalendar />
                  <div className="ms-auto">
                    <p>{periodStr}</p>
                  </div>
                </Stack>
                <div className="details">Rango de fechas</div>
              </div>
            </Col>
          </Row>
          <div>
            <div style={{ textAlign: "left" }}>
              <h4>Lista de Mensajes</h4>
            </div>
            <div style={{ textAlign: "right" }}>
              <BtnExportToExcell
                dataReport={listMessages}
                fileName="SMS_Report"
              ></BtnExportToExcell>
            </div>
          </div>

          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: "sm",
              striped: true,
              className: "fs--1 mb-0 overflow-hidden",
            }}
          />
          <AdvanceTablePagination table />
          <AdvanceTableFooter
            rowCount={listMessages.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
            pageSize={10}
          />
        </AdvanceTableWrapper>
      )}
    </>
  );
};

export default ReportSmsSmryList;
