import React, { useEffect, useState } from "react";

// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Componentes
import { SmsByDateGraph } from "../components/SmsByDateGraph";

//Widgets
import WidgetCredit from "../components/widgets/widgetCredit";
import WidgetToSend from "../components/widgets/widgetToSend";
import WidgetSended from "../components/widgets/widgetSended";
import WidgetMessages from "../components/widgets/widgetMessages";
import { ApiEndpoint } from "../utils/ApiEndpoint";
import { useAuth } from "../Context/AuthContext";
import AdminDashboard from "./Dashboards/AdminDashboard";
import WidgetNextCampaigns from "../components/widgets/widgetNextCampaigns";
import { httpClient } from "../utils/HttpClient";

const Home = () => {
  const Auth = useAuth();
  const [packageData, setPackageData] = useState(false);
  const [userName, setUserName] = useState(false);
  const getPackageData = () => {
    var userData = Auth.getSessionData();
    setUserName(userData.vc_name);
    const config = {
      headers: { Authorization: `Bearer ${userData.token}` },
    };
    if (userData.uid_partner == null) return;
    const URIPartnerPackage =
      ApiEndpoint +
      "campaigns/getPartnerCurrentPackage/" +
      userData.uid_partner;

    httpClient(
      "POST",
      URIPartnerPackage,
      {
        idpartner: userData.uid_partner,
        limit: 10,
        user: userData.uid_user,
      },
      userData.token
    ).then(function (response) {
      setPackageData(response.data);
    });
  };

  useEffect(() => {
    getPackageData();
  }, []);
  return (
    <>
      <div className="">
        <div>
          <h2>Dashboard</h2>
          <h3>Bienvenido {userName ?? ""}.</h3>
        </div>
      </div>
      {!Auth.isAdminRole() ? (
        <>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="chart">
                <SmsByDateGraph />
              </div>
            </Col>
          </Row>
          <Row>
            {/* <Col xs={12} sm={12} md={6} lg={6} xl={4}>
              <WidgetCredit
                amount={Number(packageData.amount)}
                used={packageData.used_events}
                totalSms={packageData.totalsms}
                grandTotal={
                  Number(packageData.unitprice) * Number(packageData.totalsms)
                }
              />
            </Col> */}
            {/* <Col xs={12} sm={12} md={6} lg={6} xl={2}>
              <Row>
                <Col xs={12} sm={6} md={12} lg={12} xl={12}>
                  <WidgetToSend
                    availabableSms={
                      Number(packageData.totalsms) -
                      Number(packageData.used_events)
                    }
                  />
                </Col>
                <Col xs={12} sm={6} md={12} lg={12} xl={12}>
                  <WidgetSended used_events={packageData.used_events} />
                </Col>
              </Row>
            </Col> */}
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <WidgetMessages />
            </Col>{" "}
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <WidgetNextCampaigns />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <AdminDashboard></AdminDashboard>
        </>
      )}
    </>
  );
};

export default Home;
