import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { Fragment } from "react";

export const ProtectedRoute = ({ children, roles }) => {
  const auth = useAuth();
  if (!auth.userData) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};

export const UnProtectedRoute = ({ children }) => {
  const auth = useAuth();
  if (auth.userData) {
    // user is not authenticated
    return <Navigate to="/home" />;
  }
  return children;
};

export const RolesAuthRoute = ({ children, roles }) => {
  const auth = useAuth();

  if (!auth.userData) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  const role = auth.getUserRole();
  console.log(role);
  console.log(roles);
  const canAccess = roles.includes(role);

  if (canAccess) return <Fragment>{children}</Fragment>;

  return <Navigate to="/" />;
};
