import React, { useEffect } from "react";
import { useAuth } from "../../Context/AuthContext";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import * as AiIcons from "react-icons/ai";

const Logout = () => {
  const Auth = useAuth();
  useEffect(() => {
    console.log("entre a logout page");
    Auth.logoutNoRedirect();
  }, []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            style={{ textAlign: "center" }}
          >
            <div className="Form d-flex align-items-center justify-content-center">
              <img src={Logo} alt="logotipo" className="Logotipo" />
              <Col xs={8} sm={8} md={11} lg={10} xl={7}>
                <h1 className="NotFound">404</h1>
                <h2 className="NotFoundText">Su Sesión ha expirado</h2>
                <Link to="/Home">
                  <Button
                    variant="outline-primary"
                    style={{ marginTop: "40px" }}
                  >
                    <AiIcons.AiOutlineArrowLeft /> Regresar al Sistema
                  </Button>{" "}
                </Link>{" "}
              </Col>
            </div>
          </Col>
          <Col xs={12} sm={12} md={7} lg={7} xl={7}>
            <div className="Cover">
              <div className="NotFoundCover">
                <p>Copyright @ODT 2023 - Todos los derechos reservados</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Logout;
