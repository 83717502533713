/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAsyncDebounce } from "react-table";
import * as AiIcons from "react-icons/ai";
import { Stack } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { useAuth } from "../../Context/AuthContext";
import { v4 as uuidv4 } from "uuid";
import { httpClient } from "../../utils/HttpClient";
const moment = require("moment");

const ReportsByDateFilters = ({
  setReportData,
  setPeriodStr,
  endPointReport = "getReportSms",
}) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [carrierList, setCarrierList] = useState(false);
  const [statusList, setStatusList] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(false);
  const [selectRange, setSelectRange] = useState(false);
  const [shortCodesList, setShortCodesList] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Consultar");
  const [values, setValues] = useState({
    idcarrier: "0",
    idshortcode: "0",
    idstatus: "0",
    dateFrom: "",
    dateTo: "",
  });

  const getDateReport = () => {
    setReportData(null);
    setIsDisabled(true);
    setButtonText("Generando...");
    const URI = ApiEndpoint + "reports/" + endPointReport;
    let dateFrom = new Date(),
      dateTo = new Date();
    var today = new Date();
    switch (selectRange) {
      case "1":
        dateFrom = new Date(dateFrom.setDate(today.getDate() - 1));
        dateTo = new Date(dateTo.setDate(today.getDate()));
        break;
      case "2":
        dateFrom = new Date(dateFrom.setDate(today.getDate() - 7));
        dateTo = new Date(dateTo.setDate(today.getDate() - 1));
        break;
      case "3":
        dateFrom = new Date(today.getFullYear(), today.getMonth(), 1);
        dateTo = today;
        break;
      case "4":
        dateFrom = new Date(dateFrom.setDate(today.getDate() - 30));
        dateTo = today;
        break;
      case "5":
        dateFrom = new Date(values.dateFrom);
        dateTo = new Date(values.dateTo);
        break;
      default:
        dateFrom = today;
        dateTo = today;
        break;
    }
    let dateFromM = moment(dateFrom);
    let dateToM = moment(dateTo);

    let periodStr = `Del ${dateFromM.format("DD/MM/yyyy")}
    al ${dateToM.format("DD/MM/yyyy")} `;
    setPeriodStr(periodStr);

    httpClient(
      "POST",
      URI,
      {
        uidpartner: userData.uid_partner,
        from: dateFrom,
        to: dateTo,
        carrier: values.idcarrier ?? "0",
        shortcode: values.idshortcode ?? "0",
        status: values.idstatus,
        user: userData.uid_user,
        report: uuidv4(),
      },
      userData.token
    )
      .then(function (response) {
        setReportData(response.data);
        setIsDisabled(false);
        setButtonText("Consultar");
      })
      .catch(function (error) {
        console.log(error);
        setIsDisabled(false);
        setButtonText("Consultar");
      });
  };

  const getCarriersList = () => {
    const URICarriers = ApiEndpoint + "carriers";

    httpClient("GET", URICarriers, {}, userData.token).then((response) => {
      setCarrierList(response.data);
    });
  };

  const getStatuses = () => {
    const URICarriers = ApiEndpoint + "configuration/smsStatus";

    httpClient("GET", URICarriers, {}, userData.token).then((response) => {
      setStatusList(response.data.filter((st) => st.idStatus != 4));
    });
  };

  const getShortCodes = (idCarrier) => {
    const URIShortCodes = ApiEndpoint + "shortcodesbycarrier/";

    httpClient("GET", URIShortCodes + idCarrier, {}, userData.token).then(
      (response) => {
        setShortCodesList(response.data);
      }
    );
  };

  useEffect(() => {
    if (!selectedCarrier) return;
    getShortCodes(selectedCarrier);
  }, [selectedCarrier]);

  useEffect(() => {
    getCarriersList();
    getStatuses();
  }, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Form>
        <Row className="mb-1">
          <Col xs={6} sm={6} md={6} lg={3} xl={3}>
            <Form.Group>
              <FloatingLabel label="Carrier:" className="mb-1">
                <Form.Select
                  id="idcarrier"
                  name="idcarrier"
                  onChange={(selectedOption) => {
                    onChange(selectedOption);
                    setSelectedCarrier(`${selectedOption.target.value}`);
                  }}
                  value={values.idcarrier}
                  required
                  aria-label="select_carrier"
                >
                  <option value="0">Todos los carriers</option>
                  {!!carrierList?.length &&
                    carrierList.map((carrier) => (
                      <option value={carrier.idcarrier}>
                        {carrier.carrier}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={6} sm={6} md={6} lg={3} xl={3}>
            <Form.Group>
              <FloatingLabel label="Número corto:" className="mb-1">
                <Form.Select
                  id="idshortcode"
                  name="idshortcode"
                  onChange={(selectedOption) => {
                    onChange(selectedOption);
                  }}
                  value={values.idshortcode}
                  required
                  aria-label="select_shortCode"
                >
                  <option selected disabled hidden>
                    Número corto
                  </option>
                  <option value="0">Todas</option>
                  {!!shortCodesList?.length &&
                    shortCodesList.map((shortcode) => (
                      <option value={shortcode.idshortcode}>
                        {shortcode.shortcode}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={6} sm={6} md={6} lg={3} xl={3}>
            <Form.Group>
              <FloatingLabel label="Estatus:" className="mb-1">
                <Form.Select
                  id="idstatus"
                  name="idstatus"
                  onChange={(selectedOption) => {
                    onChange(selectedOption);
                  }}
                  value={values.idstatus}
                  required
                  aria-label="select_status"
                >
                  <option selected disabled hidden>
                    Estatus:
                  </option>
                  <option value="">Todos</option>
                  {!!statusList?.length &&
                    statusList.map((status) => (
                      <option value={status.idStatus}>{status.status}</option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={6} sm={6} md={6} lg={3} xl={3}>
            <Form.Group>
              <>
                <FloatingLabel label="Periodo:" className="mb-1">
                  <Form.Select
                    id="selectRange"
                    name="selectRange"
                    onChange={(selectedOption) => {
                      onChange(selectedOption);
                      setSelectRange(selectedOption.target.value);
                    }}
                    value={values.selectRange}
                    required
                    aria-label="select_selectRange"
                  >
                    <option value="0">Hoy</option>
                    <option value="1">Últimos 24 horas</option>
                    <option value="2">Última semana</option>
                    <option value="3">Mes Actual</option>
                    <option value="4">Ultimos 30 dias</option>
                    <option value="5">Personalizar</option>
                  </Form.Select>
                </FloatingLabel>
              </>
            </Form.Group>
          </Col>
          {selectRange == 5 && (
            <>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <Form.Group>
                  <FloatingLabel label="Fecha de inicio:" className="mb-1">
                    <Form.Control
                      type="date"
                      name="dateFrom"
                      id="dateFrom"
                      placeholder="Del"
                      onChange={(event) => {
                        onChange(event);
                      }}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <Form.Group>
                  <FloatingLabel label="Fecha de fin:" className="mb-1">
                    <Form.Control
                      type="date"
                      name="dateTo"
                      id="dateTo"
                      placeholder="Al"
                      onChange={(event) => {
                        onChange(event);
                      }}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </>
          )}
        </Row>
        <div className="text-right">
          <Button
            disabled={isDisabled}
            variant="primary"
            type="button"
            onClick={() => {
              getDateReport();
            }}
          >
            {buttonText}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ReportsByDateFilters;
