import React, { useEffect, useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { httpClient } from "../../utils/HttpClient";
const moment = require("moment");
const ReportMoFilters = ({ setReportData, setPeriodStr }) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [carrierList, setCarrierList] = useState(false);
  const [statusList, setStatusList] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(false);
  const [selectRange, setSelectRange] = useState(false);
  const [shortCodesList, setShortCodesList] = useState(false);
  const [values, setValues] = useState({
    idcarrier: "0",
    idshortcode: "0",
    idstatus: "0",
    dateFrom: "",
    dateTo: "",
  });

  const getDateReport = () => {
    setReportData(null);
    var userData = Auth.getSessionData();
    const URI = ApiEndpoint + "reports/getMoReportSms";
    let dateFrom = new Date(),
      dateTo = new Date();
    var today = new Date();
    switch (selectRange) {
      case "1":
        dateFrom = new Date(dateFrom.setDate(today.getDate() - 1));
        dateTo = new Date(dateTo.setDate(today.getDate()));
        break;
      case "2":
        dateFrom = new Date(dateFrom.setDate(today.getDate() - 7));
        dateTo = new Date(dateTo.setDate(today.getDate() - 1));
        break;
      case "3":
        dateFrom = new Date(today.getFullYear(), today.getMonth(), 1);
        dateTo = today;
        break;
      case "4":
        dateFrom = new Date(dateFrom.setDate(today.getDate() - 30));
        dateTo = today;
        break;
      case "5":
        dateFrom = new Date(values.dateFrom);
        dateTo = new Date(values.dateTo);
        break;
      default:
        dateFrom = today;
        dateTo = today;
        break;
    }
    let dateFromM = moment(dateFrom);
    let dateToM = moment(dateTo);

    let periodStr = `Del ${dateFromM.format("DD/MM/yyyy")}
    al ${dateToM.format("DD/MM/yyyy")} `;
    setPeriodStr(periodStr);

    httpClient(
      "POST",
      URI,
      {
        uidpartner: userData.uid_partner,
        infrom: dateFrom,
        into: dateTo,
        carrier: values.idcarrier,
        shortcode: values.idshortcode,
        onlyread: "false",
      },
      userData.token
    )
      .then(function (response) {
        setReportData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getCarriersList = () => {
    const URICarriers = ApiEndpoint + "carriers";

    httpClient("GET", URICarriers, {}, userData.token).then((response) => {
      setCarrierList(response.data);
    });
  };

  const getStatuses = () => {
    const URICarriers = ApiEndpoint + "configuration/smsStatus";
    httpClient("GET", URICarriers, {}, userData.token).then((response) => {
      setStatusList(response.data.filter((st) => st.idStatus != 4));
    });
  };

  const getShortCodes = (idCarrier) => {
    const URIShortCodes = ApiEndpoint + "shortcodesbycarrier/";
    httpClient("GET", URIShortCodes + idCarrier, {}, userData.token).then(
      (response) => {
        setShortCodesList(response.data);
      }
    );
  };

  useEffect(() => {
    if (!selectedCarrier) return;
    getShortCodes(selectedCarrier);
  }, [selectedCarrier]);

  useEffect(() => {
    getCarriersList();
    getStatuses();
  }, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <FloatingLabel label="Carrier:" className="mb-1">
              <Form.Select
                id="idcarrier"
                name="idcarrier"
                onChange={(selectedOption) => {
                  onChange(selectedOption);
                  setSelectedCarrier(`${selectedOption.target.value}`);
                }}
                value={values.idcarrier}
                required
                aria-label="select_carrier"
              >
                <option value="">Todos los Carriers</option>
                {!!carrierList?.length &&
                  carrierList.map((carrier) => (
                    <option value={carrier.idcarrier}>{carrier.carrier}</option>
                  ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col}>
            <FloatingLabel label="Número corto:" className="mb-1">
              <Form.Select
                id="idshortcode"
                name="idshortcode"
                onChange={(selectedOption) => {
                  onChange(selectedOption);
                }}
                value={values.idshortcode}
                required
                aria-label="select_shortCode"
              >
                <option selected disabled hidden>
                  Número corto
                </option>
                <option value="">Todas</option>
                {!!shortCodesList?.length &&
                  shortCodesList.map((shortcode) => (
                    <option value={shortcode.idshortcode}>
                      {shortcode.shortcode}
                    </option>
                  ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <FloatingLabel label="Estatus:" className="mb-1">
              <Form.Select
                id="idstatus"
                name="idstatus"
                onChange={(selectedOption) => {
                  onChange(selectedOption);
                }}
                value={values.idstatus}
                required
                aria-label="select_status"
              >
                <option selected disabled hidden>
                  Estatus:
                </option>
                <option value="">Todos</option>
                {!!statusList?.length &&
                  statusList.map((status) => (
                    <option value={status.idStatus}>{status.status}</option>
                  ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col}>
            <>
              <FloatingLabel label="Periodo:" className="mb-1">
                <Form.Select
                  id="selectRange"
                  name="selectRange"
                  onChange={(selectedOption) => {
                    onChange(selectedOption);
                    setSelectRange(selectedOption.target.value);
                  }}
                  value={values.selectRange}
                  required
                  aria-label="select_selectRange"
                >
                  <option value="0">Hoy</option>
                  <option value="1">Últimos 24 horas</option>
                  <option value="2">Última semana</option>
                  <option value="3">Mes Actual</option>
                  <option value="4">Ultimos 30 dias</option>
                  <option value="5">Personalizar</option>
                </Form.Select>
              </FloatingLabel>
            </>
          </Form.Group>
        </Row>
        {selectRange == 5 && (
          <>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <FloatingLabel label="Fecha de inicio:" className="mb-1">
                  <Form.Control
                    type="date"
                    name="dateFrom"
                    id="dateFrom"
                    placeholder="Del"
                    onChange={(event) => {
                      onChange(event);
                    }}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col}>
                <FloatingLabel label="Fecha de fin:" className="mb-1">
                  <Form.Control
                    type="date"
                    name="dateTo"
                    id="dateTo"
                    placeholder="Al"
                    onChange={(event) => {
                      onChange(event);
                    }}
                  />
                </FloatingLabel>
              </Form.Group>
            </Row>
          </>
        )}

        <div className="text-right">
          <Button
            variant="primary"
            type="button"
            onClick={() => {
              getDateReport();
            }}
          >
            Consultar
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ReportMoFilters;
