import React, { useEffect, useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { Button, Col, Row, Stack } from "react-bootstrap";
import { MissInfoReport } from "../../components/MissInfo";

import * as AiIcons from "react-icons/ai";
import { FormatDateWTime } from "../../utils/FormatData";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import { httpClient } from "../../utils/HttpClient";

//rowInfo.uid_file
const ReportFiles = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [reportData, setReportData] = useState(null);

  const getUsersReport = () => {
    setReportData(null);
    var userData = Auth.getSessionData();
    const URI = ApiEndpoint + "reports/getUserReports";

    httpClient(
      "POST",
      URI,
      {
        user: userData.uid_user,
      },
      userData.token
    )
      .then(function (response) {
        setReportData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getUsersReport();
  }, []);

  const columns = [
    {
      accessor: "vc_name",
      Header: "Reporte",
    },
    {
      accessor: "tst_start",
      Header: "Fecha",
      Cell: (rowData) => {
        let rowInfo = rowData.row.original;
        return (
          <>
            <Row>
              <Col className="DateDetails">
                {FormatDateWTime(rowInfo.tst_start)}
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      accessor: "tst_expires",
      Header: "Expira",
      Cell: (rowData) => {
        let rowInfo = rowData.row.original;
        return (
          <>
            <Row>
              <Col className="DateDetails">
                {FormatDateWTime(rowInfo.tst_expires)}
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      accessor: "uid_file",
      Header: "Descargar Reporte",
      Cell: (rowData) => {
        let rowInfo = rowData.row.original;
        return (
          <>
            <Row>
              {rowInfo.fileExist ? (
                <Button
                  variant="falcon-default"
                  size="sm"
                  onClick={() => {
                    handleDownload(
                      "/reportFiles/" + rowInfo.uid_file + ".csv",
                      rowInfo.uid_file + ".zip"
                    );
                  }}
                >
                  <AiIcons.AiOutlineDownload />
                  Descargar
                </Button>
              ) : (
                <Col style={{ maxWidth: "600px" }}>En Proceso...</Col>
              )}
            </Row>
          </>
        );
      },
    },
  ];

  const handleDownload = (url, filename) => {
    const config = {
      responseType: "blob",
      Authorization: `Bearer ${userData.token}`,
      path: url,
    };
    // axios.post(URIFiles, config).then(response => {
    //     new File(response, filename);
    // });

    const URIFiles = ApiEndpoint + "reports/getReportfile";
    axios({
      url: URIFiles,
      method: "POST",
      data: config,
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    });
  };
  return (
    <>
      <div className="">
        <Stack direction="horizontal" gap={2}>
          <div>
            <h2>Reportes Generados</h2>
            <h3>Consulte los reportes generados con base en el usuario.</h3>
          </div>
        </Stack>
        <hr />
        {reportData && reportData.length > 0 ? (
          <>
            <AdvanceTableWrapper
              columns={columns}
              data={reportData}
              sortable
              pagination
              perPage={10}
            >
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  size: "sm",
                  striped: true,
                  className: "fs--1 mb-0 overflow-hidden",
                }}
              />
              <AdvanceTablePagination table />
              <AdvanceTableFooter
                rowCount={reportData.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
                pageSize={10}
              />
            </AdvanceTableWrapper>
          </>
        ) : (
          <MissInfoReport />
        )}
      </div>
    </>
  );
};

export default ReportFiles;
