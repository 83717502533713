import React, { useEffect, useState } from "react";
import ShortCodesListByPartner from "./_ShortCodesListByPartner";
import EditShortCodeService from "./_EditShortCodeService";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { Stack } from "react-bootstrap";
import { useAuth } from "../../Context/AuthContext";
import { httpClient } from "../../utils/HttpClient";

const URI = ApiEndpoint + "administration/shortcodesbypartner/";
const ShortCodesByCustomer = ({ uidPartner }) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [shortCodes, setShortCodes] = useState(null);
  const refreshData = () => {
    getShortCodes();
  };

  const getShortCodes = () => {
    httpClient("GET", URI + uidPartner, {}, userData.token).then((response) => {
      setShortCodes(response.data);
    });
  };

  useEffect(() => {
    getShortCodes();
  }, []);
  return (
    <div>
      {shortCodes && (
        <ShortCodesListByPartner
          shortCodes={shortCodes}
        ></ShortCodesListByPartner>
      )}
      <hr />

      <EditShortCodeService
        refreshData={refreshData}
        uidPartner={uidPartner}
      ></EditShortCodeService>
    </div>
  );
};

export default ShortCodesByCustomer;
