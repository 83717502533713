import React, { useEffect, useState } from "react";

// Bootstrap
import Table from "react-bootstrap/Table";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

// Componentes
import Edit from "../../components/CampaignEdit";
import ModalEdit from "../../components/ModalEdit";

// Icons
import * as AiIcons from "react-icons/ai";
import { Badge } from "react-bootstrap";
import { useAuth } from "../../Context/AuthContext";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTableSearchBox from "../../components/AdvanceTableSearchBox";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import CreateUserPartner from "./_CreateUserPartner";
import Confirm from "../../components/Confirm";
import { stringGen } from "../../utils/Encrypt";
import { httpClient } from "../../utils/HttpClient";

const UsersByPartner = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [show, setShow] = useState(false);
  const [partnerUsers, setPartnerUsers] = useState(null);
  const handleClose = () => {
    getPartnerUsers();
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [modalMessage, setModalMessage] = useState(
    "Desea Cambiar el status del registro?"
  );
  const ModalHandleClose = () => setModalShowConfirm(false);
  const ModalHandleClosePassword = () => setModalShowConfirmPass(false);
  const ModalHandleCloseNewPassword = () => setModalShowConfirmNewPass(false);

  const [idRecordToInactivate, setIdRecordToInactivate] = useState(false);
  const [idRecordToChangePass, setIdRecordToChangePass] = useState(false);

  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState(false);
  const [modalShowConfirmPass, setModalShowConfirmPass] = useState(false);
  const [modalShowConfirmNewPass, setModalShowConfirmNewPass] = useState(false);

  const handleConfirm = () => {
    changeStatusUser();
    setModalShowConfirm(false);
  };
  const handleConfirmPass = () => {
    createNewUserPasword();
    setModalShowConfirmPass(false);
    setModalShowConfirmNewPass(true);
  };
  const handleConfirmNewPass = () => {
    setModalShowConfirmNewPass(false);
  };

  const columns = [
    {
      accessor: "name",
      Header: "Nombre",
    },
    {
      accessor: "username",
      Header: "Usuario",
    },
    {
      accessor: "email",
      Header: "Email",
    },
    {
      accessor: "phone",
      Header: "Telefono",
    },
    {
      accessor: "active",
      Header: "Estatus",
      Cell: (rowData) => {
        let active = rowData.row.original.active;
        return (
          <>
            {active ? (
              <Badge bg="primary">Activo</Badge>
            ) : (
              <Badge bg="secondary">Inactivo</Badge>
            )}
          </>
        );
      },
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let userid = rowData.row.original.uiduser;
        return (
          <>
            <AiIcons.AiOutlineEyeInvisible
              className="IconTable"
              title="Desactivar"
              onClick={() => {
                setIdRecordToInactivate(userid);
                setModalMessage("Desea cambiar el estatus del registro");
                setModalShowConfirm(true);
              }}
            />
            |
            <AiIcons.AiOutlineLock
              className="IconTable"
              title="Generar Contraseña"
              onClick={() => {
                setIdRecordToChangePass(userid);
                setModalMessage(
                  "¿Desea generar una contraseña temporal a este usuario?"
                );
              }}
            />
          </>
        );
      },
    },
  ];

  const changeStatusUser = () => {
    var userData = Auth.getSessionData();
    const URI = ApiEndpoint + "partner/updstatususer";

    httpClient(
      "POST",
      URI,
      { idpartner: userData.uid_partner, uiduser: idRecordToInactivate },
      userData.token
    ).then((response) => {
      getPartnerUsers();
    });
  };

  const createNewUserPasword = () => {
    var userData = Auth.getSessionData();
    const URI = ApiEndpoint + "partner/changepasswordfromadm";
    const newPass = stringGen();

    httpClient(
      "POST",
      URI,
      { password: newPass, uiduser: idRecordToChangePass },
      userData.token
    ).then((response) => {
      setMessageConfirm("La contraseña nueva es: " + newPass);
      setModalShowConfirmNewPass(true);
      //getPartnerUsers();
      //TODO: mostrar resultado
    });
  };

  const getPartnerUsers = () => {
    var userData = Auth.getSessionData();
    const URI = ApiEndpoint + "partner/users/" + userData.uid_partner;
    httpClient("GET", URI, {}, userData.token).then((response) => {
      setPartnerUsers(response.data);
    });
  };

  useEffect(() => {
    getPartnerUsers();
  }, []);

  return (
    <>
      <div className="">
        <Stack direction="horizontal" gap={2}>
          <div>
            <h2>Usuarios</h2>
            <h3>Administre los usuarios que tienen acceso a su sistema.</h3>
          </div>
          <div className="ms-auto">
            {/*<Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                //setIdCustomer(0);
                setShow(true);
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>{" "}*/}
          </div>
        </Stack>

        {partnerUsers && (
          <AdvanceTableWrapper
            columns={columns}
            data={partnerUsers}
            sortable
            pagination
            perPage={10}
          >
            <div className="SearchBar">
              <Stack direction="horizontal" gap={3}>
                <AdvanceTableSearchBox table />
              </Stack>
            </div>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            <AdvanceTablePagination table />
            <AdvanceTableFooter
              rowCount={partnerUsers.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
              pageSize={10}
            />
          </AdvanceTableWrapper>
        )}
      </div>
      <ModalEdit
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        title="Agregar un usuario"
      >
        <CreateUserPartner handleClose={handleClose}></CreateUserPartner>
      </ModalEdit>
      <Confirm
        show={modalShowConfirm}
        handleClose={ModalHandleClose}
        handleConfirm={handleConfirm}
        message={"¿Desea cambiar el estatus del usuario?"}
      />
      <Confirm
        show={modalShowConfirmPass}
        handleClose={ModalHandleClosePassword}
        handleConfirm={handleConfirmPass}
        message={modalMessage}
      />
      <Confirm
        show={modalShowConfirmNewPass}
        handleClose={ModalHandleCloseNewPassword}
        handleConfirm={ModalHandleCloseNewPassword}
        message={messageConfirm}
      />
    </>
  );
};

export default UsersByPartner;
