import React, { useEffect, useState } from "react";

// Bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";

// Icons
import * as AiIcons from "react-icons/ai";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { useParams } from "react-router-dom";
import { FormatDate } from "../../utils/FormatData";
import DataBaseRecords from "./_DataBaseRecords";
import { useAuth } from "../../Context/AuthContext";
import moment from "moment";
import { httpClient } from "../../utils/HttpClient";

const CamapaignsDetailDatabase = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [dataBaseInfo, setDataBaseInfo] = useState(null);
  let { id } = useParams();

  const getListDatabases = () => {
    const URI = ApiEndpoint + "campaigns/getPartnersDatabase";

    httpClient(
      "POST",
      URI,
      {
        uiddatabase: `${id}`,
        uidUser: userData.uid_user,
      },
      userData.token
    )
      .then(function (response) {
        setDataBaseInfo(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getListDatabases();
  }, []);
  return (
    <>
      {dataBaseInfo && (
        <>
          <div className="">
            <h2>Detalles de base de datos</h2>

            <h4>Información</h4>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                <div className="DbDetails1">
                  <Stack direction="horizontal" gap={2}>
                    <AiIcons.AiOutlineDatabase />
                    <div className="ms-auto">
                      <p>{dataBaseInfo.database.vc_name}</p>
                    </div>
                  </Stack>
                  <div className="details">Nombre</div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                <div className="DbDetails2">
                  <Stack direction="horizontal" gap={2}>
                    <AiIcons.AiOutlineNumber />
                    <div className="ms-auto">
                      <p>{dataBaseInfo.database.i_records}</p>
                    </div>
                  </Stack>
                  <div className="details">Número de registros</div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                <div className="DbDetails3">
                  <Stack direction="horizontal" gap={2}>
                    <AiIcons.AiOutlineCalendar />
                    <div className="ms-auto">
                      <p>
                        {moment(dataBaseInfo.database.tst_create).format(
                          "DD/MM/YYYY"
                        )}
                      </p>
                    </div>
                  </Stack>
                  <div className="details">Fecha de creación</div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                <div
                  className={
                    dataBaseInfo.database.b_active
                      ? "DbDetailsStatusON"
                      : "DbDetailsStatusOFF"
                  }
                >
                  <Stack direction="horizontal" gap={2}>
                    {dataBaseInfo.database.b_locked ? (
                      <AiIcons.AiFillLock />
                    ) : (
                      <AiIcons.AiOutlineFlag />
                    )}
                    <div className="ms-auto">
                      <p>
                        {dataBaseInfo.database.b_active ? "Active" : "Inactiva"}
                      </p>
                    </div>
                  </Stack>
                  <div className="details">Estatus</div>
                </div>
              </Col>
            </Row>
            <div className="wrapp">
              <DataBaseRecords
                isHidden={dataBaseInfo.database.b_locked}
                uidDatabase={`${id}`}
                idpartnerdatabase={dataBaseInfo.idpartnerdatabase}
                isBigData={dataBaseInfo.database.i_records > 100000}
              ></DataBaseRecords>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CamapaignsDetailDatabase;
