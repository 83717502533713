import React, { useEffect, useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import { Button, FloatingLabel, Form, Alert } from "react-bootstrap";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { httpClient } from "../../utils/HttpClient";

const EditOperativeUser = ({ handleClose }) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [validated, setValidated] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [inputValueConfirm, setinputValueConfirm] = useState("");

  const [passValidate, setPassValidate] = useState(false);
  const [passValidateB, setPassValidateB] = useState(false);

  const [usersTypes, setUsersTypes] = useState(false);
  const [values, setValues] = useState({
    username: "",
    password: "",
    confirmpassword: "",
    displayname: "",
    name: "",
    firstname: "",
    lastname: "",
    email: "",
    usertype: "",
    phone: "",
    idusertype: "0",
  });

  const validatePass = (event) => {
    setPassValidate(inputValue !== event.target.value);
  };

  const validatePassB = (event) => {
    setPassValidateB(inputValueConfirm !== event.target.value);
  };

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    const URICreateUser = ApiEndpoint + "configuration/createStaff";

    httpClient(
      "POST",
      URICreateUser,
      {
        username: values.username,
        password: values.password,
        displayname: values.username,
        name: values.name,
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        usertype: values.idusertype,
        phone: values.phone,
      },
      userData.token
    )
      .then(function (response) {
        console.log(response);
        handleClose();
        return;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getUsersTypes = () => {
    const URIShortCodes = ApiEndpoint + "configuration/userstypes";
    httpClient("GET", URIShortCodes, {}, userData.token).then((response) => {
      setUsersTypes(response.data);
    });
  };

  useEffect(() => {
    getUsersTypes();
  }, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit1}>
        <FloatingLabel label="Nombre" className="mb-3">
          <Form.Control
            type="text"
            name="name"
            id="name"
            value={values.name}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el nombre del nuevo usuario"
          />
        </FloatingLabel>
        <FloatingLabel label="Apellido Paterno" className="mb-3">
          <Form.Control
            type="text"
            name="firstname"
            id="firstname"
            value={values.firstname}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el Apellido Paterno"
          />
        </FloatingLabel>
        <FloatingLabel label="Apellido Materno" className="mb-3">
          <Form.Control
            type="text"
            name="lastname"
            id="lastname"
            value={values.lastname}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el Apellido Materno"
          />
        </FloatingLabel>
        <FloatingLabel label="Email" className="mb-3">
          <Form.Control
            type="text"
            name="email"
            id="email"
            value={values.email}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese un Email Valido"
          />
        </FloatingLabel>
        <FloatingLabel label="Telefono" className="mb-3">
          <Form.Control
            type="number"
            name="phone"
            id="phone"
            maxLength={"10"}
            value={values.phone}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese un Telefono Valido"
          />
        </FloatingLabel>

        <FloatingLabel label="Usuario" className="mb-3">
          <Form.Control
            type="text"
            name="username"
            id="username"
            value={values.username}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese un Usuario"
          />
        </FloatingLabel>
        <FloatingLabel label="Contraseña" className="mb-3">
          <Form.Control
            type="password"
            name="password"
            id="password"
            value={values.password}
            required
            onChange={(event) => {
              onChange(event);
              validatePassB(event);
              setInputValue(event.target.value);
            }}
            placeholder="Ingrese una Contraseña"
          />
        </FloatingLabel>
        <FloatingLabel label="Confirmar contraseña" className="mb-3">
          <Form.Control
            type="password"
            name="confirmpassword"
            id="confirmpassword"
            value={values.confirmpassword}
            required
            onChange={(event) => {
              onChange(event);
              validatePass(event);
              setinputValueConfirm(event.target.value);
            }}
            placeholder="Ingrese nuevamente la nueva Contraseña"
          />
        </FloatingLabel>
        {passValidate && passValidateB && (
          <Alert
            key="danger"
            variant="danger"
            size="sm"
            style={{ fontSize: "13px", padding: "5px 10px" }}
          >
            Las contraseñas no coinciden
          </Alert>
        )}
        <hr />
        <FloatingLabel label="Tipo de Usuario *" className="mb-3">
          <Form.Select
            id="idusertype"
            name="idusertype"
            onChange={(selectedOption) => {
              onChange(selectedOption);
            }}
            value={values.idcarrier}
            required
            aria-label="select_usertypes"
          >
            <option value="">Seleccione una opcion de la lista</option>
            {!!usersTypes?.length &&
              usersTypes.map((usertype) => (
                <option value={usertype.idusertype}>{usertype.usertype}</option>
              ))}
          </Form.Select>
        </FloatingLabel>
        <div className="text-right">
          <Button variant="primary" type="submit" className="align-self-end">
            Guardar
          </Button>
        </div>
      </Form>
    </>
  );
};

export default EditOperativeUser;
