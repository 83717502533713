import React from "react";

// Bootstrap
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function Confirm({ show, handleClose, message, handleConfirm }) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" size="sm" onClick={handleConfirm}>
            Confirmar
          </Button>
          <Button variant="secondary" size="sm" onClick={handleClose}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Confirm;
