import React from "react";

// Bootstrap
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";

const CampaignEdit = ({ show, handleClose }) => {
  return (
    <Form>
      <FloatingLabel
        controlId="floatingInput"
        label="First Name"
        className="mb-3"
      >
        <Form.Control type="text" name="" id="" placeholder="Example" />
      </FloatingLabel>

      <FloatingLabel
        controlId="floatingInput"
        label="Last Name"
        className="mb-3"
      >
        <Form.Control type="text" name="" id="" placeholder="Example" />
      </FloatingLabel>

      <FloatingLabel
        controlId="floatingInput"
        label="Username"
        className="mb-3"
      >
        <Form.Control type="text" name="" id="" placeholder="Example" />
      </FloatingLabel>
      <div className="text-right">
        <Button variant="primary" type="submit" className="align-self-end">
          Actualizar
        </Button>
      </div>
    </Form>
  );
};

export default CampaignEdit;
