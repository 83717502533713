import React, { useEffect, useState } from "react";
// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { FormatCurrency2Dec, FormatInt } from "../../utils/FormatData";
import GenericWidget from "../../components/widgets/GenericWidget";
import CustomerBarGraph from "./CustomerBarGraph";
import * as FiIcons from "react-icons/fi";
import { httpClient } from "../../utils/HttpClient";
import { useAuth } from "../../Context/AuthContext";
const moment = require("moment");

const AdminDashboard = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [reportData, setReportData] = useState(false);
  const [chartData, setChartData] = useState(false);
  const [dataCategories, setDataCategories] = useState([]);
  const [dataReport1, setDataReport1] = useState([]);
  const getSummaryData = () => {
    const URIPartnerSummary = ApiEndpoint + "administration/report/summary";
    var date = new Date();
    const fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const toDate = new Date();

    httpClient(
      "POST",
      URIPartnerSummary,
      {
        from: moment(fromDate).format("YYYY/MM/DD"),
        to: moment(toDate).format("YYYY/MM/DD"),
      },
      userData.token
    )
      .then(function (response) {
        setReportData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getReportBarData = () => {
    const URIPartnerSummary =
      ApiEndpoint + "administration/report/customersummary";
    const date = new Date();
    const fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const toDate = new Date();

    httpClient(
      "POST",
      URIPartnerSummary,
      {
        from: moment(fromDate).format("YYYY/MM/DD"),
        to: moment(toDate).format("YYYY/MM/DD"),
      },
      userData.token
    )
      .then(function (response) {
        let chartData = response.data;
        let AllCustomers = [];
        chartData.map((item) => {
          AllCustomers.push(item.vc_customer);
        });

        var uniqueCustomers = [...new Set(AllCustomers)];
        setDataCategories(uniqueCustomers);
        var dataToGraph1 = [],
          dataToGraph2 = [];
        uniqueCustomers.map((vc_customer) => {
          let subtotal = chartData
            .filter((item) => item.vc_customer === vc_customer)
            .reduce((total, row) => {
              return parseInt(row.i_total) + parseInt(total);
            }, 0);

          dataToGraph1.push(subtotal);
        });
        uniqueCustomers.map((vc_customer) => {
          let subtotal2 = chartData
            .filter((item) => item.vc_customer === vc_customer)
            .reduce((totalamount, row) => {
              return parseInt(row.n_amount) + parseInt(totalamount);
            }, 0);

          dataToGraph2.push(subtotal2);
        });

        setDataReport1([dataToGraph1, dataToGraph2]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getSummaryData();
    getReportBarData();
  }, []);

  return (
    <>
      {reportData && (
        <>
          <Row>
            <GenericWidget
              className={"Widget1"}
              title={"Ingreso Actual"}
              legend={"Mes Actual"}
              quantity={FormatCurrency2Dec(reportData.n_amount)}
              sizexs={"12"}
              sizesm={"12"}
              icon={<FiIcons.FiDollarSign />}
            ></GenericWidget>
            <GenericWidget
              className={"Widget2"}
              title={"Mensajes Enviados"}
              legend={"Mes Actual"}
              quantity={FormatInt(reportData.i_total)}
              sizexs={"6"}
              sizesm={"6"}
              icon={<FiIcons.FiMail />}
            ></GenericWidget>
            <GenericWidget
              className={"Widget3"}
              title={"Mensajes Por Enviar"}
              legend={"Mes Actual"}
              quantity={reportData.i_programed}
              sizexs={"6"}
              sizesm={"6"}
              icon={<FiIcons.FiSend />}
            ></GenericWidget>
            <CustomerBarGraph
              dataCategories={dataCategories}
              dataReport={dataReport1}
            ></CustomerBarGraph>
          </Row>
        </>
      )}
    </>
  );
};

export default AdminDashboard;
