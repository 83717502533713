import React, { useEffect, useState } from "react";
import Confirm from "../../components/Confirm";
import EditBitlyToken from "../Generic/_EditBitlyToken";
import ModalEdit from "../../components/ModalEdit";
import { Badge, Button, Stack } from "react-bootstrap";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import AdvanceTableSearchBox from "../../components/AdvanceTableSearchBox";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import ShortLinkDetail from "../Campaigns/_ShortLinkDetail";

import * as AiIcons from "react-icons/ai";
import NewShortLinkAdmin from "./_NewShortLinkAdmin";
import { useAuth } from "../../Context/AuthContext";
import { httpClient } from "../../utils/HttpClient";

const ShortLinksAdmin = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [listShortLinks, setListShortLinks] = useState(false);
  const [show, setShow] = useState(false);

  const [showDetailUrl, setShowDetailUrl] = useState(false); /*******/

  const [modalMessage, setModalMessage] = useState(
    "¿Desea cambiar el estatus del registro?"
  );

  const [idRecordDetails, setIdRecordDetails] = useState(false);

  const [ShortLink, setShortLink] = useState(null);
  const [OriginalLink, setOriginalLink] = useState(null);
  const [ShortLinkName, setShortLinkName] = useState(null);
  const [shortLinkFull, setShortLinkFull] = useState(null);

  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  const [showEditConfig, setShowEditConfig] = useState(false);
  const [idRecordToInactivate, setIdRecordToInactivate] = useState(false);
  const handleClose = () => {
    getShortLinks();
    setShowDetailUrl(false);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const ModalHandleClose = () => {
    setModalShowConfirm(false);
    getShortLinks();
  };
  const handleCloseEditConfig = () => setShowEditConfig(false);
  const handleConfirm = () => {
    changeStatusShortLink();
    setModalShowConfirm(false);
  };

  const handleShowDetailUrl = () => setShowDetailUrl(true); /*******/

  const columns = [
    {
      accessor: "cat_partner.name",
      Header: "Partner",
    },
    {
      accessor: "displayname",
      Header: "Nombre",
    },
    {
      accessor: "shortlinks",
      Header: "Url Corta",
      Cell: (rowData) => {
        let rowInfo = rowData.row.original;
        return (
          <>
            {rowInfo.shortlinks}{" "}
            <a
              href={rowInfo.shortlinks}
              className="LinkBlue"
              target="_blank"
              rel="noreferrer"
            >
              <AiIcons.AiOutlineLink />
            </a>
          </>
        );
      },
    },
    {
      accessor: "originallink",
      Header: "Url Original",

      Cell: (rowData) => {
        let rowInfo = rowData.row.original.originallink;
        return (
          <>
            {rowInfo.length > 50 ? rowInfo.substring(0, 49) + "..." : rowInfo}{" "}
            <a
              href={rowInfo}
              className="LinkBlue"
              target="_blank"
              rel="noreferrer"
            >
              <AiIcons.AiOutlineLink />
            </a>
          </>
        );
      },
    },
    {
      accessor: "active",
      Header: "Estatus",
      Cell: (rowData) => {
        let active = rowData.row.original.active;
        return (
          <>
            {active ? (
              <Badge bg="primary">Activo</Badge>
            ) : (
              <Badge bg="secondary">Inactivo</Badge>
            )}
          </>
        );
      },
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let idshortlink = rowData.row.original.idshortlink;
        let uidpartner = rowData.row.original.cat_partner.uidpartner;
        let shortlinks = rowData.row.original.shortlinks;
        let bitlink = rowData.row.original.bitlink;
        let bitlinkName = rowData.row.original.displayname;
        let Longlink = rowData.row.original.originallink;
        return (
          <>
            <AiIcons.AiOutlineEyeInvisible
              className="IconTable"
              title="Desactivar"
              onClick={() => {
                setIdRecordToInactivate({
                  idpartner: uidpartner,
                  idshortlink: idshortlink,
                });
                setModalShowConfirm(true);
              }}
            />
            |
            <AiIcons.AiOutlineSearch
              className="IconTable"
              title="Detalles"
              onClick={() => {
                setShowDetailUrl(true);
                setIdRecordDetails(idshortlink);
                setShortLink(bitlink);
                setShortLinkFull(shortlinks);
                setShortLinkName(bitlinkName);
                setOriginalLink(Longlink);
              }}
            />{" "}
          </>
        );
      },
    },
  ];
  const getShortLinks = () => {
    const URI = ApiEndpoint + "configuration/getShortLinks/";

    httpClient("GET", URI, {}, userData.token).then((response) => {
      setListShortLinks(response.data);
    });
  };

  const changeStatusShortLink = () => {
    const URI = ApiEndpoint + "campaigns/changeShortLink";

    httpClient(
      "POST",
      URI,
      {
        idRecordToInactivate: idRecordToInactivate,
      },
      userData.token
    ).then((response) => {
      getShortLinks();
    });
  };

  useEffect(() => {
    getShortLinks();
  }, []);

  return (
    <>
      <div className="">
        <Stack direction="horizontal" gap={2}>
          <div>
            <h2>Urls Cortas</h2>
            <h3>Administre la lista de Urls Cortas.</h3>
          </div>
          <div className="ms-auto">
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                setShowEditConfig(true);
              }}
            >
              <AiIcons.AiFillSetting /> Token BitLy
            </Button>{" "}
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                setShow(true);
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>
          </div>
        </Stack>

        {listShortLinks && (
          <AdvanceTableWrapper
            columns={columns}
            data={listShortLinks}
            sortable
            pagination
            perPage={10}
          >
            <div className="SearchBar">
              <Stack direction="horizontal" gap={3}>
                <AdvanceTableSearchBox table />
              </Stack>
            </div>
            <h4>Lista de Urls Cortas</h4>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            <AdvanceTablePagination table />
            <AdvanceTableFooter
              rowCount={listShortLinks.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
              pageSize={10}
            />
          </AdvanceTableWrapper>
        )}
      </div>
      <ModalEdit
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        title="Editando Url Corta"
      >
        <NewShortLinkAdmin
          handleShow={handleShow}
          handleClose={handleClose}
        ></NewShortLinkAdmin>
      </ModalEdit>

      <ModalEdit
        show={showDetailUrl}
        handleShow={handleShowDetailUrl}
        handleClose={handleClose}
        title="Detalles de la Url Corta"
      >
        <ShortLinkDetail
          ShortLink={ShortLink}
          ShortLinkName={ShortLinkName}
          OriginalLink={OriginalLink}
          ShortLinkFull={shortLinkFull}
        ></ShortLinkDetail>
      </ModalEdit>

      {showEditConfig && (
        <ModalEdit
          show={showEditConfig}
          handleClose={handleCloseEditConfig}
          title="Modificar Token Bitly"
        >
          <EditBitlyToken handleClose={handleCloseEditConfig}></EditBitlyToken>
        </ModalEdit>
      )}

      <Confirm
        show={modalShowConfirm}
        handleClose={ModalHandleClose}
        handleConfirm={handleConfirm}
        message={modalMessage}
      />
    </>
  );
};

export default ShortLinksAdmin;
