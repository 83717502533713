import React from "react";
import { Badge, Button, Stack } from "react-bootstrap";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTableSearchBox from "../../components/AdvanceTableSearchBox";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";

import * as AiIcons from "react-icons/ai";
import { FormatDate, FormatDateWTime } from "../../utils/FormatData";
import { getStatus } from "../../utils/AuxFunctions";
import moment from "moment";

const CampaignMessages = ({
  setShow,
  setShowDetail,
  dataBaseInfo,
  setIdRecord,
  setIdRecordToDetail,
  setIdRecordMessageToDelete,
  setShowConfirmCancel,
  setStatus,
  setNameCampaign,
  uidDatabase,
  isDatabaseDeleted,
}) => {
  const columns = [
    {
      accessor: "message",
      Header: "Mensaje",
    },
    {
      accessor: "sendDate",
      Header: "Fecha Programada",
      Cell: (rowData) => {
        let sendDate = rowData.row.original.sendDate;
        return moment(sendDate).format("DD/MM/YYYY HH:mm");
      },
    },
    {
      accessor: "execution",
      Header: "Fecha de Ejecucion",
      Cell: (rowData) => {
        let execution = rowData.row.original.execution;
        let idStatus = rowData.row.original.idStatus;
        return idStatus == 1 || idStatus == 4
          ? "-"
          : moment(execution).format("DD/MM/YYYY HH:mm");
      },
    },
    {
      accessor: "idStatus",
      Header: "Estatus",
      Cell: (rowData) => {
        let idStatus = rowData.row.original.idStatus;
        let status = getStatus(idStatus);

        return (
          <Badge
            bg={
              idStatus == 1
                ? "primary"
                : idStatus == 2
                ? "info"
                : idStatus == 3
                ? "success"
                : "danger"
            }
          >
            {status}
          </Badge>
        );
      },
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let iddetail = rowData.row.original.idCampaignMessage;
        let idStatus = rowData.row.original.idStatus;
        let nameCampaign = rowData.row.original.message;
        return (
          <>
            {idStatus == 1 ? (
              <>
                <AiIcons.AiOutlineStop
                  className="IconTable"
                  title="Desactivar"
                  onClick={() => {
                    setIdRecordMessageToDelete(iddetail);
                    setShowConfirmCancel(true);
                  }}
                />
                {" | "}
              </>
            ) : (
              ""
            )}
            <>
              <AiIcons.AiOutlineSearch
                className="IconTable"
                title="Detalles"
                onClick={() => {
                  setShowDetail(true);
                  setIdRecordToDetail(iddetail);
                  setStatus(idStatus);
                  setNameCampaign(nameCampaign);
                }}
              />{" "}
            </>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Stack direction="horizontal" className="wrappTitle" gap={2}>
        <div>
          <h4>Lista de Mensajes Configurados</h4>
        </div>
        <div className="ms-auto">
          {!isDatabaseDeleted && (
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => {
                setIdRecord(0);
                setShow(true);
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>
          )}
        </div>
      </Stack>
      {dataBaseInfo && (
        <AdvanceTableWrapper
          columns={columns}
          data={dataBaseInfo}
          sortable
          pagination
          perPage={10}
        >
          <div className="SearchBar">
            <Stack direction="horizontal" gap={3}>
              <AdvanceTableSearchBox table />
            </Stack>
          </div>
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: "sm",
              striped: true,
              className: "fs--1 mb-0 overflow-hidden",
            }}
          />
          <AdvanceTablePagination table />
          <AdvanceTableFooter
            rowCount={dataBaseInfo.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
            pageSize={20}
          />
        </AdvanceTableWrapper>
      )}
    </>
  );
};

export default CampaignMessages;
