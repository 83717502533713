import React, {useState} from 'react';
import styled from 'styled-components';

import { NavLink } from 'react-router-dom';
import { useAuth } from "../Context/AuthContext";

// Assets
import Logo from '../assets/logo.png';

// Assets
import * as RiIcons from 'react-icons/ri';
import * as IoIcons from 'react-icons/io5';
import { IconContext } from 'react-icons/lib';

// Components
import { SideBarData } from './sideBarData';
import SubMenu from './subMenu';

const Nav = styled.div`
    background: #ffffff;
    box-shadow: 0px 0px 60px 0px #0000000f;
    height: 65px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const NavIcon = styled(NavLink)`
    margin-left: 15px;
    font-size: 30px;
    height: 65px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const SidebarNav = styled.nav`
    background: #ffffff;
    box-shadow: 0px 0px 60px 0px #0000000f;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
    transition: 350ms;
    z-index: 10;
`;

const SidebarWrap = styled.div`
    width: 100%;
    color: #969696;
`;


const TopBar = () => {
    const Auth = useAuth();
    const [sidebar, setSidebar] = useState(false)

    const showSidebar = () => setSidebar(!sidebar)

    return (
        <IconContext.Provider value={{ color: '#009cff' }}>
            <Nav className='justify-content-between'>
                <NavIcon to='#'>
                    <RiIcons.RiMenu4Line onClick={showSidebar} />
                </NavIcon>
                <img src={Logo} alt="logotipo" className='LogotipoTop'/>
            </Nav>
            <SidebarNav sidebar={sidebar}>
                <SidebarWrap>
                    <NavIcon to='#'>
                        <IoIcons.IoCloseOutline onClick={showSidebar} />
                    </NavIcon>
                    {SideBarData.filter(
                    (item) =>
                        item.isAdminRoute == Auth.isAdminRole() &&
                        item.roles.includes(Auth.getUserRole())
                    ).map((item, index) => {
                    return <SubMenu item={item} key={index} />;
                    })}
                </SidebarWrap>
            </SidebarNav>
        </IconContext.Provider>
        );
    };

export default TopBar;