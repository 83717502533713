import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../Context/AuthContext";
import { httpClient } from "../../utils/HttpClient";

const EditCustomer = ({ show, idCustomer, handleClose }) => {
  const Auth = useAuth();
  const [validated, setValidated] = useState(false);
  var userData = Auth.getSessionData();
  const [values, setValues] = useState({
    name: "",
    comercialname: "",
    rfc: "",
    address: "",
  });

  const getCustomer = () => {
    const URICustomer = ApiEndpoint + "customer/" + idCustomer;
    httpClient("GET", URICustomer, {}, userData.token).then((response) => {
      setValues(response.data);
    });
  };

  useEffect(() => {
    getCustomer();
  }, []);

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    const URICustomer = ApiEndpoint + "customer/";
    if (idCustomer > 0) {
      httpClient(
        "PUT",
        URICustomer + idCustomer,
        {
          name: values.name,
          comercialname: values.comercialname,
          rfc: values.rfc,
          address: values.address,
        },
        userData.token
      )
        .then(function (response) {
          handleClose();
          return;
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      httpClient(
        "POST",
        URICustomer,
        {
          name: values.name,
          comercialname: values.comercialname,
          rfc: values.rfc,
          address: values.address,
          uidCustomer: uuidv4(),
        },
        userData.token
      )
        .then(function (response) {
          console.log(response);
          handleClose();
          return;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit1}>
        <FloatingLabel label="Nombre" className="mb-3">
          <Form.Control
            type="text"
            name="name"
            id="name"
            value={values.name}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el nombre del cliente"
          />
        </FloatingLabel>

        <FloatingLabel label="Nombre Comercial" className="mb-3">
          <Form.Control
            type="text"
            name="comercialname"
            id="comercialname"
            required
            value={values.comercialname}
            onChange={(event) => onChange(event)}
            placeholder="Ingrese el nombre comercial"
          />
        </FloatingLabel>

        <FloatingLabel label="RFC" className="mb-3">
          <Form.Control
            type="text"
            name="rfc"
            id="rfc"
            value={values.rfc}
            onChange={(event) => onChange(event)}
            required
            placeholder="Ingrese el RFC"
          />
        </FloatingLabel>

        <FloatingLabel label="Direccion" className="mb-3">
          <Form.Control
            type="text"
            name="address"
            required
            value={values.address}
            onChange={(event) => onChange(event)}
            id="address"
            placeholder="Ingrese la Direccion"
          />
        </FloatingLabel>
        <div className="text-right">
          <Button variant="primary" type="submit" className="align-self-end">
            {idCustomer > 0 ? "Actualizar" : "Guardar"}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default EditCustomer;
