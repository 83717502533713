import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { Button, FloatingLabel } from "react-bootstrap";
import Notification from "../../components/notification";
import SmsTextBox from "../../components/generic/_SmsTextBox";
import { Badge, Col, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { useAuth } from "../../Context/AuthContext";
import moment from "moment";
import { httpClient } from "../../utils/HttpClient";

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

var today = moment(new Date()).format().split("T")[0];

const NewCampaignMessage = ({
  CampaignName,
  uidCampaign,
  handleCloseParent,
  uidDatabase,
}) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showPrev, setShowPrev] = useState(false);
  const [listShortLinks, setListShortLinks] = useState(false);
  const [carrierList, setCarrierList] = useState(false);
  const [fieldsInfo, setFieldsInfo] = useState(false);
  const [messageText, setMessageText] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [messageNotification, setMessageNotification] =
    useState("Campaña creada");
  const [values, setValues] = useState({
    message: "",
    template: "",
    dateSend: "",
    msisdn: "",
    proghour: "",
    template: "",
  });

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    if (isScheduled) {
      console.log("new programCampaign 2");
      console.log(values.dateSend);
      console.log(values.proghour);
    }
    setValidated(true);
    let URICustomer = ApiEndpoint + "campaigns/createCampaignMessage";
    if (isScheduled) {
      URICustomer = ApiEndpoint + "campaigns/programCampaignMessage";
    }

    httpClient(
      "POST",
      URICustomer,
      {
        uid: uidCampaign,
        message: values.message,
        date: values.dateSend,
        hour: isScheduled ? values.proghour.split(":")[0] : 0,
        minute: isScheduled ? values.proghour.split(":")[1] : 0,
        uidUser: userData.uid_user,
      },
      userData.token
    )
      .then(function (response) {
        console.log(response.data.error);
        if (response.data.error) {
          setMessageNotification(
            response.data.Errorcode == "0"
              ? "No cuenta con SMS suficientes para la campaña"
              : "Ha ocurrido un error"
          );
          setNotificationType("warning");
          setShow(true);
        } else {
          setMessageNotification("Campaña creada");
          setNotificationType("");
          setShow(true);
          handleCloseParent();
        }

        return;
      })
      .catch(function (error) {
        setMessageNotification("Ha ocurrido un error");
        setNotificationType("warning");
        setShow(true);
        console.log(error);
      });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const getCarriersList = () => {
    const URICarriers = ApiEndpoint + "carriers";

    httpClient("GET", URICarriers, {}, userData.token).then((response) => {
      setCarrierList(response.data);
    });
  };

  const getXtraFieldsInfo = (uidDatabase) => {
    const URIFields = ApiEndpoint + "campaigns/fieldsdatabase/";
    httpClient("GET", URIFields + uidDatabase, {}, userData.token).then(
      (response) => {
        setFieldsInfo(response.data);
      }
    );
  };

  useEffect(() => {
    getXtraFieldsInfo(uidDatabase);
  }, [uidDatabase]);

  const getShortLinks = () => {
    const URI = ApiEndpoint + "campaigns/shortLinks/" + userData.uid_partner;
    httpClient("GET", URI, {}, userData.token).then((response) => {
      setListShortLinks(response.data.filter((x) => x.active));
    });
  };

  useEffect(() => {
    if (!fieldsInfo) {
      setShowPrev(true);
      return;
    }
    let messageWithTemplate = values.message;
    var pattern = RegExp("{(.*?)}", "g");
    let result;
    while ((result = pattern.exec(messageWithTemplate))) {
      // Do something with result[0].
      console.log(result[0]);
      try {
        var currentK = fieldsInfo.filter(
          (item) => "{" + item.vc_keycolumn + "}" == result[0]
        );
        console.log(currentK);
        messageWithTemplate = messageWithTemplate.replace(
          result[0],
          currentK[0].vc_value
        );
      } catch (error) {}
    }
    setValues({
      ...values,
      ["template"]: messageWithTemplate.replaceAll("{", "").replaceAll("}", ""),
    });
  }, [values.message]);

  useEffect(() => {
    getCarriersList();
    getShortLinks();
  }, []);

  return (
    <>
      <div className="NewCampaignMsg">
        <Form noValidate validated={validated} onSubmit={handleSubmit1}>
          <h2>Campaña: {CampaignName}</h2>
          <Divider />
          <h3>1. Redacte el mensaje</h3>
          <Row>
            <Col sm={12}>
              <SmsTextBox
                name="message"
                defaultValue={values.message}
                changeHandler={onChange}
              ></SmsTextBox>
            </Col>
            <Col sm={12}>
              <Tabs
                defaultActiveKey="ShortLinks"
                id="fill-tab-example"
                className="tabsBottom"
                fill
              >
                <Tab eventKey="ShortLinks" title="Urls Cortas">
                  {fieldsInfo && (
                    <div className="ShortLink" style={{ paddingTop: "10px" }}>
                      <h5>
                        Al darle click se agregará la url corta relacionada con
                        el alias que aparece a continuación:
                      </h5>
                      <div className="AllLinks">
                        <>
                          {listShortLinks &&
                            listShortLinks.map((shortlink) => {
                              return (
                                <>
                                  {" "}
                                  <Badge
                                    onClick={() => {
                                      setMessageText(shortlink.shortlinks);
                                      setValues({
                                        ...values,
                                        message:
                                          values.message +
                                          " " +
                                          shortlink.shortlinks +
                                          " ",
                                      });
                                    }}
                                    bg="primary"
                                  >
                                    {shortlink.displayname}
                                  </Badge>{" "}
                                </>
                              );
                            })}
                        </>
                      </div>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="AddFields" title="Template">
                  <div className="ShortLink" style={{ paddingTop: "10px" }}>
                    <h5>
                      Al darle click se agregará el campo relacionado con el
                      alias que aparece a continuación:
                    </h5>
                    <div className="AllLinks">
                      <>
                        {fieldsInfo &&
                          fieldsInfo.map((field) => {
                            return (
                              <>
                                <Badge
                                  onClick={() => {
                                    setMessageText(field.vc_keycolumn);
                                    setValues({
                                      ...values,
                                      message:
                                        values.message +
                                        " {" +
                                        field.vc_keycolumn +
                                        "} ",
                                    });
                                  }}
                                  bg="primary"
                                >
                                  {field.vc_keycolumn}
                                </Badge>
                              </>
                            );
                          })}
                      </>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>

          {values.template && showPrev && (
            <>
              <h5 className="PrevTitleModal">Previsualización:</h5>
              <div class="contenedo_msj">
                <div class="mensaje-izq">
                  <div class="cuerpo_msj">
                    <div class="msj-texto">{values.template}</div>
                  </div>
                </div>
                <Badge
                  className="mt-2"
                  style={{ minWidth: "143px" }}
                  bg="secondary"
                >
                  Max. Caracteres: {values.template.length}
                </Badge>
              </div>
              <hr />
            </>
          )}

          <Divider />
          <h3>2. Programe el mensaje (opcional)</h3>
          <h5>
            En caso de no seleccionar esta opción tu campaña se procesará de
            inmediato.
          </h5>

          <FloatingLabel label="" className="mb-3">
            <Form.Check
              type="switch"
              id="isScheduled"
              value={isScheduled}
              label="¿Desas programar la campaña?"
              onChange={(event) => {
                setIsScheduled(!isScheduled);
              }}
              className="prevent-validation"
            />
          </FloatingLabel>
          {isScheduled && (
            <>
              {isScheduled ? (
                <>
                  <FloatingLabel label="Fecha de envío:" className="mb-3">
                    <Form.Control
                      type="date"
                      name="dateSend"
                      id="dateSend"
                      placeholder="Programar"
                      min={today}
                      onChange={(event) => {
                        onChange(event);
                      }}
                      required
                    />
                  </FloatingLabel>
                  <FloatingLabel label="Hora de envío:" className="mb-3">
                    <Form.Control
                      type="time"
                      name="proghour"
                      id="proghour"
                      placeholder="Programar"
                      onChange={(event) => {
                        onChange(event);
                      }}
                      required
                    />
                  </FloatingLabel>
                  <Alert
                    key={"secondary"}
                    variant={"secondary"}
                    className="Notice"
                  >
                    <b>Nota:</b> Si la hora seleccionada ya transcurrió la
                    campaña se ejecutará inmediatamente.
                  </Alert>
                </>
              ) : (
                <>
                  <FloatingLabel label="Fecha de envío:" className="mb-3">
                    <Form.Control
                      type="date"
                      name="dateSend"
                      id="dateSend"
                      placeholder="Programar"
                      min={today}
                      onChange={(event) => {
                        onChange(event);
                      }}
                    />
                  </FloatingLabel>
                  <FloatingLabel label="Hora de envío:" className="mb-3">
                    <Form.Control
                      type="time"
                      name="proghour"
                      id="proghour"
                      placeholder="Programar"
                      onChange={(event) => {
                        onChange(event);
                      }}
                    />
                  </FloatingLabel>
                  <Alert
                    key={"secondary"}
                    variant={"secondary"}
                    className="Notice"
                  >
                    <b>Nota:</b> Si la hora seleccionada ya transcurrió la
                    campaña se ejecutará inmediatamente.
                  </Alert>
                </>
              )}
            </>
          )}
          <div className="text-right">
            <Button variant="primary" type="submit" className="align-self-end">
              Enviar
            </Button>
          </div>
        </Form>
      </div>

      <Notification
        alertType={notificationType}
        show={show}
        setShow={setShow}
        message={messageNotification}
      />
    </>
  );
};

export default NewCampaignMessage;
