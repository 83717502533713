import React, { useEffect, useState } from "react";

/* Bootstrap */
import Stack from "react-bootstrap/Stack";

/* Components */
import Loader from "../../components/loader";

import { MissInfoReport } from "../../components/MissInfo";

import ReportSearchBox from "../../components/ReportSearchBox";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { useAuth } from "../../Context/AuthContext";
import ReportUserDetail from "./_ReportUserDetail";
import { httpClient } from "../../utils/HttpClient";

const ReportUser = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [msisdnFilter, setMsisdnFilter] = useState("");
  const [reportData, setReportData] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  const [showLoader, setShowLoader] = useState(false);
  const handleClose = () => setShowLoader(false);
  const handleShow = () => setShowLoader(true);

  const getMsisdnReport = () => {
    setIsDisabled(true);
    setShowLoader(true);

    setReportData(null);
    const URI = ApiEndpoint + "reports/getMessagesByMsisdn";

    httpClient(
      "POST",
      URI,
      {
        uidpartner: userData.uid_partner,
        from: new Date(2023, 1, 1),
        to: new Date(),
        msisdn: msisdnFilter,
      },
      userData.token
    )
      .then(function (response) {
        setReportData(response.data);

        setIsDisabled(false);
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const refreshFilter = () => {
    getMsisdnReport();
  };
  useEffect(() => {
    console.log(msisdnFilter);
    if (msisdnFilter && msisdnFilter != "") {
      getMsisdnReport();
    } else {
      setReportData(null);
    }
  }, [msisdnFilter]);
  return (
    <>
      <div className="">
        <Stack direction="horizontal" gap={2}>
          <div>
            <h2>Reportes por usuario</h2>
            <h3>Consulte los reportes generados.</h3>
          </div>
        </Stack>

        <div className="">
          <Stack direction="horizontal" gap={3}>
            <ReportSearchBox
              filter={msisdnFilter}
              setFilter={setMsisdnFilter}
              isDisabled={isDisabled}
              refreshFilter={refreshFilter}
            />
          </Stack>
        </div>
        <hr />
        {reportData ? (
          <ReportUserDetail
            msisdnFilter={msisdnFilter}
            listMessages={reportData}
          ></ReportUserDetail>
        ) : (
          <MissInfoReport />
        )}
      </div>
      <Loader show={showLoader} onHide={handleClose} />
    </>
  );
};

export default ReportUser;
