import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Bootstrap
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Alert from "react-bootstrap/Alert";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

// Componentes
import Notification from "../../components/notification";
import axios from "axios";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import { useAuth } from "../../Context/AuthContext";
import { Badge, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SmsTextBox from "../../components/generic/_SmsTextBox";
import moment from "moment";
import { FormatDateToDb } from "../../utils/FormatData";
import { httpClient } from "../../utils/HttpClient";
const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

var today = moment(new Date()).format().split("T")[0];
//var today2 = new Date().toISOString().split("T")[0];
const NewCampaign = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();

  const navigate = useNavigate();
  const [shortCodesList, setShortCodesList] = useState(false);
  const [masksList, setMasksList] = useState(null);
  const [buttonText, setButtonText] = useState("Enviar");
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [listShortLinks, setListShortLinks] = useState(false);
  const [carrierList, setCarrierList] = useState(false);
  const [show, setShow] = useState(false);
  const [showPrev, setShowPrev] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [listDatabases, setListDatabases] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [messageText, setMessageText] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [fieldsInfo, setFieldsInfo] = useState(false);
  const [messageNotification, setMessageNotification] =
    useState("Campaña creada");
  const [values, setValues] = useState({
    campaingName: "",
    uiddatabase: "",
    message: "",
    dateSend: "",
    msisdn: "",
    proghour: "",
    template: "",
    idshortcode: "0",
    mask: "",
  });

  const getListDatabases = (uidpartner) => {
    const URI = ApiEndpoint + "campaigns/getPartnerDatabasesActive";

    httpClient(
      "POST",
      URI,
      {
        id: `${uidpartner}`,
        iduser: userData.uid_user,
      },
      userData.token
    ).then((response) => {
      setListDatabases(response.data);
    });
  };

  const getCarriersList = () => {
    const URICarriers = ApiEndpoint + "carriers";

    httpClient("GET", URICarriers, {}, userData.token).then((response) => {
      setCarrierList(response.data);
    });
  };

  const getShortLinks = () => {
    const URI = ApiEndpoint + "campaigns/shortLinks/" + userData.uid_partner;

    httpClient("GET", URI, {}, userData.token).then((response) => {
      setListShortLinks(response.data.filter((x) => x.active));
    });
  };

  const getXtraFieldsInfo = (uidDatabase) => {
    const URIFields = ApiEndpoint + "campaigns/fieldsdatabase/";

    httpClient("GET", URIFields + uidDatabase, {}, userData.token).then(
      (response) => {
        setFieldsInfo(response.data);
      }
    );
  };

  useEffect(() => {
    if (!values.uiddatabase) return;
    getXtraFieldsInfo(values.uiddatabase);
  }, [values.uiddatabase]);

  useEffect(() => {
    if (!fieldsInfo) {
      setShowPrev(true);
      return;
    }
    let messageWithTemplate = values.message;
    var pattern = RegExp("{(.*?)}", "g");
    let result;
    while ((result = pattern.exec(messageWithTemplate))) {
      // Do something with result[0].
      console.log(result[0]);
      try {
        var currentK = fieldsInfo.filter(
          (item) => "{" + item.vc_keycolumn + "}" == result[0]
        );
        console.log(currentK);
        messageWithTemplate = messageWithTemplate.replace(
          result[0],
          currentK[0].vc_value
        );
      } catch (error) {}
    }
    setValues({
      ...values,
      ["template"]: messageWithTemplate.replaceAll("{", "").replaceAll("}", ""),
    });
  }, [values.message]);

  useEffect(() => {
    getListDatabases(userData.uid_partner);
  }, []);

  useEffect(() => {
    getCarriersList();
    getShortLinks();
    getAvMask();
  }, []);

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    setIsBtnDisabled(true);
    setButtonText("Enviando...");

    var sendDate = new Date();

    if (isScheduled) {
      console.log("new programCampaign");
      console.log(values.dateSend);
      console.log(values.proghour);
    }
    let URICustomer = ApiEndpoint + "campaigns/createCampaign";
    if (isScheduled) {
      URICustomer = ApiEndpoint + "campaigns/programCampaign";
    }
    getListDatabases(userData.uid_partner);

    httpClient(
      "POST",
      URICustomer,
      {
        name: values.campaingName,
        uiddatabase: values.uiddatabase,
        partner: `${userData.uid_partner}`,
        message: values.message,
        send: values.dateSend,
        hour: isScheduled ? values.proghour.split(":")[0] : 0,
        minute: isScheduled ? values.proghour.split(":")[1] : 0,
        uidUser: userData.uid_user,
        idshortcodemask: values.idshortcode > 0 ? values.idshortcode : null,
        mask: values.mask,
      },
      userData.token
    )
      .then(function (response) {
        if (response.data.error) {
          setMessageNotification(
            response.data.Errorcode == "0"
              ? "No cuenta con SMS suficientes para la campaña"
              : "Ya existe una campaña con ese nombre"
          );
          setNotificationType("warning");
          setShow(true);
          setIsBtnDisabled(false);
          setValidated(false);
          setButtonText("Enviar");
        } else {
          setMessageNotification("Campaña creada");
          setNotificationType("");
          setShow(true);
          navigate("/Campaigns/Campaigns");
        }
        return;
      })
      .catch(function (error) {
        setMessageNotification("Ha ocurrido un error");
        setNotificationType("warning");
        setShow(true);
        console.log(error);
      });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    getCarriersList();
    getShortLinks();
    getShortCodes();
  }, []);

  const getShortCodes = () => {
    const URI = ApiEndpoint + "administration/shortcodesbypartner/";
    httpClient("GET", URI + `${userData.uid_partner}`, {}, userData.token).then(
      (response) => {
        setShortCodesList(response.data);
      }
    );
  };

  const getAvMask = () => {
    const URI = ApiEndpoint + "campaigns/masks/";
    httpClient("GET", URI + `${userData.uid_partner}`, {}, userData.token).then(
      (response) => {
        setMasksList(response.data);
      }
    );
  };

  return (
    <>
      <div className="">
        <Form noValidate validated={validated} onSubmit={handleSubmit1}>
          <h2>Nueva campaña</h2>
          <h3>1. Datos generales</h3>

          <FloatingLabel label="Nombre de la campaña *" className="mb-3">
            <Form.Control
              type="text"
              name="campaingName"
              id="campaingName"
              value={values.campaingName}
              required
              onChange={(event) => {
                onChange(event);
              }}
              placeholder="Nombre de la campaña *"
            />
          </FloatingLabel>

          <FloatingLabel label="Base de datos *" className="mb-3">
            <Form.Select
              id="uiddatabase"
              name="uiddatabase"
              value={values.uiddatabase}
              required
              aria-label="select_carrier"
              onChange={(event) => {
                onChange(event);
                setIsDisabled(false);
              }}
            >
              <option value="">Seleccione una opcion de la lista</option>
              {!!listDatabases?.length &&
                listDatabases.map((item) => (
                  <option value={item.uiddatabase}>{item.name}</option>
                ))}
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel label="Mascara (opcional)" className="mb-3">
            <Form.Select
              id="mask"
              name="mask"
              onChange={(selectedOption) => {
                onChange(selectedOption);
              }}
              value={values.mask}
              aria-label="select_mask"
            >
              <option value="">Predeterminada</option>
              {!!masksList?.length &&
                masksList.map((mask) => (
                  <option value={mask.vc_mask}>{mask.vc_mask}</option>
                ))}
            </Form.Select>
          </FloatingLabel>
          {/* <FloatingLabel
            label="Mascara/Numero corto (opcional)"
            className="mb-3"
          >
            <Form.Select
              id="idshortcode"
              name="idshortcode"
              onChange={(selectedOption) => {
                onChange(selectedOption);
              }}
              value={values.id_shortcode_mask}
              aria-label="select_shortCode"
            >
              <option value="">Seleccione una opcion de la lista</option>
              {!!shortCodesList?.length &&
                shortCodesList.map((shortcode) => (
                  <option value={shortcode.id_shortcode_mask}>
                    {shortcode.vc_mask ?? shortcode.vc_shortcode}
                  </option>
                ))}
            </Form.Select>
          </FloatingLabel> */}
          {/* masksList */}
          <Divider />
          <h3>2. Redacte el mensaje</h3>
          <Row>
            <Col sm={8}>
              <SmsTextBox
                name="message"
                isDisabled={isDisabled}
                defaultValue={values.message}
                changeHandler={onChange}
              ></SmsTextBox>
            </Col>
            <Col sm={4}>
              <Tabs
                defaultActiveKey="ShortLinks"
                id="fill-tab-example"
                className="tabsBottom"
                fill
              >
                <Tab eventKey="ShortLinks" title="Urls Cortas">
                  <div className="ShortLink">
                    {/* <h3>Urls Cortas</h3> */}
                    <h5 style={{ marginBottom: "10px" }}>
                      Al darle click se agregará la url corta relacionada con el
                      alias que aparece a continuación:
                    </h5>
                    <div className="AllLinks">
                      <>
                        {listShortLinks &&
                          listShortLinks.map((shortlink) => {
                            return (
                              <>
                                {" "}
                                <Badge
                                  onClick={() => {
                                    setMessageText(shortlink.shortlinks);
                                    setValues({
                                      ...values,
                                      message:
                                        values.message +
                                        " " +
                                        shortlink.shortlinks +
                                        " ",
                                      template:
                                        values.template +
                                        " " +
                                        shortlink.shortlinks +
                                        " ",
                                    });
                                  }}
                                  bg="primary"
                                >
                                  {shortlink.displayname}
                                </Badge>{" "}
                              </>
                            );
                          })}
                      </>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="AddFields" title="Template">
                  {fieldsInfo ? (
                    <div className="ShortLink">
                      {/* <h3>Campos Adicionales</h3> */}
                      <h5 style={{ marginBottom: "10px" }}>
                        Al darle click se agregará el campo relacionado con el
                        alias que aparece a continuación:
                      </h5>
                      <div className="AllLinks">
                        <>
                          {fieldsInfo &&
                            fieldsInfo.map((field) => {
                              return (
                                <>
                                  {" "}
                                  <Badge
                                    onClick={() => {
                                      setMessageText(field.vc_keycolumn);
                                      setValues({
                                        ...values,
                                        message:
                                          values.message +
                                          " {" +
                                          field.vc_keycolumn +
                                          "} ",
                                        template:
                                          values.template +
                                          " {" +
                                          field.vc_value +
                                          "} ",
                                      });
                                    }}
                                    bg="primary"
                                  >
                                    {field.vc_keycolumn}
                                  </Badge>
                                </>
                              );
                            })}
                        </>
                      </div>
                    </div>
                  ) : (
                    <div className="ShortLink">
                      <p
                        style={{
                          textAlign: "center",
                          fontWeight: "400",
                          color: "rgb(106, 106, 106)",
                          fontSize: "14px",
                        }}
                      >
                        Selecciona una <b>base de datos</b> para visualizar los
                        campos adicionales que contiene.
                      </p>
                    </div>
                  )}
                </Tab>
              </Tabs>
            </Col>
          </Row>

          {values.template && showPrev && (
            <>
              <h5 className="PrevTitleModal">Previsualización:</h5>
              <div class="contenedo_msj">
                <div class="mensaje-izq">
                  <div class="cuerpo_msj">
                    <div class="msj-texto">{values.template}</div>
                  </div>
                </div>
                <Badge
                  className="mt-2"
                  style={{ minWidth: "143px" }}
                  bg="secondary"
                >
                  Max. Caracteres: {values.template.length}
                </Badge>
              </div>
              <hr />
            </>
          )}
          <Divider />
          <h3>3. Programe la campaña (opcional)</h3>
          <h5>
            En caso de no seleccionar esta opción tu campaña se procesará de
            inmediato.
          </h5>

          <FloatingLabel label="" className="mb-3">
            <Form.Check
              type="switch"
              id="isScheduled"
              value={isScheduled}
              label="¿Desas programar la campaña?"
              onChange={(event) => {
                setIsScheduled(!isScheduled);
              }}
              className="prevent-validation"
            />
          </FloatingLabel>

          {isScheduled ? (
            <>
              <FloatingLabel label="Fecha de envío:" className="mb-3">
                <Form.Control
                  type="date"
                  name="dateSend"
                  id="dateSend"
                  placeholder="Programar"
                  min={today}
                  onChange={(event) => {
                    onChange(event);
                  }}
                  required
                />
              </FloatingLabel>
              <FloatingLabel label="Hora de envío:" className="mb-3">
                <Form.Control
                  type="time"
                  name="proghour"
                  id="proghour"
                  placeholder="Programar"
                  onChange={(event) => {
                    onChange(event);
                  }}
                  required
                />
              </FloatingLabel>
              <Alert key={"secondary"} variant={"secondary"} className="Notice">
                <b>Nota:</b> Si la hora seleccionada ya transcurrió la campaña
                se ejecutará inmediatamente.
              </Alert>
            </>
          ) : (
            <></>
          )}

          <div className="text-right">
            <Button
              disabled={isBtnDisabled}
              variant="primary"
              type="submit"
              className="align-self-end"
            >
              {buttonText}
            </Button>
          </div>
        </Form>
      </div>

      <Notification
        alertType={notificationType}
        show={show}
        setShow={setShow}
        message={messageNotification}
      />
    </>
  );
};

export default NewCampaign;
