import React, { useState } from "react";
import styled from "styled-components";
import Stack from "react-bootstrap/Stack";
import { MissInfoReport } from "../../components/MissInfo";
import ReportSmsSmryList from "./_ReportSmsSmryList";
import ReportFiltersSmry from "./ReportFiltersSmry";
import ReportSmsByUserList from "./_ReportSmsByUserList";

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
const ReportSmsSummaryByUser = () => {
  const [reportData, setReportData] = useState(null);
  const [periodStr, setPeriodStr] = useState("");
  return (
    <>
      <div className="">
        <Stack direction="horizontal" gap={2}>
          <div>
            <h2>Resumen por usuario</h2>
            <h3>
              Resumen de Mensajes exitosos enviados por usuario con base en la
              fecha.
            </h3>
          </div>
        </Stack>

        <div className="ReportDate">
          <ReportFiltersSmry
            endPointReport="getpartnerSummaryByUser"
            setReportData={setReportData}
            setPeriodStr={setPeriodStr}
          />
        </div>

        <Divider />
        {reportData ? (
          <ReportSmsByUserList
            periodStr={periodStr}
            listMessages={reportData}
          ></ReportSmsByUserList>
        ) : (
          <MissInfoReport />
        )}
      </div>
    </>
  );
};

export default ReportSmsSummaryByUser;
