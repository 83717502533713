/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAsyncDebounce } from "react-table";
import * as AiIcons from "react-icons/ai";
import { Stack } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { ApiEndpoint } from "../../utils/ApiEndpoint";
import axios from "axios";
import { useAuth } from "../../Context/AuthContext";
import { httpClient } from "../../utils/HttpClient";
const moment = require("moment");

const ReportFiltersSmry = ({
  setReportData,
  setPeriodStr,
  endPointReport = "getReportSms",
}) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [selectRange, setSelectRange] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Consultar");
  const [values, setValues] = useState({
    idshortcode: "0",
    dateFrom: "",
    dateTo: "",
  });

  const getDateReport = () => {
    setReportData(null);
    setIsDisabled(true);
    setButtonText("Generando...");
    var userData = Auth.getSessionData();
    const URI = ApiEndpoint + "reports/" + endPointReport;
    let dateFrom = new Date(),
      dateTo = new Date();
    var today = new Date();
    switch (selectRange) {
      case "1":
        dateFrom = new Date(dateFrom.setDate(today.getDate() - 1));
        dateTo = new Date(dateTo.setDate(today.getDate()));
        break;
      case "2":
        dateFrom = new Date(dateFrom.setDate(today.getDate() - 7));
        dateTo = new Date(dateTo.setDate(today.getDate() - 1));
        break;
      case "3":
        dateFrom = new Date(today.getFullYear(), today.getMonth(), 1);
        dateTo = today;
        break;
      case "4":
        dateFrom = new Date(dateFrom.setDate(today.getDate() - 30));
        dateTo = today;
        break;
      case "5":
        dateFrom = new Date(values.dateFrom);
        dateTo = new Date(values.dateTo);
        break;
      default:
        dateFrom = today;
        dateTo = today;
        break;
    }
    let dateFromM = moment(dateFrom);
    let dateToM = moment(dateTo);

    let periodStr = `Del ${dateFromM.format("DD/MM/yyyy")}
    al ${dateToM.format("DD/MM/yyyy")} `;
    setPeriodStr(periodStr);

    httpClient(
      "POST",
      URI,
      {
        uidpartner: userData.uid_partner,
        from: dateFrom,
        to: dateTo,
        shortcode: "0",
        user: userData.uid_user,
      },
      userData.token
    )
      .then(function (response) {
        setReportData(response.data);
        setIsDisabled(false);
        setButtonText("Consultar");
      })
      .catch(function (error) {
        console.log(error);
        setIsDisabled(false);
        setButtonText("Consultar");
      });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Form>
        <Row className="mb-1">
          <Col xs={6} sm={6} md={4} lg={4} xl={4}>
            <Form.Group>
              <>
                <FloatingLabel label="Periodo:" className="mb-1">
                  <Form.Select
                    id="selectRange"
                    name="selectRange"
                    onChange={(selectedOption) => {
                      onChange(selectedOption);
                      setSelectRange(selectedOption.target.value);
                    }}
                    value={values.selectRange}
                    required
                    aria-label="select_selectRange"
                  >
                    <option value="0">Hoy</option>
                    <option value="1">Últimos 24 horas</option>
                    <option value="2">Última semana</option>
                    <option value="3">Mes Actual</option>
                    <option value="4">Ultimos 30 dias</option>
                    <option value="5">Personalizar</option>
                  </Form.Select>
                </FloatingLabel>
              </>
            </Form.Group>
          </Col>
          {selectRange == 5 && (
            <>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <Form.Group>
                  <FloatingLabel label="Fecha de inicio:" className="mb-1">
                    <Form.Control
                      type="date"
                      name="dateFrom"
                      id="dateFrom"
                      placeholder="Del"
                      onChange={(event) => {
                        onChange(event);
                      }}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <Form.Group>
                  <FloatingLabel label="Fecha de fin:" className="mb-1">
                    <Form.Control
                      type="date"
                      name="dateTo"
                      id="dateTo"
                      placeholder="Al"
                      onChange={(event) => {
                        onChange(event);
                      }}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </>
          )}
        </Row>
        <div className="text-right">
          <Button
            disabled={isDisabled}
            variant="primary"
            type="button"
            onClick={() => {
              getDateReport();
            }}
          >
            {buttonText}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ReportFiltersSmry;
