import React from "react";
import { Badge, Col, Row, Stack } from "react-bootstrap";
import AdvanceTableWrapper from "../../components/AdvanceTableWrapper";
import BtnExportToExcell from "../../utils/BtnExportToExcell";
import AdvanceTable from "../../components/AdvanceTable";
import AdvanceTablePagination from "../../components/AdvanceTablePagination";
import AdvanceTableFooter from "../../components/AdvanceTableFooter";
import * as AiIcons from "react-icons/ai";
import { FormatInt } from "../../utils/FormatData";
const columns = [
  {
    accessor: "vc_customer",
    Header: () => {
      return (
        <div style={{ textAlign: "center", fontWeight: "bold" }}>Cliente</div>
      );
    },
    Footer: () => {
      return (
        <Col style={{ textAlign: "right", fontWeight: "bold" }}>Totales:</Col>
      );
    },
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <>
          <Col style={{ textAlign: "left", fontSize: "smaller" }}>
            {rowInfo.vc_customer}
          </Col>
        </>
      );
    },
  },
  {
    accessor: "i_telcel",
    Header: () => {
      return (
        <div style={{ textAlign: "center", fontWeight: "bold" }}>Telcel</div>
      );
    },

    showTotal: true,
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <Col style={{ textAlign: "right" }}>{FormatInt(rowInfo.i_telcel)}</Col>
      );
    },
    Footer: (columnData) => {
      return (
        <div style={{ textAlign: "right", fontWeight: "bold" }}>
          {FormatInt(
            columnData.data.reduce(
              (i_total, item) => i_total + Number(item.i_telcel),
              0
            )
          )}
        </div>
      );
    },
  },
  {
    accessor: "i_movistar",
    Header: () => {
      return (
        <div style={{ textAlign: "center", fontWeight: "bold" }}>Movistar</div>
      );
    },

    showTotal: true,
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <Col style={{ textAlign: "right" }}>
          {FormatInt(rowInfo.i_movistar)}
        </Col>
      );
    },
    Footer: (columnData) => {
      return (
        <div style={{ textAlign: "right", fontWeight: "bold" }}>
          {FormatInt(
            columnData.data.reduce(
              (i_total, item) => i_total + Number(item.i_movistar),
              0
            )
          )}
        </div>
      );
    },
  },
  {
    accessor: "i_att",
    Header: () => {
      return (
        <div style={{ textAlign: "center", fontWeight: "bold" }}>AT&T</div>
      );
    },
    showTotal: true,
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <Col style={{ textAlign: "right" }}>{FormatInt(rowInfo.i_att)}</Col>
      );
    },
    Footer: (columnData) => {
      return (
        <div style={{ textAlign: "right", fontWeight: "bold" }}>
          {FormatInt(
            columnData.data.reduce(
              (i_total, item) => i_total + Number(item.i_att),
              0
            )
          )}
        </div>
      );
    },
  },
  {
    accessor: "i_other",
    Header: () => {
      return (
        <div style={{ textAlign: "center", fontWeight: "bold" }}>Otros</div>
      );
    },

    showTotal: true,
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <Col style={{ textAlign: "right" }}>{FormatInt(rowInfo.i_other)}</Col>
      );
    },
    Footer: (columnData) => {
      return (
        <div style={{ textAlign: "right", fontWeight: "bold" }}>
          {FormatInt(
            columnData.data.reduce(
              (i_total, item) => i_total + Number(item.i_other),
              0
            )
          )}
        </div>
      );
    },
  },

  {
    accessor: "i_total",
    Header: () => {
      return (
        <div style={{ textAlign: "center", fontWeight: "bold" }}>
          Total Envios
        </div>
      );
    },
    showTotal: true,
    Cell: (rowData) => {
      let rowInfo = rowData.row.original;
      return (
        <Col style={{ textAlign: "right" }}>{FormatInt(rowInfo.i_total)}</Col>
      );
    },
    Footer: (columnData) => {
      return (
        <div style={{ textAlign: "right", fontWeight: "bold" }}>
          {FormatInt(
            columnData.data.reduce(
              (i_total, item) => i_total + Number(item.i_total),
              0
            )
          )}
        </div>
      );
    },
  },
];
const AdminSmsCarrierList = ({ listMessages, periodStr }) => {
  return (
    <>
      {listMessages && (
        <AdvanceTableWrapper
          columns={columns}
          data={listMessages}
          sortable
          pagination
          perPage={10}
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="DbDetails2">
                <Stack direction="horizontal" gap={2}>
                  <AiIcons.AiOutlineCalendar />
                  <div className="ms-auto">
                    <p>{periodStr}</p>
                  </div>
                </Stack>
                <div className="details">Rango de fechas</div>
              </div>
            </Col>
          </Row>
          <div>
            <div style={{ textAlign: "left" }}>
              <h4>Resumen</h4>
            </div>
            <div style={{ textAlign: "right" }}>
              <BtnExportToExcell
                dataReport={listMessages}
                fileName="SMS_Report"
              ></BtnExportToExcell>
            </div>
          </div>

          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: "sm",
              striped: true,
              className: "fs--1 mb-0 overflow-hidden",
            }}
          />
          <AdvanceTablePagination table />
          <AdvanceTableFooter
            rowCount={listMessages.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
            pageSize={10}
          />
        </AdvanceTableWrapper>
      )}
    </>
  );
};

export default AdminSmsCarrierList;
