import axios from "axios";
import { ApiEndpoint } from "./ApiEndpoint";

const client = axios.create({
  baseURL: ApiEndpoint,
  // baseURL: process.env.APP_API_BASE_URL,
});

export const httpClient = (
  method = "POST",
  url = "",
  data = {},
  token = ""
) => {
  const axiosConfig = {
    method: method,
    url: url,
  };
  if (token != "") axiosConfig.headers = { Authorization: `Bearer ${token}` };
  return new Promise((resolve, reject) => {
    if (method === "GET" || method === "DELETE") {
      axiosConfig.params = data;
    } else {
      axiosConfig.data = data;
    }

    client(axiosConfig)
      .then((response) => {
        return resolve(response);
      })
      .catch((err) => {
        console.log("no token" + err.response?.status);
        if (err.response?.status === 403) {
          console.log("no token");
          window.location.href = "/logout";
        }
        return reject(
          err?.response?.data || {
            error: true,
            message: "Ha ocurrido un error",
            status: 401,
          }
        );
      });
  });
};
